// component variables
$icon-size-xs: em(16);
$icon-size-xs-text: em(10);
$icon-size-s: em(32);
$icon-size-s-text: em(24);
$icon-size-m: em(42);
$icon-size-m-text: em(22);
$icon-size-mm: em(56);
$icon-size-mm-text: em(22);
$icon-size-l: em(72);
$icon-size-l-text: em(36);

$icon-sizes:    (xs, $icon-size-xs, $icon-size-xs-text),
                (s, $icon-size-s, $icon-size-s-text),
                (m, $icon-size-m, $icon-size-m-text),
                (mm, $icon-size-mm, $icon-size-mm-text),
                (l, $icon-size-l, $icon-size-l-text);

$icon-color-yellow: $bsecondary;
$icon-color-orange: $bdanger;
$icon-color-dorange: $dorange;
$icon-color-purple: $bpurple;
$icon-color-green: $bsuccess;
$icon-color-dgreen: $bgreen-dark;
$icon-color-blue: $bblue;
$icon-color-dblue: $bblue-dark;
$icon-color-red: $red;
$icon-color-black: #000000;
$icon-color-bgray: $gray;
$icon-color-gray: $gdark;
$icon-color-green-active: $bgreen-active;
$icon-color-gray-inactive: $glight2;

$icon-colors:   ('orange', $icon-color-orange),
                ('dorange', $icon-color-dorange),
                ('yellow', $icon-color-yellow),
                ('purple', $icon-color-purple),
                ('green', $icon-color-green),
                ('dgreen', $icon-color-dgreen),
                ('blue', $icon-color-blue),
                ('dblue', $icon-color-dblue),
                ('red', $icon-color-red),
                ('black', $icon-color-black),
                ('green-active', $icon-color-green-active),
                ('gray-inactive', $icon-color-gray-inactive),
                ('bgray', $icon-color-bgray),
                ('gray', $icon-color-gray);

.icon {
    position: relative;
    z-index: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
    color: $bblack;

    &:before {
        // display: inline-block;
        // margin: -1em 0 0 -1em;
        content: '';
        background-color: $bprimary;
        @include circle-wc(2em);
    }

    i {
        position: absolute;
        z-index: 1;
        display: inline-block;
        left: 50%;
        top: 50%;
        @include transform (translate(-50%,-50%));
    }

    @each $name, $size, $text in $icon-sizes {
        &.icon-#{$name} {
            width: #{$size};
            height: #{$size};

            &:before {
                @include circle-wc(#{$size});
            }

            i { color: $bwhite; font-size: #{$text}; }
        }
    }

    @each $name, $color in $icon-colors {
        &.icon-color-#{$name} {
            &:before { background-color: #{$color}; }
        }
    }

    @each $name, $color in $icon-colors {
        &.icon-color-empty-#{$name} {
            &:before {
                background-color: transparent;
                border: 2px solid #{$color};
            }

            i {
                color: #{$color};
            }
        }
    }


}

.fi {

    &.fi-red {
        color: $redtc;
    }

    &.icon-mirrored {

        &:before {
            @include transform(rotate(180deg));
        }
    }

    &.flaticon-cancel {
        cursor: pointer;
    }

}
