.mentor-wrapper {
    width: 100%;

    .back-to-profile {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1em 0 0 3em;

        .home {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: $gray;
            font-weight: 100;

            &:visited {
                color: $gray;
            }
        }
    }

    .mentor-title {
        width: 100%;
        padding: 3em 0 2em 3em;

        .title-text {
            font-family: $sfaf;
            font-size: em(35);
            font-weight: 300;
        }
    }

    .mentor-content {
        width: 81%;

        .mentor-header {
            display: flex;
            align-items: center;
            width: 100%;
            height: 80px;
            background-color: $glighter2;

            .status-text {
                padding: 0 2em 0 3em;
                font-size: em(15);
                font-weight: 300;
                color: $gray;
            }

            .btn {
                position: relative;

                .icon {
                    position: absolute;
                    top: -15px;
                    right: -15px;
                    margin: 0;
                }
            }

            .deadline {
                display: flex;
                flex-wrap: wrap;
                padding-left: 2em;
                font-size: em(14);
                line-height: em(26);
                font-weight: 300;

                .deadline-text {
                    width: 100%;
                }

                .deadline-date {
                    font-weight: 400;
                }
            }

            .mentor-log {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 1em;
                margin-right: 2em;
                margin-left: auto;
                color: $gray;

                &.active {
                    background-color: $glightest2;
                }

                i {
                    padding-right: .5em;
                }
            }
        }

        .mentor-card {
            display: flex;
            width: 100%;
            min-height: 800px;

            .sidebar {
                width: 35%;
                padding: 4em 2em;
                background-color: $reg-tile-tl;

                &.norightpadding {
                    padding-right: 0;
                }

                &.with-header {
                    padding: 0;

                }

                .mobile-sidebar-trigger {
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: none;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    padding: 2em 0;
                    cursor: pointer;
                }

                .sidebar-header {
                    padding: 2.5em 3em;
                    background-color: $glighter2;

                    &.self-eval {
                        background-color: $reg-tile-tr;
                    }

                    .sidebar-header-text {
                        font-size: em(17);
                        font-weight: 600;
                    }
                }

                .sidebar-text-content {

                    .title {
                        font-size: em(17);
                        color: $bprimary-dark;
                    }

                    .text {
                        padding-top: 2em;
                        font-size: em(16);
                        font-weight: 300;
                        line-height: em(25);
                        color: $gray;
                    }

                    .pdf-download {
                        padding: 2em 0 2em 1em;
                        font-size: em(14);

                        a {
                            color: $bblack;
                            text-decoration: underline;

                            &:hover {
                                color: $bblack;
                            }

                            &:visited {
                                color: $bblack;
                            }
                        }
                    }

                    .attached-docs {
                        padding: 0 0 2em 1em;
                        font-size: em(14);
                        font-weight: 600;
                    }

                    .attachment {
                        padding: 0 0 2em 2em;
                        font-size: em(14);

                        a {
                            color: $bblack;
                            text-decoration: underline;

                            &:hover {
                                color: $bblack;
                            }

                            &:visited {
                                color: $bblack;
                            }
                        }
                    }

                    .btn {
                        padding: 1.5em 1em;
                        margin-top: 3em;
                        font-size: em(11);
                        font-weight: 300;
                    }
                }

                .timeline-wrapper {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    width: 100%;
                    padding-top: 1em;
                    padding-left: 1em;

                    .timeline-part {
                        width: 100%;

                        .timeline-item {
                            position: relative;
                            display: flex;
                            align-items: center;
                            padding: 1em 0;
                            margin-bottom: 2.5em;
                            cursor: pointer;

                            &.active {
                                background-color: $glightest2;
                            }

                            .tear-wrapper {
                                padding-left: (($tear-m / 2) - $dot-size);
                                margin-left: -3px;
                            }

                            .item-text {
                                padding-left: 2em;
                                color: $gray;
                                font-size: em(14);
                                line-height: em(26);
                                font-weight: 300;

                                .item-title {
                                    font-weight: 600;

                                    &.highlighted {
                                        color: $bdanger;
                                    }
                                }

                                .date {
                                    position: relative;

                                    .badge {
                                        position: absolute;
                                        top: (-$icon-size-s / 2);
                                        right: (-$icon-size-s);
                                        margin: 0;
                                    }
                                }

                                .attachment-icon {
                                    display: flex;
                                    align-items: center;

                                    .icon-attachment {
                                        margin-right: 1em;
                                    }

                                    .message-notification {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 0 5px;
                                        background-color: $glighter2;
                                        border-radius: 5px;
                                        font-size: em(15);

                                        .message-count {
                                            padding-left: .5em;
                                            color: $dorange;
                                            font-weight: 400;
                                        }
                                    }
                                }
                            }
                        }

                        &.future {
                            position: relative;
                            padding-top: 2.5em;
                            border-left: 2px dashed $gray;

                            .mentor-menu-icon {
                                position: absolute;
                                top: (-$icon-size-m);
                                left: ((-$icon-size-m) / 2);
                                z-index: 10;
                                cursor: pointer;

                                .mentor-menu-bubble {
                                    display: none;
                                    position: absolute;
                                    left: 3.5em;
                                    top: -150%;
                                    padding: 1em 1.5em;
                                    background: $gray;
                                    border-radius: 25px;
                                    border: 1px solid $gray;
                                    cursor: default;

                                    &.active {
                                        display: block;
                                    }

                                    &:after, &:before {
                                        right: 100%;
                                        top: 50%;
                                        border: solid transparent;
                                        content: " ";
                                        height: 0;
                                        width: 0;
                                        position: absolute;
                                        pointer-events: none;
                                    }

                                    &:after {
                                        border-color: transparent;
                                        border-right-color: $gray;
                                        border-width: 15px;
                                        margin-top: -15px;
                                    }

                                    &:before {
                                        border-color: transparent;
                                        border-right-color: $gray;
                                        border-width: 16px;
                                        margin-top: -16px;
                                    }

                                    .menu-list {
                                        list-style: none;

                                        .menu-item {
                                            padding: 1em 0;
                                            font-size: em(13);
                                            font-weight: 300;
                                            line-height: em(25);
                                            color: $bwhite;
                                            white-space: nowrap;

                                            a {
                                                color: $bwhite;

                                                &:visited {
                                                    color: $bwhite;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .timeline-today {
                                position: absolute;
                                bottom: ((-$tear-s) / 2);
                                left: (($tear-s / 2) - ($dot-size / 2));
                                display: flex;
                                align-items: center;
                                margin-left: -1px;

                                .today {
                                    padding-left: .5em;
                                    color: $gray;
                                    font-size: em(14);
                                    line-height: em(26);
                                    font-weight: 300;
                                }
                            }
                        }

                        &.past {
                            padding: 2.5em 0;
                            border-left: 2px solid $gray;

                            .timeline-item:last-of-type {
                                margin-bottom: -5.5em;
                            }
                        }
                    }
                }

                .custom-dropdown {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    width: 100%;
                    height: 35px;
                    padding: 1em;
                    border: 1px solid $glighter3;
                    border-radius: 8px;
                    background-color: $bwhite;
                    cursor: pointer;

                    .placeholder {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .icon {
                            margin-right: 1em;
                        }
                    }

                    &:after {
                        position: absolute;
                        right: 10px;
                        top: 11px;
                        z-index: 0;
                        content: $font-Flaticon-down-arrow;
                        font-family: "Flaticon";
                        font-size: em(13);
                    }

                    .dropdown {
                        position: absolute;
                        z-index: 2;
                        top: 100%;
                        left: -1px;
                        right: -1px;
                        border: 1px solid $glighter3;
                        border-top: none;
                        border-radius: 8px;
                        background-color: $bwhite;
                        opacity: 0;
                        pointer-events: none;

                        .dropdown-item {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            padding: 1em;
                            cursor: pointer;
                            color: $bblack;

                            .icon {
                                margin-right: 1em;
                            }
                        }
                    }

                    &.active {
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 0;

                        .dropdown {
                            opacity: 1;
                            pointer-events: auto;
                            border-top-right-radius: 0;
                            border-top-left-radius: 0;
                        }
                    }
                }

                .sidebar-content {
                    padding: 2em;

                    &.self-eval {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 2em 0;

                        .custom-dropdown {
                            width: 80%;
                        }

                        .company-list {
                            width: 100%;
                            padding: 2em 0;

                            .list-item {
                                align-items: center;
                                position: relative;
                                padding: .5em 10%;
                                margin-bottom: 1em;

                                &.active {
                                    background-color: $glightest2;

                                    .item-icon {
                                        position: absolute;
                                        right: 2em;
                                    }

                                    .item-text {
                                        padding-left: calc(1em + 23px);
                                    }
                                }
                            }
                        }
                    }

                    .company-list {
                        padding: 2em 1em 0 1em;

                        .list-item {
                            display: flex;
                            cursor: pointer;
                            padding-bottom: 1em;
                            color: $dark-color;

                            .item-icon {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .list-icon-circle {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    padding: 3px;
                                    @include border-radius(50%);
                                    border: 3px solid $bsecondary;

                                    &.status-1 {
                                        border-color: $bprimary-light;
                                    }

                                    &.status-2 {
                                        border-color: $bsecondary;
                                    }

                                    &.status-3 {
                                        border-color: $bgreen;
                                    }

                                    &.status-4 {
                                        border-color: $alert-box-border;
                                    }

                                    .list-icon-icon {
                                        font-size: em(14);
                                        font-weight: 300;
                                    }
                                }
                            }

                            .item-text {
                                padding-left: 1em;

                                .item-name {
                                    font-size: em(18);
                                    padding-bottom: .5em;
                                }

                                .item-status {
                                    color: $gray;
                                    font-size: em(13);
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                }
            }

            .functions {
                width: 65%;
                padding: 2em 4em;
                background-color: $glightest2;

                &.with-header {
                    padding: 0;

                    .functions-content {

                        .task-list {
                            padding: 3em 0;

                            .task-item {
                                padding: 1.5em 3em;
                                display: flex;
                                align-items: center;

                                &:nth-of-type(even) {
                                    background-color: $glighter2;
                                }

                                .tear-wrapper {
                                    width: 15%;
                                }

                                .details {
                                    width: 35%;
                                    color: $gray;

                                    .date {
                                        font-size: em(13);
                                        font-weight: 300;
                                        line-height: em(28);
                                    }

                                    .task-name {
                                        font-weight: 600;
                                        font-size: em(14);
                                        line-height: em(28);
                                    }
                                }

                                .company-name {
                                    color: $gray;
                                    font-size: em(14);
                                    font-weight: 300;
                                }
                            }
                        }

                        .comments {

                            .comments-header {
                                padding: 2.5em 3em;
                                background-color: $glighter2;

                                .comments-header-text {
                                    font-size: em(17);
                                    font-weight: 600;
                                }
                            }

                            .comments-list {
                                padding: 2em 4em;

                                &.self-eval {
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;
                                    padding: 4em;
                                }

                                .message-bubble {
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    float: left;
                                    width: 80%;
                                    margin-top: 1em;
                                    margin-bottom: 3em;
                                    padding: 2em;
                                    clear: both;
                                    color: $bblack;
                                    background: $bwhite;
                                    border: 1px solid $bwhite;
                                    @include border-radius(25px);
                                    font-weight: 300;
                                    line-height: em(20);
                                    word-wrap: break-word;

                                    &.self-eval {
                                        flex-direction: column;
                                        margin-bottom: 4em;
                                    }

                                    .timestamp {
                                        display: block;
                                        padding-bottom: .5em;
                                        font-size: em(12);
                                    }

                                    .bubble-content {
                                        width: 100%;
                                    }

                                    .message-text {
                                        display: block;
                                        font-size: em(15);

                                        .attachment-icon {
                                            padding-right: 1em;
                                        }

                                        .attachment-link {
                                            color: $bblack;
                                            text-decoration: underline;

                                            &:hover {
                                                color: $bblack;
                                            }

                                            &:visited {
                                                color: $bblack;
                                            }
                                        }
                                    }

                                    &.blue {
                                        float: right;
                                        background: $bblue-message-bubble;
                                        border: 1px solid $bblue-message-bubble;
                                        color: $bwhite;

                                        &:before {
                                            left: auto;
                                            right: -5px;
                                        }

                                        &:after {
                                            left: auto;
                                            right: -5px;
                                            border-bottom-color: $bblue-message-bubble;
                                        }

                                        .attachment-link {
                                            color: $bwhite;
                                            text-decoration: underline;

                                            &:hover {
                                                color: $bwhite;
                                            }

                                            &:visited {
                                                color: $bwhite;
                                            }
                                        }
                                    }

                                    &:before, &:after {
                                        border-radius: 20px / 5px;
                                        content: '';
                                        display: block;
                                        position: absolute;
                                    }

                                    &:before {
                                        border: 10px solid transparent;
                                        border-bottom-color: rgba(0,0,0,0.5);
                                        bottom: 0px;
                                        left: -7px;
                                        z-index: -2;
                                    }

                                    &:after {
                                        border: 8px solid transparent;
                                        border-bottom-color: $bwhite;
                                        bottom: 3px;
                                        left: -5px;
                                    }

                                    .message-delete {

                                        .icon {
                                            margin: 0;
                                            cursor: pointer;
                                        }
                                    }

                                    .bubble-label {
                                        position: absolute;
                                        display: flex;
                                        top: -2em;
                                        width: 100%;
                                        font-size: em(12);
                                        color: $gray;

                                        .company-name {
                                            font-weight: 600;
                                            padding-right: 1em;
                                        }

                                        .date {
                                            font-weight: 300;
                                            padding-right: 1em;
                                        }

                                        .task-name {
                                            font-weight: 600;
                                        }

                                        &.self-eval {
                                            top: -3em;
                                            display: flex;
                                            cursor: pointer;
                                            color: $dark-color;
                                            font-size: em(16);

                                            .item-icon {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;

                                                .list-icon-circle {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    padding: 3px;
                                                    @include border-radius(50%);
                                                    border: 3px solid $bsecondary;

                                                    &.status-1 {
                                                        border-color: $bprimary-light;
                                                    }

                                                    &.status-2 {
                                                        border-color: $bsecondary;
                                                    }

                                                    &.status-3 {
                                                        border-color: $bgreen;
                                                    }

                                                    &.status-4 {
                                                        border-color: $alert-box-border;
                                                    }

                                                    .list-icon-icon {
                                                        font-size: em(14);
                                                        font-weight: 300;
                                                        color: $icon-color-black;
                                                    }
                                                }
                                            }

                                            .item-text {
                                                padding-left: 1em;

                                                .item-name {
                                                    font-size: em(18);
                                                }

                                                .item-status {
                                                    color: $gray;
                                                    font-size: em(13);
                                                    font-weight: 300;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .tab-toggler {
                            display: none;
                        }

                        .tab-content {
                            position: relative;
                            display: none;
                            padding: 6em 2em 2em 2em;

                            &.active {
                                display: block;
                            }

                            .self-eval-status {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                color: $gray;
                                font-size: em(18);

                                .status-text {
                                    font-weight: bold;
                                    padding-bottom: .5em;
                                }

                                .btn {
                                    margin-top: 2em;
                                }
                            }

                            .form-save {
                                position: absolute;
                                top: 1.5em;
                                right: 1.5em;
                            }

                            .form-content {

                                .status-msg {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                    margin: 2em 0;
                                    padding: 0 2em;

                                    .alert-box {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        width: 100%;
                                        padding: 2em 0;
                                        margin-bottom: 2em;
                                        color: $alert-box-border;
                                        background-color: $alert-box-bg;
                                        border: 1px dashed $alert-box-border;
                                        border-radius: 18px;

                                        &.success {
                                            color: $bgreen;
                                            background-color: $bgreen-extralight;
                                            border: 1px dashed $bgreen;
                                        }

                                        .alert-title {
                                            font-size: em(22);
                                            font-weight: bold;
                                            padding-bottom: .7em;
                                        }

                                        .alert-text {
                                            color: $gdark;
                                        }

                                    }

                                }

                                .button-wrapper {
                                    font-size: em(13);
                                    margin-top: 1em;
                                }

                                .form-section {
                                    padding-bottom: 4em;

                                    &.hidden {
                                        display: none;
                                    }

                                    .section-title {
                                        font-weight: bold;
                                        padding-bottom: 1em;
                                    }

                                    .custom-textarea {
                                        width: 100%;
                                        height: 125px;
                                        padding: 20px;
                                        font-family: $bfaf;
                                        font-size: em(15);
                                        line-height: 1.5;
                                        background-color: $bwhite;
                                        @include nicerborder(1px, $glightest, 8px);
                                        resize: none;

                                        &.xl {
                                            height: 200px;
                                        }

                                        &:focus {
                                            outline: 0;
                                        }
                                    }

                                    .interview-input-group {
                                        padding: 2em;
                                        background-color: $glighter2;
                                        @include nicerborder(1px, $glighter2, 8px);

                                        &:not(:first-of-type) {
                                            margin-top: 1.5em;
                                        }

                                    }

                                    .line {
                                        display: flex;
                                        width: 100%;
                                        margin-bottom: 2em;

                                        &.hidden {
                                            display: none;
                                        }

                                        .input-cell {
                                            display: flex;
                                            flex-direction: column;
                                            width: 100%;

                                            &.cell-1-2 {
                                                width: 48%;
                                                margin-right: 2%;

                                                &:last-of-type {
                                                    margin-right: 0;
                                                }
                                            }

                                            .label-text {
                                                // display: flex;
                                                // align-items: flex-end;
                                                // flex: 1 0 auto;
                                                // width: 100%;
                                                padding-bottom: .7em;
                                                color: $gray;
                                                font-size: em(14);
                                                line-height: 1.5;
                                            }
                                        }
                                    }

                                    .custom-text-input {
                                        width: 100%;
                                        height: 50px;
                                        padding: 0 20px;
                                        font-family: $bfaf;
                                        font-size: em(15);
                                        line-height: 1.5;
                                        background-color: $bwhite;
                                        @include nicerborder(1px, $glightest, 8px);

                                        &:focus {
                                            outline: 0;
                                        }
                                    }

                                    .add-new-line {
                                        display: inline-block;
                                        margin: 1.5em;
                                        font-size: em(13);
                                        text-transform: uppercase;
                                        cursor: pointer;
                                    }

                                    .btn-wrapper {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }

                                    .checkbox-wrapper {
                                        width: 100%;
                                        padding: 3em;

                                        .custom-checkbox {
                                            display: none;
                                        }

                                        .custom-checkbox-label {
                                            display: flex;
                                            align-items: center;
                                            width: 100%;

                                            .label-text {
                                                width: calc(100% - 35px);
                                                line-height: 1.8;
                                            }

                                            &:before {
                                                display: block;
                                                content: '';
                                                width: 15px;
                                                height: 15px;
                                                margin-right: 20px;
                                                background: $bwhite;
                                                border: 2px solid $bwhite;
                                                outline: 1px solid $glight;
                                                cursor: pointer;
                                            }
                                        }

                                        .custom-checkbox:checked + .custom-checkbox-label:before {
                                            background: $gdark;
                                        }
                                    }

                                    .organization-data {

                                        border: 1px solid $bblack;
                                        width: 100%;

                                        td {
                                            padding: 10px;
                                        }
                                    }
                                }
                            }
                        }

                        .tab-toggler.tab-1:checked ~ .tab-content.tab-1 {
                            display: block;
                        }

                        .tab-toggler.tab-2:checked ~ .tab-content.tab-2 {
                            display: block;
                        }

                        .tab-toggler.tab-3:checked ~ .tab-content.tab-3 {
                            display: block;
                        }
                    }
                }

                .functions-header {
                    padding: 2.5em 3em;

                    &.self-eval-header {
                        background-color: $glighter2;
                    }

                    &.self-eval {
                        position: relative;
                        display: flex;
                        align-items: flex-end;
                        height: 85px;
                        padding: 0 3em;
                        background-color: $glighter2;

                        .company-name {
                            position: absolute;
                            right: 0;
                            top: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 48px;
                            padding: 0 2em;
                            background-color: $reg-tile-tr;
                        }

                        .functions-tab {
                            display: flex;

                            .tab {
                                position: relative;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 135px;
                                height: 55px;
                                cursor: pointer;

                                a {
                                    color: $bblack;
                                }

                                &:hover {
                                    background-color: $glightest2;
                                }

                                &.active {
                                    font-weight: bold;
                                    background-color: $glightest2;
                                    cursor: default;

                                    .msg-counter {
                                        font-weight: normal;
                                    }
                                }

                                .msg-counter {
                                    position: absolute;
                                    top: .5em;
                                    right: .5em;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    color: $bwhite;
                                    background-color: $bdanger;
                                }
                            }
                        }
                    }

                    .functions-header-text {
                        font-size: em(17);
                        font-weight: 600;
                    }
                }

                .action-notification {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 1em;
                    margin-bottom: 2em;
                    color: $bdanger;
                    background-color: $orange-light-bg;
                    border: 1px dashed $bdanger;
                    font-size: em(15);
                    font-weight: 600;

                    .icon {
                        position: absolute;
                        left: 1em;
                        top: 0.5em;

                        i {
                            color: $orange-light-bg;
                        }
                    }
                }

                .task-category {
                    padding-bottom: 1em;
                    font-size: em(15);
                    font-weight: 300;
                }

                .title {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding-bottom: 1em;

                    &.mentor-log-title {
                        justify-content: space-between;
                    }

                    .title-text {
                        font-size: em(25);
                        line-height: em(25);
                    }

                    .task-icon {
                        position: absolute;
                        left: -1.5em;
                        font-size: em(25);
                        line-height: em(25);

                        &--blue {
                            color: $bblue;
                        }

                        &--gray {
                            color: $gray;
                        }
                    }

                    .task-done-icon {
                        margin-left: 2em;
                    }

                }

                .form-group {
                    display: block;
                    position: relative;
                    margin-bottom: 2em;

                    .error {
                        display: none;
                    }

                    .inline-inputs {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .date-input-label {
                            padding-right: 1em;
                        }

                        .inline-input {
                            display: flex;
                            align-items: center;

                            .time-input {
                                padding-right: 1em;
                                width: 95px;

                                &.time-input-hour {
                                    padding-left: 1em;
                                }
                            }
                        }

                    }

                    &.has-error {

                        .form-input, .form-selection {
                            border: 1px solid $red;
                        }

                        .error {
                            display: flex;
                            flex: 1 0 0;
                            justify-content: flex-start;
                            align-items: center;
                            margin-top: 1em;

                            .error-text {
                                font-size: em(14);
                                color: $red;
                            }
                        }
                    }

                    .label-text {
                        display: block;
                        padding-bottom: .5em;
                        font-size: em(18);
                        color: $gdark2;
                    }

                    .dev-text {
                        padding: 1em 0 1em 1.5em;
                        font-weight: 300;
                    }

                    .form-selection {
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        width: 100%;
                        border: 1px solid $glighter3;
                        border-radius: 8px;
                        background-color: $bwhite;
                        @include appearance(none);

                        &:after {
                            position: absolute;
                            right: 10px;
                            top: 20px;
                            z-index: 0;
                            content: $font-Flaticon-down-arrow;
                            font-family: "Flaticon";
                            font-size: em(13);
                        }

                        select {
                            position: relative;
                            z-index: 1;
                            width: 100%;
                            flex: 1 0 0;
                            height: 50px;
                            padding-left: 15px;
                            font-size: em(15);
                            // line-height: 50px;
                            outline: 0;
                            border: 0;
                            background-color: transparent;
                            font-weight: 300;
                            @include appearance(none);
                        }


                        option.placeholder {
                            color: $glighter;
                            font-weight: 300;
                            @include appearance(none);
                        }
                    }

                    .form-input {
                        display: block;
                        width: 100%;
                        height: 50px;
                        padding-left: 15px;
                        font-size: em(15);
                        // line-height: 50px;
                        border: 1px solid $glighter3;
                        border-radius: 8px;
                        @include appearance(none);

                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            color: $glighter;
                            font-weight: 300;
                        }
                        &::-moz-placeholder { /* Firefox 19+ */
                            color: $glighter;
                            font-weight: 300;
                        }
                        &:-ms-input-placeholder { /* IE 10+ */
                            color: $glighter;
                            font-weight: 300;
                        }
                        &:-moz-placeholder { /* Firefox 18- */
                            color: $glighter;
                            font-weight: 300;
                        }

                        &.date {
                            width: 255px;
                        }
                    }

                    .custom-textarea {
                        width: 100%;
                        padding: 1em;
                        margin-top: 1em;
                        border: 1px solid $glighter3;
                        border-radius: 8px;
                        background-color: $bwhite;
                        font-family: $bfaf;
                        font-size: em(14);
                        @include appearance(none);
                        resize: none;

                        &:focus {
                            @include appearance(none);
                            outline: none;
                        }
                    }

                    .date-edit {
                        display: flex;
                        align-items: center;

                        i {
                            padding-left: .5em;
                            color: $gray;
                        }
                    }

                    &.description-expandable {

                        .dev-text {
                            display: none;

                            &:first-of-type {
                                display: block;
                            }
                        }

                        .read-more {
                            font-weight: 300;
                            text-decoration: underline;
                            cursor: pointer;
                        }

                        &.expanded {

                            .dev-text {
                                display: block;
                            }

                            .read-more {
                                display: none;
                            }
                        }
                    }

                    .value {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 1em 2em;
                        color: $gray;
                        font-size: em(18);
                        font-weight: 300;

                        .stars {
                            padding-left: 1em;
                            color: $gdark2;
                            font-size: em(22);

                            .star-wrapper {
                                position: relative;

                                .star {
                                    cursor: pointer;

                                    &.on {
                                        color: $bsecondary;

                                        &:before {
                                            content: $font-Flaticon-star;
                                        }
                                    }
                                }

                                .hidden-radio {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    margin: 0;
                                    visibility: hidden;
                                    z-index: -10;
                                }
                            }
                        }
                    }
                }

                .attachments {
                    padding-bottom: 2em;

                    .title {
                        font-size: em(18);
                    }

                    .list {
                        padding-left: 1.5em;

                        .list-item {
                            position: relative;
                            display: flex;
                            align-items: center;
                            min-height: 50px;
                            font-size: em(15);
                            font-weight: 300;

                            .icon {
                                margin: 0;
                                margin-left: 1em;

                                i {
                                    color: $gdark;
                                }
                            }

                            a {
                                color: $bblack;
                                text-decoration: underline;

                                &:hover {
                                    color: $bblack;
                                }

                                &:visited {
                                    color: $bblack;
                                }
                            }
                        }
                    }
                }

                .button-wrapper {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 1em;

                    &.single-button-right {
                        justify-content: flex-end;
                    }

                    &.buttons-centered {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .modifier-link {
                            padding-top: 1em;
                            color: $bblack;
                            text-decoration: underline;

                            &:hover {
                                color: $bblack;
                            }

                            &:visited {
                                color: $bblack;
                            }
                        }

                        .btn {
                            margin-bottom: 2em;
                        }
                    }

                    &.btn-task-rating {
                        margin: 0 -1em;

                        .btn {
                            display: flex;
                            align-items: center;
                            font-size: em(14);

                            .task-rating-icon {
                                font-size: em(35);
                                padding-right: .3em;
                                margin-left: -.5em;
                            }
                        }

                        &--bottom {
                            margin: 1em -4em -2em -4em;
                            padding: 2em 3em;
                            background-color: $glighter2;
                        }
                    }

                    .btn {
                        font-size: em(16);
                        font-weight: 300;
                    }

                    .delete {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        a {
                            color: $bblack;
                            text-decoration: underline;

                            &:hover {
                                color: $bblack;
                            }

                            &:visited {
                                color: $bblack;
                            }
                        }
                    }
                }

                .summary {
                    position: relative;
                    padding: 1em 4em;
                    margin: 0 -4em;
                    background-color: $glighter2;

                    .description-title {
                        color: $gray;
                    }

                    .task-ready {
                        position: absolute;
                        right: 0;
                        top: 0;
                        display: flex;
                        align-items: center;
                        padding: 1em;

                        .icon {
                            margin-right: .5em;
                        }

                        .task-ready-text {
                            font-size: em(17);
                        }
                    }
                }

                .messages {
                    padding: 2em 0;

                    &:after {
                        content: '';
                        display:block;
                        clear:both;
                    }

                    .message-bubble {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        float: left;
                        width: 80%;
                        margin-bottom: 2em;
                        padding: 2em;
                        clear: both;
                        color: $bblack;
                        background: $bwhite;
                        border: 1px solid $bwhite;
                        @include border-radius(25px);
                        font-weight: 300;
                        line-height: em(20);
                        word-wrap: break-word;

                        .timestamp {
                            display: block;
                            padding-bottom: .5em;
                            font-size: em(12);
                        }

                        .message-text {
                            display: block;
                            font-size: em(15);
                        }

                        .attachment-icon {
                            padding-right: 1em;
                        }

                        .attachment-link {
                            color: $bblack;
                            text-decoration: underline;

                            &:hover {
                                color: $bblack;
                            }

                            &:visited {
                                color: $bblack;
                            }
                        }

                        &.blue {
                            float: right;
                            background: $bblue-message-bubble;
                            border: 1px solid $bblue-message-bubble;
                            color: $bwhite;

                            &:before {
                                left: auto;
                                right: -5px;
                            }

                            &:after {
                                left: auto;
                                right: -5px;
                                border-bottom-color: $bblue-message-bubble;
                            }

                            .attachment-link {
                                color: $bwhite;
                                text-decoration: underline;

                                &:hover {
                                    color: $bwhite;
                                }

                                &:visited {
                                    color: $bwhite;
                                }
                            }
                        }

                        &:before, &:after {
                            border-radius: 20px / 5px;
                            content: '';
                            display: block;
                            position: absolute;
                        }

                        &:before {
                            border: 10px solid transparent;
                            border-bottom-color: rgba(0,0,0,0.5);
                            bottom: 0px;
                            left: -7px;
                            z-index: -2;
                        }

                        &:after {
                            border: 8px solid transparent;
                            border-bottom-color: $bwhite;
                            bottom: 3px;
                            left: -5px;
                        }

                        .message-delete {

                            .icon {
                                margin: 0;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .new-message {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    textarea {
                        width: 100%;
                    }

                    .message-add-attachment {
                        width: 40%;
                    }

                    .message-add-text {
                        width: 50%;
                    }
                }

                .erase-task {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 100%;
                    padding-top: 4em;

                    .erase-link {
                        color: $bblack;
                        text-decoration: underline;

                        &:hover {
                            color: $bblack;
                        }

                        &:visited {
                            color: $bblack;
                        }
                    }
                }

                .mentor-events-wrapper {
                    padding-bottom: 2em;

                    .checkbox-wrapper {
                        padding: 3em 0;

                        .custom-checkbox {
                            display: none;
                        }

                        .custom-checkbox-label {
                            display: flex;
                            align-items: center;
                            width: 200px;

                            &:before {
                                display: block;
                                content: '';
                                width: 25px;
                                height: 25px;
                                margin-right: 10px;
                                background: $bwhite;
                                border: 4px solid $bwhite;
                                outline: 1px solid $glight;
                                cursor: pointer;
                            }
                        }

                        .custom-checkbox:checked + .custom-checkbox-label:before {
                            background: $gdark;
                        }
                    }

                    .events {

                        .event-item {
                            padding: 1em 0;
                            margin-bottom: 1em;
                            border-radius: 45px;
                            background-color: $bwhite;

                            &.hidden {
                                display: none;
                                border: 1px solid $glight;

                                .event-item-content {

                                    .message-text {
                                        color: $gray;
                                        font-weight: 400;
                                    }
                                }

                                &.active {
                                    display: block;
                                }
                            }

                            .event-item-content {
                                position: relative;
                                padding: 0 5em;
                                font-weight: 300;

                                .timestamp {
                                    display: block;
                                    padding-bottom: 1em;
                                    font-size: em(12);
                                    color: $glight;
                                }

                                .message-text {
                                    display: block;
                                    font-size: em(15);
                                    line-height: em(30);
                                    color: $gdark;
                                }

                                .event-icon {
                                    position: absolute;
                                    left: 2.5em;
                                    top: 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 100%;

                                    i {
                                        font-size: em(20);
                                        color: $gray;
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}

.self-evaluation-popup-wrapper {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba($gdark, 0.7);
    text-align: left;

    &.active {
        display: block;
    }

    .se-flex {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .se-popup {
        position: relative;
        width: 45em;
        z-index: 1000;
        background-color: $glight2;
        border-radius: 15px;
        @include opacity(1);

        .title-wrapper {
            padding: 2em;

            .title {
                display: block;
                font-family: $secondary-font-family;
                font-size: em(35);
                line-height: em(25);
                color: $bdanger;
            }
        }

        .form-wrapper {
            margin: 0 4em;

            form {
                width: 100%;
            }

            .form-group {
                display: block;
                position: relative;
                margin-bottom: 2em;

                .error {
                    display: none;
                }

                &.has-error {

                    .form-input, .form-selection {
                        border: 1px solid $red;
                    }

                    .error {
                        display: flex;
                        flex: 1 0 0;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 1em;

                        .error-text {
                            font-size: em(14);
                            color: $red;
                        }
                    }
                }

                .label-text {
                    display: block;
                    padding-bottom: .5em;
                    font-size: em(18);
                    color: $gdark2;
                }

                .hint {
                    font-size: em(13);
                    font-weight: 300;
                    line-height: em(20);
                    color: $gray;
                }

                .form-input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    padding-left: 15px;
                    font-size: em(15);
                    // line-height: 50px;
                    border: 1px solid $glighter3;
                    border-radius: 8px;

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: $glighter;
                        font-weight: 300;
                    }

                    &.text-area {
                        height: 220px;
                        margin: 1em 0;
                        padding: .5em;
                        line-height: normal;
                        resize: none;
                    }
                }

                &.form-submit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    flex-wrap: wrap;

                    .btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 3em;
                        margin-bottom: 1.5em;
                        font-size: em(18);
                        line-height: em(18);
                        font-weight: 300;
                        letter-spacing: 0.2em;
                    }
                }

            }

        }

        .fi-close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }
}
