@import url('https://fonts.googleapis.com/css?family=Merriweather:300,400,700,900&subset=latin,latin-ext');

@font-face {
    font-family: 'LatoWeb';
    src: url('../fonts/LatoLatin-Light.eot');
    src: url('../fonts/LatoLatin-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/LatoLatin-Light.woff2') format('woff2'),
         url('../fonts/LatoLatin-Light.woff') format('woff'),
         url('../fonts/LatoLatin-Light.ttf') format('truetype'),
         url('../fonts/LatoLatin-Light.svg#LatoWeb') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('../fonts/LatoLatin-Regular.eot');
    src: url('../fonts/LatoLatin-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/LatoLatin-Regular.woff2') format('woff2'),
         url('../fonts/LatoLatin-Regular.woff') format('woff'),
         url('../fonts/LatoLatin-Regular.ttf') format('truetype'),
         url('../fonts/LatoLatin-Regular.svg#LatoWeb') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('../fonts/LatoLatin-Bold.eot');
    src: url('../fonts/LatoLatin-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/LatoLatin-Bold.woff2') format('woff2'),
         url('../fonts/LatoLatin-Bold.woff') format('woff'),
         url('../fonts/LatoLatin-Bold.ttf') format('truetype'),
         url('../fonts/LatoLatin-Bold.svg#LatoWeb') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('../fonts/LatoLatin-Black.eot');
    src: url('../fonts/LatoLatin-Black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/LatoLatin-Black.woff2') format('woff2'),
         url('../fonts/LatoLatin-Black.woff') format('woff'),
         url('../fonts/LatoLatin-Black.ttf') format('truetype'),
         url('../fonts/LatoLatin-Black.svg#LatoWeb') format('svg');
    font-weight: 800;
    font-style: normal;
}
