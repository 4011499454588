.event-calendar {
    background: $bwhite;
}

.cal-header {
    height: 32px;
    display: block;
    position: relative;
    text-align: left;
    background: $bblue-dark;

    .cal-actual-month {
        display: block;
        font-size: 1.1em;
        line-height: 32px;
        text-align: center;
    }

    .cal-directions {
        width: 100%;
        height: 32px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
        text-align: center;

        .cal-direction {
            color: $bwhite;
            font-size: 18px;
            position: absolute;
            top: 6px;
            &#cal-prev { left: .5em; }
            &#cal-next { right: .5em; }
        }
    }
}

.cal-body {
    display: block;

    table {
        width: 100%;
        border-collapse: collapse;
        box-shadow: 0 0 15px rgba($bblack, .15);

        thead th {
            width: 14%;
            text-align: center;
            padding: .5em;
            color: $bwhite;
            font-size: .9em;
            font-weight: normal;
            background: $bblue-dark;
        }

        tbody td {
            width: 14%;
            text-align: center;
            border: 1px solid transparent;

            a {
                display: block;
                padding: 7px;
                color: $bblack;
                font-size: 13px;
                font-weight: bold;
                border: none;
                outline: none;
                &:hover { background: tint($gbg, 30%); }
            }

            &.has-event {
                background: $gbg;
                color: $bred;
                a {
                    color: $bred;
                    &:hover { background: $gbg; }
                }
            }
            &.inactive {
                background: none;
                border: 1px solid transparent;
                a { font-weight: normal; opacity: .3; color: $bblack; pointer-events: none; }
            }
            &.today {
                background: tint($bwhite, 90%);
                a {
                    color: $bprimary;
                    &:hover {
                        color: $bprimary;
                        background: tint($bwhite, 70%);
                    }
                }
            }
        }
    }
}
