.infotar-wrapper {
    width: 100%;
    min-width: 1024px;
    font-size: $base-font-size-px;

    .back-to-home {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .home {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: $gray;
            text-transform: uppercase;
            font-weight: 100;

            &:visited {
                color: $gray;
            }
        }
    }

    .title {
        padding-bottom: 1em;
        font-family: $secondary-font-family;
        font-size: em(35);
        font-weight: 300;
    }

    .infotar-doc-wrapper {
        padding-bottom: 3em;

        .btn-new {
            position: absolute;
            right: 2em;
            top: 15px;
            font-size: 0.9375em;
            font-weight: 300;
        }

        .infotar-doc-header {
            display: inline-flex;
            align-items: center;
            height: 70px;
            width: 100%;
            background-color: $glightest2;
            border-bottom: 1px solid $glighter;

            .search-wrapper {
                position: relative;
                display: flex;
                align-items: center;
                width: 30%;
                margin-left: 1em;
                transition: all 500ms ease-in-out;

                .search-input {
                    width: 100%;
                    height: 48px;
                    padding-left: 1em;
                    font: normal 1em $bfaf;
                    color: $gray;
                    border: 2px solid $glightest;
                    border-radius: $rad;
                    outline: none;
                    &:focus { border-color: $glighter; }
                    &:hover { border-color: lighten($glighter, 12%); }
                    @include appearance(none);
                }

                .search-submit {
                    position: absolute;
                    top: 50%;
                    right: 4px;
                    color: $bwhite;
                    width: 40px;
                    height: 40px;
                    margin-top: -20px;
                    font-size: 16px;
                    background: $glight;
                    border: none;
                    border-radius: 50%;
                    outline: none;
                    cursor: pointer;
                    &:hover { background: $gray; }
                }
            }

            .filter-wrapper {
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                width: 70%;
                padding: 0 2em;

                .form-selection {
                    display: inline-flex;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    width: 24%;
                    border: 1px solid $glighter3;
                    border-radius: 8px;
                    background-color: $bwhite;
                    @include appearance(none);

                    &:after {
                        position: absolute;
                        right: 10px;
                        top: 20px;
                        z-index: 0;
                        content: $font-Flaticon-down-arrow;
                        font-family: "Flaticon";
                        font-size: em(13);
                    }

                    select {
                        position: relative;
                        z-index: 1;
                        width: 100%;
                        flex: 1 0 0;
                        height: 48px;
                        padding-left: 15px;
                        font-size: em(15);
                        // line-height: 50px;
                        outline: 0;
                        border: 0;
                        background-color: transparent;
                        font-weight: 300;
                        @include appearance(none);
                    }


                    option.placeholder {
                        color: $glighter;
                        font-weight: 300;
                        @include appearance(none);
                    }
                }
            }
        }

        .infotar-item-list {

            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 20px;

            .doc-list-header {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                //width: 85%;
                height: 50px;
                padding: 0 1em;
                margin: 0 auto;
                margin-top: 40px;
                font-size: em(14);
                font-weight: 300;
                border-bottom: 1px solid $glighter;

                .doc-name {
                    width: 40%;
                }

                .doc-validated {
                    width: 25%;
                }

                .doc-date {
                    width: 8%;
                }

                .doc-type {
                    width: 12%;
                }

                .doc-details {
                    width: 10%;
                }
            }

            .doc-list-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                //width: 85%;
                min-height: 50px;
                padding: 0 1em;
                margin: 0 auto;
                font-size: em(14);
                font-weight: 300;
                border-bottom: 1px solid $glightest;
                line-height: 1.2em;

                .doc-name {
                    width: 40%;
                    font-weight: 400;
                    padding-right: 1em;
                    padding-top: .5em;
                    padding-bottom: .5em;

                    small {
                        font-weight: 300;
                    }
                }

                .doc-validated {
                    width: 25%;
                    text-align: center;
                    display: flex;
                    justify-content: flex-end;
                    padding-left: 1em;
                    padding-right: 1em;
                    color: $bgreen;
                    font-size: em(14);

                    i {
                        font-size: 150%;
                        margin-right: 5px;
                    }

                    .name {
                        font-weight: bold;
                        margin-right: 5px;
                    }
                }

                .doc-date {
                    width: 8%;
                }

                .doc-type {
                    width: 12%;
                }

                .doc-details {
                    width: 10%;
                    text-align: center;
                }

                .doc-download {
                    width: 5%;
                    text-align: right;

                    .icon {
                        cursor: pointer;
                    }
                }

                .icon {
                    margin: 0;

                    .fi {
                        color: $bwhite;
                    }
                }
            }


            .btn {
                font-size: em(10);

                &.btn-validate {
                    padding: .5em 1.5em;
                    align-items: center;
                    display: flex;

                    .validate-icon {
                        font-size: em(35);
                        padding-right: .3em;
                        margin-left: -.5em;
                    }
                }
            }
        }

        .list-nav {
            .items-per-page {
                display: none;
            }
        }
    }

    .infotar-map-wrapper {
        display: block;

        .infotar-map-header {
            display: block;
            width: 120px;
            padding: 2em;
            font-size: em(17);
            font-weight: 300;
            background-color: $glightest2;
        }

        .infotar-map {
            padding: 2em;
            background-color: $glightest2;

            .infotar-map-container {
                min-height: 550px;
                width: 100%;
            }
        }
    }
}
