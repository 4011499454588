@import "./vendor/bower_components/trumbowyg/dist/ui/sass/trumbowyg";

.trumbowyg-button-pane {
    background-color: #FFF;
    border-radius: 8px 8px 0 0;
    padding: 0;
    overflow: hidden;
}

.trumbowyg-editor {
    border-radius: 0 0 8px 8px;
}

.trumbowyg-box, .trumbowyg-editor {
    margin: 0;
    /*min-height: 180px;*/
}

.trumbowyg-box {
    border-radius: 8px;
}

.trumbowyg-button-pane button.trumbowyg-active {
    background-color: $glighter2;
    outline: none;
}

.trumbowyg-button-pane .trumbowyg-button-group {
    margin-bottom: -1px;
}

.trumbowyg-counter {
    display: block;
    float: right;
    margin: 8px;
    font-weight: 100;
}
/*
.trumbowyg-editor[contenteditable=true]:empty:not(:focus):before {
    content: attr(placeholder);
}
*/
