.site-footer {
    width: 100%;
    display: block;
    background: $gdark;
    padding: 3em 0;

    .wrapper {
        display: flex;
        justify-content: space-between;
    }

    .footer-col-flex {
        flex: 1;
        display: flex;
        justify-content: space-between;
    }

    .footer-col {
        width: 45%;
        display: inline-block;
        padding: 0 1em;
        letter-spacing: 0.1em;
        &.footer-col-social {
            display: flex;
            justify-self: flex-end;
            align-items: center;
        }

        .footer-col-title {
            display: block;
            color: $bwhite;
            text-transform: uppercase;
            margin-bottom: 1em;
        }

        ul.footer-nav {
            li {
                width: 100%;
                padding: .5em 0;

                a {
                    font-size: 1em;
                    color: $glighter;
                    line-height: 1.5em;
                    &:hover { text-decoration: underline; }
                }
            }
        }

        .footer-social-links {
            .social-link { margin: 0 1em; }
        }
    }
}