// component variables
$tear-s: em(35);
$tear-s-text: em(13);
$tear-m: em(55);
$tear-m-text: em(20);
$dot-size: em(10);

$tear-sizes:    (s, $tear-s, $tear-s-text),
                (m, $tear-m, $tear-m-text);

$tear-blue: $bblue;
$tear-dblue: $bprimary-light;
$tear-yellow: $bsecondary;
$tear-gray: $glighter;
$tear-purple: $bpurple;

$tear-colors:   ('blue', $tear-blue),
                ('dblue', $tear-dblue),
                ('yellow', $tear-yellow),
                ('gray', $tear-gray),
                ('purple', $tear-purple);

.tear {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include border-radius(80% 0 55% 50% / 55% 0 80% 50%);
    @include transform(rotateZ(225deg));

    .tear-icon {
        @include transform(rotateZ(-225deg));
    }

    .item-icon {
        position: absolute;
        bottom: ((-$icon-size-s) * .75);
        left: 1em;
        margin: 0;
        @include transform(rotateZ(-225deg));
    }

    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        height: $dot-size;
        width: $dot-size;
        top: em(-8);
        right: em(-8);
        @include border-radius(50%);
        @include transform(rotateZ(-225deg));
    }

    &.tear-no-bubble {
        top: em(70);
        left: em(-41);
    }

    &.tear-no-dot {

        &::before {
            display: none;
        }
    }

    @each $name, $size, $text in $tear-sizes {
        &.tear-#{$name} {
            width: #{$size};
            height: #{$size};

            .tear-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $bwhite;
                font-size: #{$text};
                width: #{$text};
                height: #{$text};
            }

            .tear-text {
                padding-right: .2em;
                color: $bwhite;
                font-size: $text;
                font-weight: 300;
                @include transform(rotateZ(-225deg));
            }
        }
    }

    @each $name, $color in $tear-colors {
        &.tear-#{$name} {
            background-color: #{$color};

            &::before {
                background-color: #{$color};
            }
        }
    }
}
