.market-wrapper {

    .tag {
        margin-bottom: em(20);
        font-size: em(24);
        font-weight: normal;
    }

    .preview-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: row wrap;
        color: $bblack;
        background-color: $glightest2;
        margin-top: em(20);
        margin-bottom: em(20);

        .back-to-choice {
            color: $glight2;
            background-color: $glighter2;
            width: em(80);
            height: em(80);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: center;

            &:visited { color: $glight2; }

            i { margin: 0 auto; }
        }

        .btn-advert-submit {
            margin-left: 2em;
            padding: 1.5em 2em;
            font-weight: 300;
        }
    }



    .list {
        padding-top: 2em;

        .under-construction {
            font-size: em(24);
            font-family: $sfaf;
        }

        .list-item {
            display: flex;
            margin: 1em 0;
            border-radius: 8px;
            @include box-shadow(-2px 2px 10px $glightest);

            .details {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 40%;
                padding: 2em 3em;
                border-radius: 8px;
                background-color: $reg-tile-tl;

                .product-icon {
                    position: absolute;
                    top: -.5em;
                    left: -.5em;
                }

                .product-name {
                    font-size: em(25);
                    line-height: em(20);
                }

                .product-category {
                    color: $glighter;
                    font-size: em(12);
                    font-weight: 300;
                    font-style: italic;
                }

                .product-description {
                    padding: 1em 0;
                    color: $glighter;
                    font-size: em(15);
                    font-weight: 300;
                    line-height: em(25);
                }

                .product-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: auto;
                    color: $glighter;
                    font-size: em(13);
                    font-weight: 300;
                    line-height: em(25);

                    .company {
                        width: 45%;

                        .company-text {
                            color: $bblack;
                            font-size: em(20);
                            font-weight: 600;
                        }
                    }

                    &.not-product {

                        .company {
                            width: 65%;
                        }

                        .date {
                            margin-right: 0;
                            margin-left: auto;
                        }
                    }

                    .date {
                        margin-top: auto;
                    }

                    .image-counter {
                        margin-top: auto;
                    }
                }
            }

            .image {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35%;
                padding: 2.5em;

                img {
                    max-width: 100%;
                }

                .short-description {
                    margin-bottom: auto;
                    line-height: 1.2em;
                    color: $gray;
                    font-weight: 100;
                }
            }

            .contact {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 35%;
                padding: 2em 3em;

                .contact-details {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    flex-grow: 1;
                    margin-bottom: em(10);

                    .company-name {
                        font-size: em(16);
                        font-weight: 600;
                        line-height: em(32);
                        text-align: center;
                    }

                    .address {
                        font-size: em(16);
                        line-height: em(32);
                        text-align: center;
                        font-weight: lighter;
                        margin-top: em(10);
                    }

                    .status {
                        display: block;
                        width: 100%;

                        .stock {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-top: 3em;
                            color: $bgreen;

                            .icon {
                                margin-right: .5em;
                            }
                        }

                        .on-request {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-top: 3em;
                            color: $glighter;
                        }

                        .price {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-top: 2em;
                            font-weight: 600;

                            &.not-product {
                                padding-top: .5em;
                            }
                        }

                        .price-net {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-top: 1em;
                            font-size: em(12);
                            font-weight: 300;
                        }

                        .rate {
                            padding: 3em 0 0 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;

                            .btn {
                                margin-bottom: 1em;
                                color: $gdark2;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .btn {
                    font-size: em(12);
                    font-weight: 300;
                }
            }
        }
    }

    .market-product {
        width: 100%;

        .title {
            padding-bottom: 1em;

            .title-text {
                display: block;
                font-family: $sfaf;
                font-size: em(35);
                font-weight: 400;
            }

            .title-category {
                color: $glighter;
                font-size: em(12);
                font-weight: 300;
                font-style: italic;
            }
        }

        .main-info {
            display: flex;
            width: 100%;
            @include box-shadow(-2px 2px 10px $glightest);
            border-radius: 8px;

            .gallery {
                width: 40%;
                padding: 2em 3em;

                .image-view {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: auto;
                    cursor: zoom-in;

                    img {
                        max-width: 100%;
                    }
                }

                .thumbs {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding-top: 2em;
                    flex-flow: row wrap;

                    .thumbnail {
                        display: block;
                        margin: .3em;
                        border: 2px solid $reg-tile-bl;
                        border-radius: 2px;
                        width: 30%;
                        cursor: pointer;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .desc {
                width: 30%;
                padding: 4em 3em 0;
                color: $gray;
                background-color: $reg-tile-tl;
                font-size: em(14);
                line-height: em(28);

                &.desc-service {
                    width: 70%;
                }

                .headline {
                    padding-bottom: 6em;
                    font-weight: 400;
                }

                .description {
                    padding-bottom: 3em;
                    font-weight: 300;
                }
            }

            .info {
                width: 30%;
                display: flex;
                flex-direction: column;

                .status {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 10px;
                    min-height: 150px;

                    .stock {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 3em;
                        color: $bgreen;

                        .icon {
                            margin-right: .5em;
                        }
                    }

                    .on-request {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 3em;
                        color: $glighter;
                    }

                    .price {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 2em;
                        font-weight: 600;
                    }

                    .price-net {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 1em;
                        font-size: em(12);
                        font-weight: 300;
                    }

                    .rate {
                        padding: 3em 0 0 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;

                        .btn {
                            margin-bottom: 1em;
                            color: $gdark2;
                            cursor: pointer;
                        }
                    }
                }

                .report-advert {
                    display: flex;
                    justify-content: center;
                    margin: 10px;

                    a {
                        font-size: 12px;
                        color: $gray;
                    }
                }

                .address-contact-wrapper {
                    display: block;

                    .address-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        width: 100%;
                        background-color: $gbg;
                        padding-bottom: 2em;

                        .address-header {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 50px;
                            background-color: $glighter2;

                            .address-icon-wrapper {
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                width: 50px;
                                height: 50px;
                                background-color: $glightest;

                                .address-icon {
                                    color: $glight2;
                                }
                            }

                            .address-title {
                                padding-left: 1em;
                                font-size: em(16);
                                color: $gray;
                            }
                        }

                        .address-info {
                            text-align: center;
                            font-size: em(14);
                            line-height: em(20);
                            padding: 2em 0 0 0;
                            width: 100%;

                            .opening {
                                font-weight: 300;
                            }
                        }
                    }

                    .contact {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        width: 100%;
                        color: $bwhite;
                        background-color: $bdanger;
                        border-bottom-right-radius: 8px;

                        .contact-header {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 50px;
                            background-color: darken($dorange, 3%);

                            .contact-icon-wrapper {
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                width: 50px;
                                height: 50px;
                                background-color: $dorange;

                                .contact-icon {
                                    color: $bwhite;
                                }
                            }

                            .contact-title {
                                padding-left: 1em;
                                font-size: em(16);
                                color: $bwhite;
                            }
                        }

                        .contact-info {
                            padding: 2.5em 0;
                        }
                    }
                }
            }
        }

        .tabs-ratings {
            display: flex;
            justify-content: space-between;
            padding: 2em 0;

            .more-info-tabs {
                display: flex;
                width: 65%;
                border-bottom: 2px solid $glightest2;

                .tab {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 1.5em 2em;
                    font-size: em(16);
                    cursor: pointer;

                    &.active {
                        background-color: $glightest2;
                    }
                }
            }

            .rating-wrapper {
                display: flex;
                width: 30%;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 0 4em;

                .rating-title {
                    width: 100%;
                    padding-bottom: .5em;
                    font-size: em(11);
                    font-weight: 300;
                    text-transform: uppercase;
                    color: $gdark2;
                    border-bottom: 1px solid $reg-tile-bl;
                }

                .rating-value {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    font-size: em(30);
                    font-weight: 300;
                    color: $gdark2;

                    .stars {
                        color: $bsecondary;
                    }
                }
            }
        }

        .more-info {
            display: inline-flex;
            justify-content: space-between;
            width: 100%;

            .more-info-content {
                display: block;
                width: 65%;
                padding: 1em 0 1em 0;

                .no-rating {
                    text-align: center;
                    font-style: italic;
                }

                .tab-content {
                    display: none;

                    &.active {
                        display: block;
                    }
                }

                .description-wrapper {
                    padding: 0 0 1.5em 2em;

                    .section-title {
                        color: $bgreen;
                        font-size: em(24);
                        font-family: $sfaf;
                        font-weight: 300;
                    }

                    .content-row {
                        display: flex;
                        flex-flow: row wrap;

                        .description {
                            padding: 1.5em 0;
                            color: $gray;
                            font-size: em(14);
                            font-weight: 300;
                            line-height: em(28);
                            width: 100%;

                            &-60 {
                                flex: 6
                            }
                        }

                        .img-container {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 1.5em 0;

                            &-40 {
                                flex: 4;


                                &-right {
                                    margin-left: 40px;
                                }

                                &-left {
                                    margin-right: 40px;
                                }
                            }

                            img {
                                max-width: 100%;
                            }
                        }

                    }
                }

                .rating {
                    padding: 0 0 1.5em 2em;

                    .headline {
                        display: flex;
                        align-items: flex-start;
                        align-items: center;
                        color: $gdark2;
                        font-size: em(15);
                    }

                    .stars {
                        display: block;
                        padding: .8em 0;

                        .star {
                            color: $bsecondary;
                        }
                    }

                    .text {
                        display: block;
                        padding-bottom: 2em;
                        color: $gdark2;
                        font-size: em(15);
                        font-weight: 300;
                        font-style: italic;
                        line-height: em(26);
                        border-bottom: 1px solid $reg-tile-bl;
                    }
                }

                .contact-wrapper {
                    display: flex;

                    .contacts {
                        width: 35%;
                        padding: 3em 2em;
                        background-color: $reg-tile-tl;

                        .contacts-title {
                            font-family: $sfaf;
                            font-size: em(22);
                            color: $bdanger;
                        }

                        .contact-item {
                            padding: 2em 0 0 1em;
                            font-size: em(15);
                            font-weight: 300;
                            line-height: em(30);

                            .name {
                                font-weight: 400;
                            }
                        }
                    }

                    .message {
                        width: 65%;
                        background-color: $reg-tile-bl;

                        .message-title {
                            padding: 2em 2em 1em 2em;
                            font-family: $sfaf;
                            font-size: em(22);
                            color: $bdanger;
                        }

                        .form-group {
                            display: block;
                            padding: 1em 3em;

                            .label-text {
                                display: block;
                                padding-bottom: .5em;
                                font-size: em(18);
                                color: $gdark2;

                                &.label-text-inline {
                                    display: inline-block;
                                }
                            }

                            .form-input {
                                display: block;
                                width: 100%;
                                height: 50px;
                                padding-left: 15px;
                                font-size: em(15);
                                // line-height: 50px;
                                border: 1px solid $glighter3;
                                border-radius: 8px;
                                @include appearance(none);

                                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                    color: $glighter;
                                    font-weight: 300;
                                }
                                &::-moz-placeholder { /* Firefox 19+ */
                                    color: $glighter;
                                    font-weight: 300;
                                }
                                &:-ms-input-placeholder { /* IE 10+ */
                                    color: $glighter;
                                    font-weight: 300;
                                }
                                &:-moz-placeholder { /* Firefox 18- */
                                    color: $glighter;
                                    font-weight: 300;
                                }

                                &.text-area {
                                    height: 220px;
                                    resize: none;
                                }
                            }
                        }

                        .btn {
                            margin: 1em 0 3em 3em;
                        }
                    }
                }

            }

            .ratings {
                display: block;
                width: 30%;
                background-color: $reg-tile-tl;

                .ratings-header {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 115px;
                    background-color: $reg-tile-tr;

                    .header-text {
                        font-size: em(22);
                        text-transform: uppercase;
                    }
                }

                .ratings-content {
                    padding: 3em;

                    .rating {
                        padding-bottom: 3em;

                        .headline {
                            display: flex;
                            align-items: flex-start;
                            align-items: center;
                            color: $gdark2;
                            font-size: em(15);

                            .nr-of-stars {
                                padding-left: 1em;
                                color: $glight2;
                                font-size: em(13);
                            }
                        }

                        .stars {
                            display: block;
                            padding: .8em 0;

                            .star {
                                color: $bsecondary;
                            }
                        }

                        .text {
                            color: $gdark2;
                            font-size: em(15);
                            font-weight: 300;
                            font-style: italic;
                            line-height: em(26);
                        }
                    }
                }
            }
        }

        .more-products {
            width: 100%;
            margin-top: 4em;
            background-color: $reg-tile-tl;

            .title {
                padding: 1em 0 1em 2em;
                background-color: $reg-tile-tr;
                font-family: $sfaf;
                font-size: em(25);
            }

            .products {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2em 4em;
                flex-flow: row wrap;

                .product-tile {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    width: 30%;
                    align-self: stretch;
                    padding: 3em 3em 1em 3em;
                    background-color: $bwhite;
                    @include box-shadow(-2px 2px 10px $glightest);
                    border-radius: 8px;
                    margin-bottom: 2.5em;
                    margin-left: 2.5em;

                    &:nth-child(3n) {
                        page-break-after: always; /* CSS 2.1 syntax */
                        break-after: always; /* New syntax */
                    }

                    &:nth-child(3n-2) {
                        margin-left: 0;
                    }

                    .tile-icon {
                        position: absolute;
                        top: -5px;
                        left: -5px;
                    }

                    .headline {
                        min-height: 65px;
                        padding-bottom: 1em;

                        .name {
                            font-size: em(25);
                            line-height: em(20);
                        }

                        .category {
                            color: $glighter;
                            font-size: em(12);
                            font-weight: 300;
                            font-style: italic;
                        }
                    }

                    .image-container {
                        text-align: center;

                        img {
                            max-width: 100%;
                        }
                    }

                    .features-container {

                        margin-top: auto;
                        padding-bottom: 2em;

                        .feature-title {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-top: 1em;
                            font-size: 0.75em;
                            font-weight: 300;
                        }

                        .feature-description {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: 600;
                            padding-top: .5em;
                        }

                    }

                    .stock {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 1.5em;
                        color: $bgreen;

                        .icon {
                            margin-right: .5em;
                        }
                    }

                    .on-request {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 3em;
                        color: $glighter;
                    }

                    .price {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 2em 0;
                        font-weight: 600;
                        margin-top: auto;
                    }

                    .details-button {
                        text-align: center;
                        margin-top: auto;
                    }
                }
            }

            .button-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1em 0 3em 0;

                .btn {
                    font-weight: 300;
                }
            }
        }

        .company-intro {
            display: flex;
            margin-top: 2em;
            background-color: $reg-tile-tl;

            .info {
                width: 60%;
                padding: 3em 4em 3em 3em;

                .company-name {
                    padding-bottom: 2em;
                    font-family: $sfaf;
                    font-size: em(32);
                    font-weight: 300;
                    color: $bpurple;
                }

                .description {
                    padding-bottom: 2em;
                    color: $gray;
                    font-size: em(14);
                    font-weight: 300;
                    line-height: em(28);


                    a {
                        color: #15c !important;
                        text-decoration: underline !important;
                    }

                    div,
                    p,
                    ul,
                    ol,
                    blockquote {
                        box-shadow: none !important;
                        background: none !important;
                        margin: 0 !important;
                        margin-bottom: 15px !important;
                        line-height: 1.4em !important;
                        border: none;
                    }
                    blockquote {
                        margin-left: 32px !important;
                        font-style: italic !important;
                        color: #555;
                    }
                    ul,
                    ol {
                        padding-left: 20px !important;
                    }
                    ul ul,
                    ol ol,
                    ul ol,
                    ol ul {
                        border: none;
                        margin: 2px !important;
                        padding: 0 !important;
                        padding-left: 24px !important;
                    }
                }
            }

            .img-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40%;
                padding: 2.5em;
                background-color: $glightest2;

                img {
                    max-width: 100%;
                }

                div {
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    width: 100%;
                    height: 100%;
                    min-height: 300px;
                }
            }
        }
    }

    .list-nav {
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

.modal-wrapper,
.rating-popup-wrapper,
.reporting-popup-wrapper {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba($gdark, 0.7);
    text-align: left;

    &.active {
        display: block;
    }

    .modal-flex,
    .reporting-flex,
    .rating-flex {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal,
    .reporting-popup,
    .rating-popup {
        position: relative;
        width: 30em;
        z-index: 1000;
        background-color: $glight2;
        border-radius: 15px;
        @include opacity(1);

        .title-wrapper {
            padding: 2em;

            .title {
                display: block;
                font-family: $secondary-font-family;
                font-size: em(35);
                color: $bdanger;
            }

            .product {
                font-size: em(17);
                line-height: em(32);
            }
        }

        .form-wrapper {
            margin: 0 4em;
            width: auto;

            form {
                width: 100%;
            }

            .form-group {
                display: block;
                position: relative;
                margin-bottom: 2em;

                .error {
                    display: none;
                }

                &.has-error {

                    .form-input, .form-selection {
                        border: 1px solid $red;
                    }

                    .error {
                        display: flex;
                        flex: 1 0 0;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 1em;

                        .error-text {
                            font-size: em(14);
                            color: $red;
                        }
                    }
                }

                .label-text {
                    display: block;
                    padding-bottom: .5em;
                    font-size: em(18);
                    color: $gdark2;
                }

                .value {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $gray;
                    font-weight: 300;

                    .stars {
                        padding-left: 1em;
                        color: $gdark2;
                        font-size: em(22);

                        .star-wrapper {
                            position: relative;

                            .star {
                                cursor: pointer;

                                &.on {
                                    color: $bsecondary;

                                    &:before {
                                        content: $font-Flaticon-star;
                                    }
                                }
                            }

                            .hidden-radio {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                margin: 0;
                                visibility: hidden;
                                z-index: -10;
                            }
                        }
                    }
                }

                .hint {
                    font-size: em(13);
                    font-weight: 300;
                    line-height: em(20);
                    color: $gray;
                }

                .form-input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    padding-left: 15px;
                    font-size: em(15);
                    // line-height: 50px;
                    border: 1px solid $glighter3;
                    border-radius: 8px;

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: $glighter;
                        font-weight: 300;
                    }

                    &.text-area {
                        height: 220px;
                        margin: 1em 0;
                        padding: .5em;
                        line-height: normal;
                        resize: none;
                    }
                }

                &.form-submit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    flex-wrap: wrap;

                    .legal {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-width: 100%;

                        .legal-text {
                            margin-left: 1em;
                            font-size: em(14);

                        }

                    }

                    .btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 3em;
                        margin-bottom: 1.5em;
                        font-size: em(18);
                        line-height: em(18);
                        font-weight: 300;
                        letter-spacing: 0.2em;
                    }
                }
            }

        }

        .fi-close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }
}

.list-nav {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .list-nav-text {
        width: 50%;
        font-weight: 300;
        font-style: italic;

        .list-nav-clear-filter {
            padding-left: 1em;
            font-size: em(12);
            color: $glighter;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .list-pagination {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding-top: 1em;

        .icon {
            margin-left: 1em;
            margin-right: 1em;
            cursor: pointer;

            .icon-rotate-180 {

                &:before {
                    @include rotate(180deg);
                }
            }
        }

        .pagination-pages {
            background-color: $reg-tile-tl;
            border-radius: 32px;

            .pagenr {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                padding: 1em;
                font-size: em(12);
                color: $gray;
                cursor: pointer;

                &.active {
                    border-radius: 32px;
                    color: $bwhite;
                    background-color: $bsecondary;
                }
            }

            .dotted {
                color: $gray;
                font-size: em(12);
            }
        }
    }

    .items-per-page {
        font-weight: 300;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        width: 25%;

        .items-per-page-text {
            padding-right: .5em;
        }

        .custom-dropdown {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            width: 90px;
            height: 35px;
            padding: 1em;
            border: 1px solid $glighter3;
            border-radius: 8px;
            background-color: $bwhite;
            cursor: pointer;

            .placeholder {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .icon {
                    margin-right: 1em;
                }

                a {
                    color: $bblack;
                }
            }

            &:after {
                position: absolute;
                right: 10px;
                top: 11px;
                z-index: 0;
                content: $font-Flaticon-down-arrow;
                font-family: "Flaticon";
                font-size: em(13);
            }

            .dropdown {
                position: absolute;
                z-index: 2;
                top: 100%;
                left: -1px;
                right: -1px;
                border: 1px solid $glighter3;
                border-top: none;
                border-radius: 8px;
                background-color: $bwhite;
                opacity: 0;
                pointer-events: none;

                .dropdown-item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    padding: 1em;
                    cursor: pointer;

                    .icon {
                        margin-right: 1em;
                    }

                    a {
                        color: $bblack;
                    }
                }
            }

            &.active {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;

                .dropdown {
                    opacity: 1;
                    pointer-events: auto;
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                }
            }
        }
    }

}

.list-nav--bottom {
    padding-top: 1em;
    padding-bottom: 2em;
}
