.site-header {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
    background: $bwhite;
    display: block;

    .wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        width: 215px;
        height: 56px;
        display: block;
        background: url(../img/logo.jpg) no-repeat;
        background-size: 100%;
        text-indent: -9999px;
        outline: none;
    }

    .szechenyi-logo {
        width: 225px;
        height: 175px;
        position: absolute;
        top: 0;
        right: 0;
        background: url(../img/szechenyi.png) no-repeat;
        background-size: 100%;
    }

    .ofa-logo {
        width: 225px;
        height: 175px;
        position: absolute;
        top: 0;
        right: 0;
        background: url(../img/logo-ofa.png) no-repeat;
        background-size: 100%;
    }

    .header-flex {
        flex: 1;
        height: auto;
        display: flex;
        justify-content: space-between;

        &.header-custom {
            display: flex;
            height: auto;
            flex-wrap: wrap;

            .header-bar {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                padding: .5em 0;

                .header-search-form {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin-right: 230px;

                    .sightless-block {
                        display: flex;
                        align-items: center;
                        margin-right: 1em;

                        .lang-link {
                            color: $glight;
                            display: inline-block;
                            margin-right: .5em;
                            &:hover { text-decoration: underline; }
                            &.selected { color: $bblack; }
                        }

                        .sl {
                            color: $bblack;
                            text-align: left;
                            display: inline-block;
                            margin-left: .5em;
                            &:hover { color: lighten($bblack, 5%); }
                            i.fa { display: inline-block; color: $yellow; }
                        }

                        .sl-size {
                            width: 30px;
                            height: 30px;
                            display: inline-block;
                            background: $yellow;
                            margin: 0 .15em;
                            text-align: center;
                            line-height: 30px;
                            color: $bblack !important;
                            &.sl-size-plus { font-size: 1.3em; }
                            &.sl-size-minus { font-size: .9em; }
                        }
                    }

                    .search-input-wrapper {
                        position: relative;
                        display: flex;
                        align-items: center;

                        .search-input {
                            width: 100%;
                            height: 48px;
                            font: normal 1em $bfaf;
                            color: $gray;
                            padding: 0 2em;
                            border: 2px solid $glightest;
                            border-radius: $rad;
                            outline: none;
                            &:focus { border-color: $glighter; }
                            &:hover { border-color: lighten($glighter, 12%); }
                            @include appearance(none);
                        }

                        .search-submit {
                            position: absolute;
                            top: 50%;
                            right: 4px;
                            color: $bwhite;
                            width: 40px;
                            height: 40px;
                            margin-top: -20px;
                            font-size: 16px;
                            background: $glight;
                            border: none;
                            border-radius: 50%;
                            outline: none;
                            cursor: pointer;
                            &:hover { background: $gray; }
                        }

                    }

                    .user-login {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        margin-right: 1em;
                        cursor: pointer;

                        .icon {
                            margin-right: .5em;
                        }

                        .user-login-text {
                            position: relative;
                            margin-right: 1em;
                            color: $bdanger;
                            font-size: em(12);
                            text-transform: uppercase;
                        }

                        .btn-logged-in {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            z-index: 2;
                            padding: 0 1em;
                            margin: 0;
                            border: none;

                            .menu-text {
                                align-self: center;
                                font-size: em(13);
                                font-weight: 300;
                                margin-right: 1.5em;
                                margin-left: .5em;
                            }

                            .icon {
                                right: -1.5em;
                            }
                        }

                        .menu-wrapper {
                            position: absolute;
                            display: none;
                            justify-content: center;
                            align-items: baseline;
                            flex-wrap: wrap;
                            padding-top: 4em;
                            z-index: 1;
                            top: 0;
                            left: 0;
                            width: 100%;
                            background-color: $bgreen-dark;
                            border-radius: 25px;
                            cursor: default;

                            &.active {
                                display: flex;
                                @include animation-duration(150ms);
                            }

                            .menu-content {
                                position: relative;
                                z-index: 0;
                                width: 100%;
                            }

                            .menu-list {
                                padding: 0 2em;
                                color: $bwhite;
                                list-style-type: none;
                                font-weight: 300;
                                text-transform: uppercase;
                                text-align: center;

                                li {
                                    padding: 1em 0;
                                    font-size: em(15);
                                    border-bottom: 1px solid darken($bgreen-dark, 10%);

                                    a {
                                        color: $bwhite;

                                        &:visited {
                                            color: $bwhite;
                                        }
                                    }
                                }
                            }

                            .btn-logout {
                                padding: 1em 1.2em;
                                font-size: em(12);
                                font-weight: 300;
                                margin: 4em 0 2em 0;
                                border: 1px solid $glighter;
                                color: $bwhite;

                                .fi {
                                    margin-right: -.5em;
                                }
                            }
                        }
                    }
                }
            }
        }

        .nav-menu {
            margin: 0 1.5em;

            ul {
                margin: 2em 0;
                list-style: none;
                border-top: 1px solid $glighter2;
                border-bottom: 1px solid $glighter2;

                li {
                    position: relative;
                    display: inline-block;
                    a {
                        display: inline-block;
                        font-size: 1em;
                        color: $gray;
                        text-transform: uppercase;
                        padding: .75em .9em;
                    }
                    &.active {
                        background-color: $gbg;
                    }
                    &:hover {
                        a { text-decoration: underline; }
                    }

                    &.mentor {
                        background-color: $bsecondary;
                        transition: transform 200ms ease-in-out;
                        &:hover, &.active {
                            transform: scaleY(1.2);
                            a {
                                transform: scaleY(0.8);
                                text-decoration: none;
                            }
                        }
                        a {
                            transition: transform 200ms ease-in-out;
                            color: $bwhite;
                        }
                    }

                    &.piacter {
                        background-color: $bgreen-dark;
                        transition: transform 200ms ease-in-out;
                        &:hover, &.active {
                            transform: scaleY(1.2);
                            a {
                                transform: scaleY(0.8);
                                text-decoration: none;
                            }
                        }
                        a {
                            transition: transform 200ms ease-in-out;
                            color: $bwhite;
                        }
                    }

                    &.piacter {
                        background-color: $bgreen-dark;
                        transition: transform 200ms ease-in-out;
                        &:hover, &.active {
                            transform: scaleY(1.2);
                            a {
                                transform: scaleY(0.8);
                                text-decoration: none;
                            }
                        }
                        a {
                            transition: transform 200ms ease-in-out;
                            color: $bwhite;
                        }
                    }

                    &.infotar {
                        background-color: $bblue;
                        transition: transform 200ms ease-in-out;
                        &:hover, &.active {
                            transform: scaleY(1.2);
                            a {
                                transform: scaleY(0.8);
                                text-decoration: none;
                            }
                        }
                        a {
                            transition: transform 200ms ease-in-out;
                            color: $bwhite;
                        }
                    }

                    &.partner {
                        background-color: $bpurple-dark;
                        transition: transform 200ms ease-in-out;
                        &:hover, &.active {
                            transform: scaleY(1.2);
                            a {
                                transform: scaleY(0.8);
                                text-decoration: none;
                            }
                        }
                        a {
                            transition: transform 200ms ease-in-out;
                            color: $bwhite;
                        }
                    }

                }
            }
        }
    }

    .search-form {
        width: 220px;
        height: 130px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        position: relative;
        margin-right: 215px;

        .sightless-block {
            display: flex;
            align-items: center;
            margin: -15px 0 15px 0;

            .lang-link {
                color: $glight;
                display: inline-block;
                margin-right: .5em;
                &:hover { text-decoration: underline; }
                &.selected { color: $bblack; }
            }

            .sl {
                color: $bblack;
                text-align: left;
                display: inline-block;
                margin-left: .5em;
                &:hover { color: lighten($bblack, 5%); }
                i.fa { display: inline-block; color: $yellow; }
            }

            .sl-size {
                width: 30px;
                height: 30px;
                display: inline-block;
                background: $yellow;
                margin: 0 .15em;
                text-align: center;
                line-height: 30px;
                color: $bblack !important;
                &.sl-size-plus { font-size: 1.3em; }
                &.sl-size-minus { font-size: .9em; }
            }
        }

        .search-input {
            width: 100%;
            height: 48px;
            font: normal 1em $bfaf;
            color: $gray;
            padding: 0 1em;
            border: 2px solid $glightest;
            border-radius: $rad;
            outline: none;
            &:focus { border-color: $glighter; }
            &:hover { border-color: lighten($glighter, 12%); }
        }

        .search-submit {
            position: absolute;
            top: 55%;
            right: 4px;
            color: $bwhite;
            width: 40px;
            height: 40px;
            margin-top: -20px;
            font-size: 16px;
            background: $glight;
            border: none;
            border-radius: 50%;
            outline: none;
            cursor: pointer;
            &:hover { background: $gray; }
        }
    }

    .nav-menu-toggler {
        display: none;
        background: $bblack;
        color: $bwhite;
        font-size: 1.4em;
        padding: 1em;
        text-transform: uppercase;
        text-align: left;
        cursor: pointer;
        &.open {
            i.fa:before { content: '\f060' }
        }
    }

    .piacter-header {
        width: 1280px;
        margin: 0 auto;
        text-align: left;

        .piacter-breadcrumbs {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: 2em;

            .back-to-market-home {
                color: $glight2;
                background-color: $glighter2;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                text-align: center;

                i {
                    margin: 0 auto;
                }
            }

            .piacter-breadcrumbs-text {
                padding-left: 1em;
                font-size: em(13);
                font-weight: 300;
                color: $glighter;
            }
        }

        .title {
            font-family: $secondary-font-family;
            font-size: em(35);
            font-weight: 300;
        }

        .desc {
            padding: 1em 0 2em 0;
            font-size: em(14);
            font-weight: 300;
            color: $glighter;
        }
    }
}
