@import "reset";

@import "variables";
@import "mixins";
@import "fonts";
@import "flickity";
@import "pikaday";
@import "trumbowyg";
@import "modules/flaticon";
@import "select2";
@import "dropzone";

$lg-path-images: "../img/lightgallery";
$backdrop-opacity: .6;
$lg-theme-highlight: $bhighlight;

$flaticon-font-path: "../fonts";

@import "./vendor/bower_components/compass-sass-mixins/lib/compass";
@import "./vendor/bower_components/font-awesome/scss/font-awesome";
@import "./vendor/bower_components/lightgallery/src/sass/lightgallery";
@import "./vendor/bower_components/animate.css/animate";

body {
    background: $body-bgc;
    font-family: $bfaf;
    font-size: 14px;
    color: $bblack;
    text-align: center;
    overflow-y: scroll;
}

.wrapper {
    width: $base-wrapper;
    position: relative;
    margin: 0 auto;
    text-align: left;
}

@import "ui";

@import "modules/breadcrumb";
@import "modules/pagebody";
@import "modules/pagination";
@import "modules/icons";
@import "modules/tear";

@import "partials/header";
@import "partials/layout";
@import "partials/calendar";
@import "partials/sidebar";
@import "partials/footer";
@import "partials/modal";
@import "partials/entity-choice-animation";

@import "pages/home";
@import "pages/single";
@import "pages/page";
@import "pages/category";
@import "pages/event";
@import "pages/media";
@import "pages/tag";
@import "pages/registration";
@import "pages/profile";
@import "pages/advert";
@import "pages/market";
@import "pages/infotar";
@import "pages/mentor";
@import "pages/selfeval";

@import "mediaqueries";
