// Base variables
$base-wrapper: 1280px;
$body-bgc: #fff;
$rad: 25px;

// Brand colors
$bwhite: #fff;
$bblack: #2c2c2c;

$bprimary: #295165;
$bprimary-light: lighten($bprimary, 5%);
$bprimary-dark: darken($bprimary, 5%);

$bsecondary: #FFC80A;
$bsecondary-light: lighten($bsecondary, 5%);
$bsecondary-dark: darken($bsecondary, 5%);

$bblue: #4e95ba;
$bblue-light: lighten($bblue, 5%);
$bblue-dark: #4d7488;
$bblue-message-bubble: #007EE5;

$bgreen: #3e9782;
$bgreen-light: lighten($bgreen, 5%);
$bgreen-extralight: #F0FAF7;
$bgreen-dark: #2d806d;
$bgreen-active: #6CBF12;

$bpurple: #8c4f93;
$bpurple-light: lighten($bpurple, 5%);
$bpurple-dark: #824889;

$bred: #df7e2f;
$bred-light: lighten($bred, 5%);
$bred-dark: darken($bred, 5%);

$bbrown: #82733E;

$bhighlight: $bsecondary;

$bsuccess: #3e9782;
$bwarning: #df7e2f;
$bdanger: #f05638;
$dorange: darken($bdanger, 10%);
$orange-light-bg: #FFEEEB;

$gray: #828282;
$gdark: #2c2c2c;
$gdark2: #4A4A4A;
$glight: #afafaf;
$glight2: #B5B5B5;
$glighter: #9fa3a7;
$glighter2: #E7E7E7;
$glighter3: #E0E0E0;
$glightest: #dfdfdf;
$glightest2: #F0F0F0;
$gbg: #f7f8f9;

$reg-choice-tile-top-left: #fafafa;
$reg-choice-tile-top-right: #e1e1e1;
$reg-choice-tile-bottom-left: #efefef;
$reg-choice-tile-bottom-right: #E7E7E7;

$reg-tile-tl: $reg-choice-tile-top-left;
$reg-tile-tr: $reg-choice-tile-top-right;
$reg-tile-bl: $reg-choice-tile-bottom-left;
$reg-tile-br: $reg-choice-tile-bottom-right;

$yellow: $bhighlight;
$red: #D0011B;
$terracotta: #B0361E;
$redtc: $terracotta;

$alert-box-bg: #F8E4E4;
$alert-box-border: #FD5656;

$base-font-size: 16;
$base-font-size-px: 16px;
// Font sizes
@function em($target, $context: $base-font-size) {
    @return ($target / $context) * 1em;
}

// Base font
$base-font-family: 'LatoWeb', Helvetica, Arial, sans-serif;
$bfaf: $base-font-family;

// Secondary font
$secondary-font-family: 'Merriweather', Helvetica, Arial, sans-serif;
$sfaf: $secondary-font-family;

// Icon font
$icon-font-family: 'FontAwesome';
$ifaf: $icon-font-family;
