.pagination {
    width: 100%;
    text-align: center;
    margin: 2em 0;
    padding: 1em;
    display: flex;
    justify-content: center;

    li {
        display: inline-block;
        margin: 0 .5em;
        list-style: none;
        font-family: $bfaf;
        a {
            color: $bwhite;
            font-size: 1.35em;
            font-weight: normal;
            padding: .25em .6em;
            border: none;
            border-radius: 50%;
            background: $bsecondary;
            text-decoration: none;
            &:hover { background: lighten($bsecondary, 5%); }
        }

        &.active a {
            color: $bwhite;
            border: none;
            background: $bsecondary;
        }

        &.disabled a {
            cursor: not-allowed;
            color: $bwhite;
            border: none;
            background: $glight;
        }

        &:first-child {
            margin-left: 0;
            a { color: $bwhite; background: none; }
        }

        &:last-child {
            margin-right: 0;
            a { color: $bwhite; background: none; }
        }
    }
}