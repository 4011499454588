.gallery-list {
    display: flex;
    flex-wrap: wrap;
    margin: .5em 0 1em 0;

    .gallery-item {
        width: 47%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: .75em;
        background: $bwhite;
        border: 1px solid $glightest;
        border-radius: 10px;
        box-shadow: 1px 2px 2px rgba($bblack, .1);

        .gallery-item-preview {
            background: $bwhite;
            border-radius: 10px 10px 0 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            img {
                width: 100%;
                display: block;
                border-radius: 10px 10px 0 0;
            }
        }

        .gallery-item-data {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 1.5em;

            h2 {
                font-size: 1.5em;
                font-weight: normal;
                line-height: 1.5em;
                margin-bottom: .75em;
                a {
                    color: $bblack;
                    &:hover { text-decoration: underline; }
                }
            }

            p {
                font-size: .9em;
                color: $glight;
                span { display: inline-block; margin-right: 1em; }
            }
        }
    }
}

.gallery-masonry {
    width: 100%;
    position: relative;
    display: block;

    .grid-sizer { width: 30%; }
    .gutter-sizer { width: 30px; }

    .gallery-masonry-item {
        width: 30%;
        display: inline-block;
        margin: 2em 0;
        text-align: center;

        img {
            max-width: 100%;
            display: block;
            margin: 0 auto 1em auto;
        }

        .gallery-pic-caption {
            display: block;
            text-align: left;
            font-size: 1.2em;
            line-height: 1.5em;
            color: $bblack;
        }
    }
}

.gallery-lightbox {
    display: flex;
    flex-wrap: wrap;
    margin: .5em 0 1em 0;

    .gallery-pic {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 1.25em .75em;

        .gallery-pic-preview {
            height: 150px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            align-items: center;

            img { width: auto; max-width: 90%; height: 130px; display: block; margin: .75em auto; }
            span { display: block; font-size: .9em; line-height: 1.5em; color: $bblack; }
        }
    }
}