.reg-wrapper {

    .entity-choice {

        .choice-wrapper {

            .choice-tiles {

                &.top-left-expanded {

                    .choice-tile {

                        &--top-left {
                            height: 69%;

                            .title {
                                // @include transform (translate(-50%, 0));
                                top: 1.5em;
                                transition: all 500ms ease-in-out;
                            }

                            .content {
                                @include opacity(1);
                                @include transform (translate(0, 0));
                                transition: all 500ms ease-in-out;
                            }

                        }

                        &--bottom-left {
                            top: 69%;
                            height: 31%;

                            .title {
                                // @include transform (translate(-50%, 0));
                                bottom: 0;
                                transition: all 250ms ease-in-out;
                            }

                            .choice-tile--logo {
                                padding: 0;
                            }

                        }
                    }
                }

                &.bottom-left-expanded {

                    .choice-tile {

                        &--top-left {
                            height: 31%;

                            .title {
                                top: 0;
                                // @include transform (translate(-50%, 0%));
                                transition: all 250ms ease-in-out;
                            }

                            .choice-tile--logo {
                                padding: 0;
                            }
                        }

                        &--bottom-left {
                            top: 31%;
                            height: 69%;

                            .title {
                                bottom: 11em;
                                // @include transform (translate(-50%, 0%));
                                transition: all 500ms ease-in-out;
                            }

                            .content {
                                @include opacity(1);
                                @include transform (translate(0, 0));
                                transition: all 500ms ease-in-out;
                            }
                        }
                    }
                }

                &.top-right-expanded {

                    .choice-tile {

                        &--top-right {
                            width: 62%;
                            left: 38%;

                            .title {
                                @include transform (translate(-125%, 0%));
                                transition: all 500ms ease-in-out;
                            }

                            .content {
                                @include opacity(1);
                                @include transform (translate(0, -25%));
                                transition: all 500ms ease-in-out;
                            }
                        }

                        &--top-left {
                            width: 38%;
                        }

                    }
                }

                &.bottom-right-expanded {

                    .choice-tile {

                        &--bottom-right {
                            width: 62%;
                            left: 38%;

                            .title {
                                @include transform (translate(-125%, 0%));
                                transition: all 500ms ease-in-out;
                            }

                            .content {
                                @include opacity(1);
                                @include transform (translate(0, -25%));
                                transition: all 500ms ease-in-out;
                            }
                        }

                        &--bottom-left {
                            width: 38%;
                        }
                    }
                }

            }
        }
    }
}
