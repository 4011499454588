    /*
    Flaticon icon font: Flaticon
    Creation date: 29/11/2016 16:02
    */

    @font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-arrow:before { content: "\f100"; }
.flaticon-arrows:before { content: "\f101"; }
.flaticon-avatar:before { content: "\f102"; }
.flaticon-bell:before { content: "\f103"; }
.flaticon-building:before { content: "\f104"; }
.flaticon-calendar:before { content: "\f105"; }
.flaticon-cancel:before { content: "\f106"; }
.flaticon-checked:before { content: "\f107"; }
.flaticon-clipboard:before { content: "\f108"; }
.flaticon-clothes:before { content: "\f109"; }
.flaticon-down-arrow:before { content: "\f10a"; }
.flaticon-e-mail-envelope:before { content: "\f10b"; }
.flaticon-exclamation-sign:before { content: "\f10c"; }
.flaticon-eye:before { content: "\f10d"; }
.flaticon-facebook-logo-button:before { content: "\f10e"; }
.flaticon-favorite:before { content: "\f10f"; }
.flaticon-idea:before { content: "\f110"; }
.flaticon-information-button:before { content: "\f111"; }
.flaticon-information-symbol:before { content: "\f112"; }
.flaticon-left-arrow:before { content: "\f113"; }
.flaticon-linkedin-button:before { content: "\f114"; }
.flaticon-magnifying-glass:before { content: "\f115"; }
.flaticon-mail:before { content: "\f116"; }
.flaticon-network:before { content: "\f117"; }
.flaticon-open-book:before { content: "\f118"; }
.flaticon-pencil-edit-button:before { content: "\f119"; }
.flaticon-plus-black-symbol:before { content: "\f11a"; }
.flaticon-question-mark:before { content: "\f11b"; }
.flaticon-question-mark-on-a-circular-black-background:before { content: "\f11c"; }
.flaticon-question-sign:before { content: "\f11d"; }
.flaticon-racing-flag:before { content: "\f11e"; }
.flaticon-replay:before { content: "\f11f"; }
.flaticon-right-arrow:before { content: "\f120"; }
.flaticon-save:before { content: "\f121"; }
.flaticon-save-file-option:before { content: "\f122"; }
.flaticon-search:before { content: "\f123"; }
.flaticon-search-1:before { content: "\f124"; }
.flaticon-share:before { content: "\f125"; }
.flaticon-star:before { content: "\f126"; }
.flaticon-star-half-empty:before { content: "\f127"; }
.flaticon-sticker:before { content: "\f128"; }
.flaticon-sticker-1:before { content: "\f129"; }
.flaticon-strategy:before { content: "\f12a"; }
.flaticon-success:before { content: "\f12b"; }
.flaticon-twitter-logo-button:before { content: "\f12c"; }
.flaticon-user-silhouette:before { content: "\f12d"; }
.flaticon-users:before { content: "\f12e"; }

    $font-Flaticon-arrow: "\f100";
    $font-Flaticon-arrows: "\f101";
    $font-Flaticon-avatar: "\f102";
    $font-Flaticon-bell: "\f103";
    $font-Flaticon-building: "\f104";
    $font-Flaticon-calendar: "\f105";
    $font-Flaticon-cancel: "\f106";
    $font-Flaticon-checked: "\f107";
    $font-Flaticon-clipboard: "\f108";
    $font-Flaticon-clothes: "\f109";
    $font-Flaticon-down-arrow: "\f10a";
    $font-Flaticon-e-mail-envelope: "\f10b";
    $font-Flaticon-exclamation-sign: "\f10c";
    $font-Flaticon-eye: "\f10d";
    $font-Flaticon-facebook-logo-button: "\f10e";
    $font-Flaticon-favorite: "\f10f";
    $font-Flaticon-idea: "\f110";
    $font-Flaticon-information-button: "\f111";
    $font-Flaticon-information-symbol: "\f112";
    $font-Flaticon-left-arrow: "\f113";
    $font-Flaticon-linkedin-button: "\f114";
    $font-Flaticon-magnifying-glass: "\f115";
    $font-Flaticon-mail: "\f116";
    $font-Flaticon-network: "\f117";
    $font-Flaticon-open-book: "\f118";
    $font-Flaticon-pencil-edit-button: "\f119";
    $font-Flaticon-plus-black-symbol: "\f11a";
    $font-Flaticon-question-mark: "\f11b";
    $font-Flaticon-question-mark-on-a-circular-black-background: "\f11c";
    $font-Flaticon-question-sign: "\f11d";
    $font-Flaticon-racing-flag: "\f11e";
    $font-Flaticon-replay: "\f11f";
    $font-Flaticon-right-arrow: "\f120";
    $font-Flaticon-save: "\f121";
    $font-Flaticon-save-file-option: "\f122";
    $font-Flaticon-search: "\f123";
    $font-Flaticon-search-1: "\f124";
    $font-Flaticon-share: "\f125";
    $font-Flaticon-star: "\f126";
    $font-Flaticon-star-half-empty: "\f127";
    $font-Flaticon-sticker: "\f128";
    $font-Flaticon-sticker-1: "\f129";
    $font-Flaticon-strategy: "\f12a";
    $font-Flaticon-success: "\f12b";
    $font-Flaticon-twitter-logo-button: "\f12c";
    $font-Flaticon-user-silhouette: "\f12d";
    $font-Flaticon-users: "\f12e";
