.herospace {
    width: 100%;
    height: 100px;
    display: block;

    .wrapper {
        height: 100px;
        background: $bprimary;
        overflow: hidden;
    }

    .hero-slide {
        width: 100%;
        height: 100px;
        display: block;
        position: relative;
        padding: 2em 2em 1.7em 18em;

        .proceed {
            width: 30px;
            height: 30px;
            position: absolute;
            left: 16em;
            top: 50%;
            margin-top: -15px;
            background: url(../img/featured-arrow.svg) no-repeat;
            background-size: 100%;
        }

        .hero-slide-content {
            margin-left: 1.5em;

            .date {
                font-size: .8em;
                color: $glight;
                text-transform: uppercase;
            }

            .hero-title {
                font: normal 1.5em/1.7em $sfaf;

                a {
                    color: $bwhite;
                    &:hover { color: $glightest; }
                }
            }
        }
    }
}

.flickity-page-dots {
    bottom: auto;
    top: 18px;
    left: 1.5em;
    text-align: left;

    .dot {
        background: $bwhite;
        opacity: .75;
        &.is-selected { background: $bhighlight; opacity: 1; }
    }
}

.featured-news {
    position: relative;
    display: block;

    .featured-bg {
        width: 100%;
        display: block;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    .featured-content {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        padding: 2em;
        background: rgba(85, 6, 6, .4);
        text-align: center;
        color: $bwhite;
        &.featured-content-nopic {
            position: static;
            background: rgba(85, 6, 6, 1);
        }

        h2 {
            font: normal 1.8em/1.5em $sfaf;
            color: $bwhite;
        }

        .btn-container {
            display: block;
            margin-top: 1em;
            text-align: center;

            .btn {
                font-size: .8em;
                padding: 1em 2em;
            }
        }
    }
}

.more-news {
    display: flex;
    background: tint($bred-dark, 92%);
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

     .more-news-item {
        width: 50%;
        padding: 4em 1.5em;
        text-align: center;

        h2 {
            font: normal 1.5em/1.5em $sfaf;

            a {
                color: $bred-dark;
                &:hover { color: lighten($bred-dark, 5%); }
            }
        }

        .btn-container {
            display: block;
            margin-top: 1em;
            text-align: center;

            .btn {
                color: $bred-dark;
                border-color: $bred-dark;
                font-size: .8em;
                padding: 1em 2em;
            }
        }
    }
}

.featured-pages {
    display: flex;
    background: #95b793;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .page-item {
        width: 50%;
        padding: 3em 1em;
        text-align: center;

        h2 {
            font: normal 1.7em/1.5em $bfaf;
            color: $bwhite;

            a { color: $bwhite; }
        }

        .btn-container {
            display: block;
            margin-top: 1em;
            text-align: center;

            .btn {
                font-size: .8em;
                padding: 1em 2em;
            }
        }
    }
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}