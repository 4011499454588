// Alerts
.alert {
    width: 100%;
    display: block;
    font-size: .8em;
    line-height: 1.5em;
    font-weight: bold;
    color: $bblack;
    margin: 1.2em 0;
    padding: .5em 2%;

    &.alert-success { color: $bsuccess !important; }
    &.alert-danger { color: $bdanger !important; }
    &.alert-warning { color: $bwarning !important; }

    &.alert-success-border { border: 3px solid $bsuccess !important; }
    &.alert-danger-border { border: 3px solid $bdanger !important; }
    &.alert-warning-border { border: 3px solid $bwarning !important; }

    ul {
        margin-left: 1.5em;
    }
}

// Buttons
.btn {
    display: inline-block;
    font-family: $bfaf;
    font-size: .9em;
    font-weight: normal;
    line-height: 1em;
    background: none;
    border: none;
    border-radius: $rad;
    padding: 1em 2em;
    color: $bblack;
    cursor: pointer;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    outline: none;
    @include transition(all, 200ms, ease-in-out);

    &:hover {
        @include transition(all, 200ms, ease-in-out);
    }

    &.btn-with-icon {
        display: flex;
        align-items: center;
        position: relative;
        height: 35px;
        padding-left: calc(2em + 18px);
        font-size: em(11);
        white-space: nowrap;

        .icon {
            position: absolute;
            left: -2px;
            top: -2px;
            width: 35px;
            height: 35px;

            &:before {
                width: 100%;
                height: 100%;
            }
        }
    }

    &.btn-default {
        color: $bblack;
        font-weight: bold;
        font-family: $bfaf;
        background: $gray;
        &:hover {
            background: darken($gray, 5%);
        }
    }

    &.btn-gray {
        background: $glightest;
        border: 2px solid $glightest;
        color: $gray;

        &:hover {
            background: lighten($glightest, 5%);
            border: 2px solid lighten($glightest, 5%);
        }

        &:disabled {
            cursor: not-allowed;
        }

        &.btn-gray-no-hover {
            cursor: default;
            background: $glightest;
            border: 2px solid $glightest;
        }
    }

    &.btn-gray-light {
        color: $bwhite;
        background: lighten($bblack, 15%);
        border: 2px solid lighten($bblack, 15%);
        text-transform: uppercase;

        &:hover {
            background: lighten($bblack, 10%);
            border: 2px solid lighten($bblack, 10%);
        }

        &.disabled,
        &:disabled {
            cursor: not-allowed;
            background-color: lighten($bblack, 30%);
            border-color: lighten($bblack, 30%);

            &:hover {
                cursor: not-allowed;
                background-color: lighten($bblack, 30%);
                border-color: lighten($bblack, 30%);
            }
        }
    }

    &.btn-disabled-gray:disabled {
        color: $bwhite;
        background: $glighter3;
        border: 2px solid $glighter3;
        text-transform: uppercase;
        cursor: not-allowed;

        &:hover {
            background: $glighter3;
            border: 2px solid $glighter3;
        }
    }

    &.btn-white {
        color: $bprimary;
        font-family: $bfaf;
        background: $bwhite;
        border: 1px solid lighten($gray, 50%);
        &:hover {
            background: $glighter;
        }
    }

    &.btn-blue {
        color: $bwhite;
        background: $bblue;
        border: 2px solid $bblue;

        &:hover {
            background-color: darken($bblue, 10%);
            border-color: darken($bblue, 10%);
        }
    }

    &.btn-dblue {
        color: $bwhite;
        background: $bprimary-light;
        border: 2px solid $bprimary-light;

        &:hover {
            background-color: $bprimary;
            border-color: $bprimary;
        }
    }

    &.btn-yellow {
        color: $bwhite;
        background-color: $bsecondary;
        border: 2px solid $bsecondary;

        &:hover {
            background-color: darken($bsecondary, 10%);
            border: 2px solid darken($bsecondary, 10%);
        }
    }

    &.btn-orange {
        color: $bwhite;
        background-color: $bdanger;
        border: 2px solid $bdanger;
        text-decoration: none;

        &:hover {
            background-color: darken($bdanger, 10%);
            border-color: darken($bdanger, 10%);
        }

        &:disabled {
            cursor: not-allowed;
            background-color: lighten($bdanger, 15%);
            border-color: lighten($bdanger, 15%);

            &:hover {
                cursor: not-allowed;
                background-color: lighten($bdanger, 15%);
                border-color: lighten($bdanger, 15%);
            }
        }
    }

    &.btn-green {
        color: $bwhite;
        background-color: $bgreen;
        border: 2px solid $bgreen;

        &:hover {
            background-color: darken($bgreen, 10%);
            border-color: darken($bgreen, 10%);
        }
    }

    &.btn-black {
        color: $bwhite;
        background-color: $bblack;
        border-color: $bblack;

        &:hover {
            background-color: #000000;
            border-color: #000000;
        }
    }

    &.btn-green-active {
        color: $bwhite;
        background-color: $bgreen-active;
        border: 2px solid $bgreen-active;

        &:hover {
            background-color: darken($bgreen-active, 10%);
            border-color: darken($bgreen-active, 10%);
        }
    }

    &.btn-red {
        color: $bwhite;
        background-color: $red;
        border: 2px solid $red;

        &:hover {
            background-color: darken($red, 10%);
            border-color: darken($red, 10%);
        }
    }

    &.btn-purple {
        color: $bwhite;
        background-color: $bpurple;
        border: 2px solid $bpurple;

        &:hover {
            background-color: darken($bpurple, 10%);
            border-color: darken($bpurple, 10%);
        }
    }

    &.btn-empty {
        color: $gray;
        font-family: $bfaf;
        background: none;
        border: 2px solid $glighter;
        &:hover {
            background: rgba($bwhite, .2);
            color: $bblack;
        }
        &.btn-empty-white {
            color: $bwhite;
            border-color: $bwhite;
            &:hover { color: $bwhite; }
        }

        &.btn-empty-green {
            color: $gray;
            border-color: $bsuccess;
            &:hover { color: $bsuccess; }
        }

        &.btn-empty-orange {
            color: $bdanger;
            border-color: $bdanger;
            &:hover { color: $bred; }
        }

        &.btn-empty-purple {
            color: $gray;
            border-color: $bpurple;
            &:hover { color: $bpurple; }
        }
    }

}

// Social links
.social-link {
    width: 35px;
    height: 35px;
    display: inline-block;
    color: $bwhite;
    text-decoration: none;
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    &.social-link-facebook { background-image: url(../img/icon-facebook.svg); }
    &.social-link-twitter { background-image: url(../img/icon-twitter.svg); }
    &.social-link-linkedin { background-image: url(../img/icon-linkedin.svg); }
    span { display: none; }
    &:hover { opacity: .9; }
}

// Form elements
.form-input {
    height: 2em;
    font-family: $bfaf;
    font-size: 1em;
    font-weight: normal;
    padding: 0 .5em;
    outline: none;
    border-radius: 2px;
    border: 1px solid $glighter;
    background: $bwhite;
    &:hover { background: darken($bwhite, 2%); }
    &:focus {
        border: 1px solid darken($glighter, 25%);
        background: $bwhite;
    }
    &:disabled {
        cursor: not-allowed;
        border: 1px solid $glighter;
        background: $bwhite;
    }
}

.form-textarea {
    display: block;
    width: 100%;
    height: 200px;
    padding: 15px;
    font-size: em(15);
    border: 1px solid $glighter3;
    border-radius: 8px;
    outline: none;
    @include appearance(none);

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $glighter;
        font-weight: 300;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: $glighter;
        font-weight: 300;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: $glighter;
        font-weight: 300;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: $glighter;
        font-weight: 300;
    }

    &:hover { background: darken($bwhite, 2%); }
    &:focus {
        border: 1px solid $glighter3;
        background: $bwhite;
    }
    &:disabled {
        cursor: not-allowed;
        border: 1px solid $glighter;
        background: $bwhite;
    }
}

.form-select {
    font-family: $bfaf;
    font-size: 1em;
    border: 1px solid $glight;
    border-radius: 2px;
    padding: 0;
    outline: none;
}

.has-success {
    color: $bsuccess;
    span { color: $bsuccess !important; }
    .form-input { color: $bsuccess !important; border: 1px solid $bsuccess !important; }
    select { border: 1px solid $bsuccess !important; }
}

.has-warning {
    color: $bwarning;
    span { color: $bwarning !important; }
    .form-input { color: $bwarning !important; border: 1px solid $bwarning !important; }
    select { border: 1px solid $bwarning !important; }
}

.has-error {
    color: $bdanger;
    span { color: $bdanger !important; }
    .form-input { color: $bdanger !important; border: 1px solid $bdanger !important; }
    select { border: 1px solid $bdanger !important; color: $bdanger !important; }
    textarea { border: 1px solid $bdanger !important; }
}

// lightGallery hack
.lg-item { left: 0; }

// text colors for the advert page category icons
$text-color-yellow: $bsecondary;
$text-color-orange: $bdanger;
$text-color-dorange: $dorange;
$text-color-purple: $bpurple;
$text-color-green: $bsuccess;
$text-color-dgreen: $bgreen-dark;
$text-color-blue: $bblue;
$text-color-red: $red;
$text-color-black: #000000;
$text-color-gray: $gdark;

$text-colors:   ('orange', $text-color-orange),
                ('dorange', $text-color-dorange),
                ('yellow', $text-color-yellow),
                ('purple', $text-color-purple),
                ('green', $text-color-green),
                ('dgreen', $text-color-dgreen),
                ('blue', $text-color-blue),
                ('red', $text-color-red),
                ('black', $text-color-black),
                ('gray', $text-color-gray);

.text {
    @each $name, $color in $text-colors {
        &.text-color-#{$name} {
            color: #{$color};
        }
    }
}

.image-preview input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.image-preview {
    width: 100%;
    height: 100%;

    position: relative;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    background-color: hsla(0, 0%, 100%, 0.40);
    background-blend-mode: overlay;
    border: 2px dashed $glightest;


    display: flex;
    align-items: center;
    justify-content: center;
}

.file-upload input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.attachments input[type="file"] {
    display: none;
}

.file-upload {
    width: 100%;
    height: 200px;

    position: relative;

    background-color: $glightest;
    background-blend-mode: overlay;
    border: 2px dashed $glighter;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    line-height: 1.5;
}

.file-upload-label {
    text-align: center;
}

.message-editor {
    display: flex;

    .message-editor-wysiwyg {
        flex: 2;

        .trumbowyg-box,
        .trumbowyg-editor {
            min-height: 164px;
        }
    }

    .message-editor-file {
        flex: 1;
        margin-right: 40px;
    }
}
