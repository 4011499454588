.sidebar {
    width: 335px;

    .tags-container {
        padding: 2.5em;
        background-color: $reg-tile-tr;

        .tag {
            padding-right: .5em;
            font-size: em(15);
            font-weight: 300;
            line-height: em(30);

            a {
                color: $bblack;
            }

            &:nth-child(even) {
                font-size: em(22);
            }
        }
    }

    .partner-search-container {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 100px;
        background-color: $bpurple;
        color: $bwhite;

        .partner-search-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
            font-size: em(50);
            background-color: $bpurple-dark;
        }

        .partner-search-text {
            padding: 0 2em;
            font-size: em(24);
            font-weight: 300;
            line-height: em(24);
        }
    }

    .filter-form {
        width: 100%;
        padding: 2.5em;
        background-color: $glightest2;

        .btn-filter-trigger {
            display: none;
        }

        .form-group {
            display: block;
            margin-top: 1em;

            &.inline-inputs {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                .label-text {
                    width: 100%;
                }

                .inline-input {
                    display: inline-flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex: 1;

                    input {
                        width: 100px;
                    }

                    .label-info {
                        padding: 0 .3em;
                    }
                }
            }

            .label-text {
                display: block;
                padding-bottom: .5em;
                font-size: em(15);
                color: $gdark2;
            }

            .form-input {
                display: block;
                width: 100%;
                height: 50px;
                padding-left: 15px;
                font-size: em(15);
                // line-height: 50px;
                border: 1px solid $glighter3;
                border-radius: 8px;
                @include appearance(none);

                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: $glighter;
                    font-weight: 300;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    color: $glighter;
                    font-weight: 300;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    color: $glighter;
                    font-weight: 300;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    color: $glighter;
                    font-weight: 300;
                }

            }

            .checkbox-flex-wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .form-selection {
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                width: 100%;
                border: 1px solid $glighter3;
                border-radius: 8px;
                background-color: $bwhite;
                @include appearance(none);

                &:after {
                    position: absolute;
                    right: 10px;
                    top: 20px;
                    z-index: 0;
                    content: $font-Flaticon-down-arrow;
                    font-family: "Flaticon";
                    font-size: em(13);
                }

                select {
                    position: relative;
                    z-index: 1;
                    width: 100%;
                    flex: 1 0 0;
                    height: 50px;
                    padding-left: 15px;
                    font-size: em(15);
                    // line-height: 50px;
                    outline: 0;
                    border: 0;
                    background-color: transparent;
                    font-weight: 300;
                    @include appearance(none);
                }


                option.placeholder {
                    color: $glighter;
                    font-weight: 300;
                    @include appearance(none);
                }
            }

            .btn-filter {
                width: 100%;
                padding: 1.5em;
                font-weight: 300;
            }
        }
    }

    .banner-container {
        max-width: 250px;
        margin: 1.5em auto;

        a {
            display: block;
            padding: 1em 0;
            border-bottom: 2px solid $glightest;
            &:last-child { border-bottom: none; }

            img { max-width: 180px; }
        }
    }

    .calendar-container {
        display: block;
        padding: 3em;
        background: $bblue;
        color: $bwhite;

        .calendar-title {
            display: block;
            text-align: center;
            font-size: 1.2em;
            text-transform: uppercase;
            margin-bottom: 1em;
        }

        .btn-container {
            padding-top: 1.5em;
            text-align: center;

            .btn { margin: 0 auto; }
        }
    }

    .newsletter-container {
        display: block;
        padding: 3em 0;
        background: $bdanger;
        color: $bwhite;

        .subscribe-title {
            display: block;
            text-align: center;
            font-size: 1em;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            margin-bottom: 1.5em;
        }

        .subscribe-form {
            display: block;
        }

        .subscribe-group {
            margin: 0 4em;
            position: relative;
            display: block;

            .form-input {
                width: 100%;
                height: 48px;
                font: normal 1em $bfaf;
                color: $gray;
                background: $bwhite;
                border: none;
                border-radius: $rad;
                padding: 0 1.5em;
            }

            .btn-submit {
                width: 42px;
                height: 42px;
                display: block;
                position: absolute;
                top: 3px;
                right: 3px;
                padding: 0;
                background: url(../img/icon-envelope.svg) no-repeat center center $bhighlight;
                border-radius: 50%;
                &:hover { background-color: lighten($bhighlight, 5%); }
            }
        }

        .alert {
            padding: 0 4em;
            color: $bwhite !important;
            border-color: $bwhite !important;
        }
    }
}

.sidebar-box {
    display: block;
    &.sidebar-box-purple {
        background: $bpurple;
        .box-title { background: $bpurple-dark; }
    }
    &.sidebar-box-green {
        background: $bgreen;
        .box-title { background: $bgreen-dark; }
    }
    &.sidebar-box-blue {
        background: $bblue;
        .box-title { background: $bblue-dark; }
    }
    &.sidebar-box-orange {
        background: $bsecondary;
        .box-title {
            background: $bsecondary-dark;
            line-height: 1.7;

            a {
                color: $bwhite;
                &:hover {
                    color: $bwhite;
                }
            }

            &-blue {
                a {
                    color: $bblue-dark;
                    font-size: 18px;
                    &:hover {
                        color: $bblue-dark;
                    }
                }
            }
        }

        .box-content {
            a {
                &:hover {
                    color: $bwhite;
                }
            }
        }
    }
    &.sidebar-box-dark-orange {
        background: lighten($bdanger, 10%);
        .box-title {
            background: $bdanger;
            line-height: 1.7;

            a {
                color: $bwhite;
                &:hover {
                    color: $bwhite;
                }
            }
        }
    }
}

.sidebar-box-category {
    .box-title {
        display: block;
        padding: 1.5em 20px;
        text-align: center;
        font-size: 1.5em;
        color: $bwhite;
        text-transform: uppercase;
    }

    .box-content {
        display: block;
        padding: 2em 3em;

        ul {
            list-style: none;
            margin-left: 2em;

            li {
                padding: .5em 0;

                a {
                    position: relative;
                    font-size: 1em;
                    line-height: 1.5em;
                    color: $bwhite;
                    text-transform: uppercase;
                    &:before {
                        content: "";
                        width: 16px;
                        height: 15px;
                        display: block;
                        position: absolute;
                        top: 2px;
                        left: -2em;
                        background: url(../img/list-arrow-tiny.svg) no-repeat;
                        background-size: 100%;
                        @include transition(left, 0.2s, ease-in-out);
                    }
                    &:hover {
                        color: $bhighlight;
                        &:before { left: -1.8em; }
                    }
                }
            }
        }
    }
}

.sidebar-box-social {
    background: $bblue;
    padding: 1.5em 0;
    text-align: center;

    .social-link {
        margin: 0 1em;
    }
}

.left-nav {
    display: block;
    background: $gbg;
    padding: 2em;

    ul {
        list-style: none;

        li {
            position: relative;
            padding: 1em 0;
            font-size: 1em;
            text-transform: uppercase;


            a {
                color: $bblack;

                &:hover {
                    color: $gray;
                }
            }

            &:not(.no-arrow) {

                a {
                    display: block;
                    color: $bblack;
                    padding-left: 2em;

                    &:before {
                        content: "";
                        width: 16px;
                        height: 15px;
                        display: block;
                        position: absolute;
                        top: 1em;
                        left: 0;
                        background: url(../img/list-arrow-tiny.svg) no-repeat;
                        background-size: 100%;
                        @include transition(left, 0.2s, ease-in-out);
                    }
                    &:hover {
                        color: $gray;
                        &:before { left: 3px; }
                    }
                }
            }
        }
    }
}
