.profile-wrapper {
    width: 85%;
    margin-top: -1em;
    margin-left: 1em;
    font-size: 16px;
    font-weight: 300;

    .profile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: 1.5em;
        padding-right: .5em;
        background-color: $glightest2;
        font-size: em(14);

        .logged-in {
            color: $glight;

            .user-info {
                color: $bblack;
            }
        }

        .logout-btn {
            padding: 1em 1.2em;
            margin: .5em 0;
            font-size: em(13);
            border: 1px solid $gdark2;
            text-transform: uppercase;
            line-height: .8em;
            color: $gdark2;

            i {
                margin-right: -.5em;
            }
        }
    }

    .validated {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding: 2em 0;

        .validated-text {
            margin-right: 1em;
            color: $glight;
            font-size: em(14);
            text-transform: uppercase;
        }

        .btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.6em 1.5em;
            font-weight: 300;
            letter-spacing: .2em;

            .icon {
                margin-left: 1em;
                margin-right: -1em;

                &:hover {
                    .info-bubble {
                        display: block;
                    }
                }

                .info-bubble {
                    display: none;
                    position: absolute;
                    top: 55px;
                    right: -8px;
                    width: 300px;
                    height: auto;
                    padding: 1.5em;
                    color: $bblack;
                    font-size: em(14);
                    line-height: em(22);
                    text-align: left;
                    text-transform: none;
                    background: $glightest2;
                    border-radius: 15px;
                    border: 1px solid $glight;

                    &:after, &:before {
                        right: 12px;
                        top: -16px;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }

                    &:after {
                        border-color: transparent;
                        border-bottom-color: $glightest2;
                        border-width: 15px;
                        margin-top: -14px;
                    }

                    &:before {
                        border-color: transparent;
                        border-bottom-color: $glight;
                        border-width: 15px;
                        margin-top: -15px;
                    }
                }

            }
        }

        .social-enterprise {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: .575em 1em .575em 1em;
            background-color: $bdanger;
            font-size: 90%;

            .approved-text {
                color: $bwhite;
                text-transform: uppercase;
                font-size: em(15);
            }

            .btn {
                margin-left: 1em;
            }
        }

        .validation-approved {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: .5em 2em .5em 1em;
            background-color: $bgreen;
            margin-left: auto;

            .approved-text {
                color: $bblack;
                text-transform: uppercase;
                font-size: em(15);
            }
        }
    }

    .form-wrapper {
        background-color: $glightest2;

        .form-tabs {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: em(16);
            background-color: $bwhite;

            .form-tab {
                padding: 2em 2.5em;
                text-align: center;
                color: $glight;
                background-color: $bwhite;
                cursor: pointer;

                &.active {
                    color: $bblack;
                    background-color: $glightest2;
                    cursor: default;
                }

                &:hover {
                    background-color: $glightest2;
                }
            }
        }

        .validation-error {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2em .5em;
            margin: 2em 4em 0 4em;
            background: lighten($bsecondary, 40%);
            border-radius: 15px;
            border: 1px dashed $bsecondary;
            font-size: em(14);
            line-height: em(28);
            text-align: center;
        }

        .input-wrapper {
            margin: 0 1.5em;
            padding: 2em 0;

            .input-group {
                display: none;

                &.active {
                    display: block;
                }
            }

            .form-group {
            display: block;
            position: relative;
            margin-bottom: 2em;

                .error {
                    display: none;
                }

                &.has-error {

                    .form-input, .form-selection {
                        border: 1px solid $red;
                    }

                    .error {
                        display: flex;
                        flex: 1 0 0;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 1em;

                        .error-text {
                            color: $red;
                        }
                    }
                }
            }

            .inline-inputs {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                &.inline-inputs-space {
                    justify-content: space-between;
                }

                .label-zip {
                    min-width: 10em;
                    margin-right: 1.5em;
                }

                .label-filler {
                    flex: 1 0 0;
                }
            }

            .label-text {
                display: block;
                padding-bottom: .5em;
                font-size: em(18);
                color: $gdark2;

                &.label-text-inline {
                    display: inline-block;
                }
            }

            .separator {
                margin: 0 -1em;

                .section-title {
                    display: block;
                    margin-bottom: 2em;
                    font-size: em(12);
                    text-transform: uppercase;
                    color: $glight2;
                }
            }

            hr {
                border: none;
                border-top: 1px solid $glighter3;
            }

            .form-input {
                display: block;
                width: 100%;
                height: 50px;
                padding-left: 15px;
                font-size: em(15);
                // line-height: 50px;
                border: 1px solid $glighter3;
                border-radius: 8px;
                @include appearance(none);

                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: $glighter;
                    font-weight: 300;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    color: $glighter;
                    font-weight: 300;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    color: $glighter;
                    font-weight: 300;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    color: $glighter;
                    font-weight: 300;
                }
            }

            .form-selection {
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                width: 100%;
                border: 1px solid $glighter3;
                border-radius: 8px;
                background-color: $bwhite;
                @include appearance(none);

                &:after {
                    position: absolute;
                    right: 10px;
                    top: 20px;
                    z-index: 0;
                    content: $font-Flaticon-down-arrow;
                    font-family: "Flaticon";
                    font-size: em(13);
                }

                select {
                    position: relative;
                    z-index: 1;
                    width: 100%;
                    flex: 1 0 0;
                    height: 50px;
                    padding-left: 15px;
                    font-size: em(15);
                    // line-height: 50px;
                    outline: 0;
                    border: 0;
                    background-color: transparent;
                    font-weight: 300;
                    @include appearance(none);
                }


                option.placeholder {
                    color: $glighter;
                    font-weight: 300;
                    @include appearance(none);
                }

                &.form-selection-year {
                    max-width: 7em;
                    margin-right: 1em;
                }

                &.form-selection-month {
                    max-width: 5em;
                    margin-right: 1em;
                }
            }

            .form-submit {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: row wrap;

                .legal {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 2em;

                    .legal-text {
                        margin-left: 1em;
                        font-size: em(14);

                        .legal-link {
                            color: $gdark2;
                            text-decoration: underline;

                            &:visited {
                                color: $gdark2;
                            }
                        }
                    }
                }

                .btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 2em;
                    margin-bottom: 2em;
                    font-size: em(18);
                    line-height: em(18);
                    font-weight: 300;
                    letter-spacing: 0.2em;

                    i {
                        margin-right: .4em;
                    }
                }
            }

            .user-list {
                padding-bottom: 2em;

                .user-details {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1em 1.5em;
                    margin-bottom: 1em;
                    border: 1px solid $glighter3;
                    background-color: $bwhite;
                    font-size: em(14);

                    .user-name {
                        padding-right: 1em;
                        font-weight: 600;
                    }

                    .user-email {
                        font-weight: 300;
                    }

                    .user-actions {

                        .form-selection {

                            &.user-action-selection {
                                background-color: $glightest2;

                                select {
                                    padding: 0 35px 0 25px;
                                    height: 40px;
                                    line-height: 40px;
                                    font-size: em(14);
                                }

                                &:after {
                                    top: 13px;
                                }

                            }
                        }
                    }
                }
            }

            .new-user {
                padding: 1.5em;
                margin-bottom: 2em;
                background-color: darken($glighter2, 5%);

                .new-user-title {
                    display: block;
                    padding: .5em 0 1.5em 0;
                    font-size: em(18);
                    font-weight: 400;
                }

                .label-onethird {
                    width: 30%;

                    .label-text {
                        font-size: em(16);
                    }

                }

                .btn {
                    margin-bottom: 2em;
                    font-weight: 300;
                    line-height: 2em;
                }
            }
        }
    }

    .back-to-profile {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1em 0;

        .home {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: $gray;
            text-transform: uppercase;
            font-weight: 100;

            &:visited {
                color: $gray;
            }
        }
    }

    .validation-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: .5em 0;
        flex: 1 0 0;
        font-family: $sfaf;
        font-size: em(30);
        font-weight: 300;
    }

    .appform-info {
        padding: 2em;
        margin-bottom: 2em;
        color: $gray;
        background: lighten($bsecondary, 40%);
        border-radius: 15px;
        border: 1px dashed $bsecondary;
        font-size: em(14);
        line-height: em(28);

        .appform-info-important {
            display: block;
            font-size: em(17);
            font-weight: 400;
        }

        .appform-info-text {
            display: block;
        }

        .appform-todo-text {
            margin-top: 1.5em;
            font-size: em(17);
        }

        .appform-addressee {
            display: inline-block;
            padding: 1.5em 4em 1.5em 1.5em;
            margin-top: 2em;
            background-color: $bwhite;
            border-radius: 15px;

            span {
                display: block;
            }
        }
    }

    .validation-form {
        margin-bottom: 2em;

        .form-header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: em(75);
            background-color: $glighter2;

            .form-step {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-size: em(30);
                padding: 0 1em;
                color: $glight2;
                background-color: $glighter3;
            }

            .step-title {
                padding-left: 1em;
                color: $bblack;
                font-size: em(22);
            }
        }

        .input-wrapper {
            margin: 0 4em 0 4em;

            .inline-inputs {
                display: flex;
                justify-content: space-around;
                align-items: center;

                .label-text-inline {
                    margin-right: 1em;
                    padding-bottom: 0;
                    line-height: em(22);
                    width: 69%;
                    text-align: right;

                    .note {
                        display: block;
                        padding-top: .5em;
                        font-size: em(12);
                        line-height: em(20);
                    }
                }

                .form-selection {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 30%;
                }
            }
        }

        .output-wrapper {
            margin: 0 2.5em;
            padding: 2em 0;

            .form-output {
                padding-top: 1em;

                .output-title {
                    font-size: em(18);
                    font-weight: 400;
                }
                .output-data {
                    font-size: em(14);
                    font-weight: 300;
                    padding: 2em 0 2em 1em;

                    &.second-line {
                        padding: 0 0 2em 1em;
                    }

                    &.data-inline {

                        span {
                            margin-right: 2em;
                        }
                    }
                }
            }

            .separator {
                margin: 0 -1em;

                hr {
                    margin: 0;
                }
            }
        }

        .validation-possible {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1em 0;
            background-color: $bgreen;
            color: #FFF;
        }

        &.legal-checkbox-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 2em 0 0 2.5em;

            .legal-checkbox {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex: 1 0 100%;
                margin-bottom: 2em;
                font-size: em(14);
                font-weight: 400;

                input {
                    margin-right: 1em;
                }

                a {
                    color: $bblack;
                    text-decoration: underline;

                    &:visited {
                        color: $bblack;
                    }
                }
            }
        }
    }

    .button-wrapper {
        padding-bottom: 2em;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
            padding: 1em 1.2em;
            font-size: em(18);
            font-weight: 300;
        }

        i {
            font-size: em(18);
            margin-right: 1em;
        }
    }

    .validation-form div.hide.validation-possible, .hide.show-only-on-step2, .validation-form.legal-checkbox-wrapper .hide.show-only-on-step2 {
        display: none;
    }

    .validation-possible .icon.icon-s.icon-color-dgreen {
        margin-left: 10px;
        float:left;
    }

    .validation-possible .approved-text {
        display: inline-block;
        padding-top: 10px;
        padding-bottom: 6px;
    }
}
