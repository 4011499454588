.login-popup-wrapper {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba($gdark, 0.7);
    text-align: left;

    &.active {
        display: block;
    }

    .login-flex {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login-popup {
        position: relative;
        width: 27em;
        z-index: 1000;
        background-color: $glightest2;
        border-radius: 15px;
        @include opacity(1);

        .login-title {
            display: block;
            padding: 1em .8em;
            font-family: $secondary-font-family;
            font-size: em(35);
            color: $bdanger;
        }

        .form-wrapper {
            margin: 0 4em;

            form {
                width: 100%;
            }

            .form-group {
            display: block;
            position: relative;
            margin-bottom: 2em;

                .error {
                    display: none;
                }

                &.has-error {

                    .form-input, .form-selection {
                        border: 1px solid $red;
                    }

                    .error {
                        display: flex;
                        flex: 1 0 0;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 1em;

                        .error-text {
                            font-size: em(14);
                            color: $red;
                        }
                    }
                }

                .label-text {
                    display: block;
                    padding-bottom: .5em;
                    font-size: em(18);
                    color: $gdark2;
                }

                .form-input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    padding-left: 15px;
                    font-size: em(15);
                    // line-height: 50px;
                    border: 1px solid $glighter3;
                    border-radius: 8px;

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: $glighter;
                        font-weight: 300;
                    }
                }

                &.form-submit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    flex-wrap: wrap;

                    .legal {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-width: 100%;

                        .legal-text {
                            margin-left: 1em;
                            font-size: em(14);

                        }

                    }

                    .btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 1em;
                        margin-bottom: 1.5em;
                        font-size: em(18);
                        line-height: em(18);
                        font-weight: 300;
                        letter-spacing: 0.2em;
                    }

                    .password-reminder {
                        font-size: em(14);
                        color: $gdark2;
                        text-decoration: underline;

                        &:visited {
                            color: $gdark2;
                        }
                    }
                }
            }
        }

        .fi-close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }
}

.mentor-modul-modal {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba($gdark, 0.7);
    text-align: left;

    &.active {
        display: block;
    }

    .modal-flex {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .modal {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 27em;
            z-index: 1000;
            padding: 2em;
            background-color: $glightest2;
            border-radius: 15px;
            @include opacity(1);

            .modal-text {
                display: block;
                width: 100%
            }

            .btn {
                margin-top: 2em;
            }
        }
    }
}

.modal-wrapper {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba($gdark, 0.7);
    text-align: left;

    &.active {
        display: block;
    }

    .modal-flex {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .modal {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;
            width: 27em;
            z-index: 1000;
            padding: 2em;
            background-color: $glightest2;
            border-radius: 15px;
            position: absolute;
            @include opacity(1);

            .modal-title {
                display: block;
                padding: 1em .8em;
                font-family: $secondary-font-family;
                font-size: em(35);
                color: $bdanger;
            }

            .modal-text {
                display: block;
                width: 100%
            }

            .btn {
                margin-top: 2em;
            }

            .fi-close {
                position: absolute;
                top: 20px;
                right: 20px;
                cursor: pointer;
            }
        }
    }
}

.modal-wrapper {

    &.modal-wrapper-infotar {

        .modal-flex {

            .modal {
                width: 600px;
                max-width: 100%;
            }
        }

        table {
            border-collapse: initial;
            border-spacing: 15px;

            th {
                width: 50%;
                text-align: right;
                font-weight: lighter;
                color: $glighter;
            }

            td {
                width: 50%;
                text-align: left;
                font-weight: lighter;
            }
        }

        .modal-button-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            width: 100%;

            .btn-validate {
                width: 100%;
            }

            .btn-download {
                width: 48%;
            }

            .btn-report {
                width: 48%;
            }
        }
    }
}
