.tag-masonry {
    width: 100%;
    display: block;
    position: relative;

    .grid-sizer { width: 48%; }
    .gutter-sizer { width: 30px; }

    .tag-masonry-item {
        width: 48%;
        margin: 2em 0;

        h2 {
            font-size: 2.5em;
            font-weight: normal;
            line-height: 1.3em;
            margin-bottom: .25em;

            a {
                color: $bblack;
                &:hover { text-decoration: underline; }
            }
        }

        p {
            font-size: 1.2em;
            line-height: 1.5em;
            color: $gray;
        }
    }
}