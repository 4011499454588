.self-eval-header {

    .back-to-profile {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1em 0;

        .home {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: $gray;
            text-transform: uppercase;
            font-weight: 100;

            &:visited {
                color: $gray;
            }
        }
    }

    .self-eval-title {
        font-family: $sfaf;
        font-size: em(55);
        text-align: center;
        margin-bottom: .8em;
    }

}

.self-eval-content-header {
    width: 100%;
    background-color: $glightest2;

    .self-eval-menu {
        display: flex;
        width: 100%;
        height: 60px;

        .menu-item {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: calc((100% - 280px) / 3);

            & a {
                color: $glighter;
            }

            &:nth-of-type(1) {
                min-width: 140px;
                text-transform: uppercase;
            }

            &:nth-of-type(2) {
                width: 140px;
                text-transform: uppercase;
            }

            .counter, .time {
                margin-left: .5em;
            }

            &.active {
                background-color: $bwhite;
            }

            &.ta-right {
                justify-content: flex-end;
            }
        }
    }
}

.self-eval-content-wrapper {
    width: 886px;
    margin: 0 auto;

    .welcome-content {
        padding: 3em;
        line-height: 1.8;
        background-color: $glightest2;

        div, p {
            color: $bblack;
            font-size: 15px;
            font-weight: normal;
            line-height: 1.5em;
            margin-bottom: 1em;
        }

        h2, h3, h4, h5, h6 {
            color: $bprimary;
            font-family: $sfaf;
            font-weight: normal;
            line-height: 1.5em;
            margin-top: .7em;
            margin-bottom: .5em;
            a {
                text-decoration: none;
                &:hover { text-decoration: underline; }
            }
        }

        h2 { font-size: 1.7em; }
        h3 { font-size: 1.5em; }
        h4 { font-size: 1.3em; }
        h5 { font-size: 1.1em; }
        h6 { font-size: 1em; }

        ul, ol {
            width: 96%;
            margin: .8em 0 1.2em 4%;
        }

        ul li, ol li {
            list-style-type: disc;
            font-weight: normal;
        }

        ol li {
            list-style-type: decimal;
            padding: .35em 0;
        }

        ul li {
            list-style: none;
            padding: .35em 0;
            &:before {
                margin-left: -1em;
                margin-right: .5em;
                content: '\f105';
                color: $bsecondary;
                font-family: $ifaf;
            }
        }

        a {
            color: $bprimary;
            text-decoration: underline;
        }

        .success-title {
            text-align: center;
            padding-bottom: 1em;
            font-size: em(40);
            color: $bgreen;
        }

        .btn {
            margin-top: 2em;
            color: #FFF;
            text-decoration: none;
        }

    }

    .self-eval-form {
        margin-top: 1em;

        .toc {
            position: relative;

            .toggle-input {
                display: none;

                &:checked ~ .toggle-label::after {
                    content: $fa-var-angle-up;
                }

                &:checked ~ .toc-menu .toc-menu-item {
                    display: flex;
                }
            }


            .toggle-label {
                position: absolute;
                top: 0;
                right: 0;
                width: 80px;
                height: 80px;

                &::after {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    color: $bwhite;
                    background-color: $glight2;
                    font-family: $ifaf;
                    font-size: em(50);
                    content: $fa-var-angle-down;
                }
            }

            .toc-menu {
                font-size: em(18);
                font-weight: bold;
                color: #525252;

                .toc-menu-item {
                    display: none;
                    align-items: center;
                    width: 100%;
                    background-color: $reg-choice-tile-top-right;

                    &.active {
                        display: flex;
                    }

                    &:nth-of-type(2) {
                        border-top: 1px solid $reg-choice-tile-top-right;
                        border-bottom: 1px solid $reg-choice-tile-top-right;
                    }

                    .item-nr {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 80px;
                        height: 80px;
                        background-color: $glight2;
                    }

                    .toc-link {
                        display: flex;
                        align-items: center;
                        padding: 0 2em;
                        color: #525252;

                        &:visited {
                            color: #525252;
                        }
                    }
                }
            }
        }

        .alert-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 2em 0;
            margin-bottom: 2em;
            color: $alert-box-border;
            background-color: $alert-box-bg;
            border: 1px dashed $alert-box-border;
            border-radius: 18px;

            .alert-title {
                font-size: em(22);
                font-weight: bold;
                padding-bottom: .7em;
            }

            .alert-text {
                color: $gdark;
            }

        }

        .form-content {
            width: 100%;
            font-size: em(15);
            line-height: 2;
            background-color: $glightest2;

            .status-msg {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 2em 0;
                padding: 0 2em;

                .alert-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 2em 0;
                    margin-bottom: 2em;
                    color: $alert-box-border;
                    background-color: $alert-box-bg;
                    border: 1px dashed $alert-box-border;
                    border-radius: 18px;

                    &.success {
                        color: $bgreen;
                        background-color: $bgreen-extralight;
                        border: 1px dashed $bgreen;
                    }

                    .alert-title {
                        font-size: em(22);
                        font-weight: bold;
                        padding-bottom: .7em;
                    }

                    .alert-text {
                        color: $gdark;
                    }

                }

                .button-wrapper {
                    text-align: center;
                }

            }

            .form-section-title {
                display: flex;
                align-items: center;
                padding: 0 2em;
                width: 100%;
                height: 80px;
                font-size: em(18);
                font-weight: bold;
                color: #525252;
                background-color: $reg-choice-tile-top-right;
            }

            .form-section {
                width: 100%;
                position: relative;
                padding: 1em 60px 2em 60px;

                .messages {
                    padding: 2em 0;

                    &:after {
                        content: '';
                        display:block;
                        clear:both;
                    }

                    .message-bubble {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        float: left;
                        width: 80%;
                        margin-bottom: 2em;
                        padding: 2em;
                        clear: both;
                        color: $bblack;
                        background: $bwhite;
                        border: 1px solid $bwhite;
                        @include border-radius(25px);
                        font-weight: 300;
                        line-height: em(20);
                        word-wrap: break-word;

                        .timestamp {
                            display: block;
                            padding-bottom: .5em;
                            font-size: em(12);
                        }

                        .message-text {
                            display: block;
                            font-size: em(15);
                        }

                        &.blue {
                            float: right;
                            background: $bblue-message-bubble;
                            border: 1px solid $bblue-message-bubble;
                            color: $bwhite;

                            &:before {
                                left: auto;
                                right: -5px;
                            }

                            &:after {
                                left: auto;
                                right: -5px;
                                border-bottom-color: $bblue-message-bubble;
                            }
                        }

                        &:before, &:after {
                            border-radius: 20px / 5px;
                            content: '';
                            display: block;
                            position: absolute;
                        }

                        &:before {
                            border: 10px solid transparent;
                            border-bottom-color: rgba(0,0,0,0.5);
                            bottom: 0px;
                            left: -7px;
                            z-index: -2;
                        }

                        &:after {
                            border: 8px solid transparent;
                            border-bottom-color: $bwhite;
                            bottom: 3px;
                            left: -5px;
                        }
                    }
                }

                .section-nr {
                    position: absolute;
                    top: 1em;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 46px;
                    height: 40px;
                    font-size: em(18);
                    font-weight: bold;
                    color: $bwhite;
                    background-color: $bgreen;
                }

                .section-lead {
                    width: 100%;
                    padding-bottom: 1em;
                    font-weight: bold;
                }

                .attachments {
                    width: 100%;
                    margin-bottom: 2em;

                    .attachment-item {
                        display: flex;
                        align-items: center;
                        padding: 1em 0;
                        border-bottom: 1px solid $glighter3;

                        .detail {
                            display: flex;
                            align-items: center;
                            padding-right: 10px;

                            &--big {
                                width: 30%;
                            }

                            &--sm {
                                width: 25%;
                            }

                            &--xs {
                                width: 15%;
                            }
                        }

                        .file-name {
                            font-weight: bold;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: inline;
                        }

                        .actions {
                            justify-content: flex-end;
                            padding-right: 0;

                            .icon {
                                margin: 0 0 0 1em;

                                i {
                                    color: $gdark;
                                }

                            }
                        }
                    }
                }
            }

            .q-section {
                width: 100%;
                padding-bottom: 1.5em;

                .q-text {
                    width: 100%;
                    padding-bottom: 1em;

                    &-no-padding {
                        padding-bottom: 0;
                    }

                    small {
                        color: #999999;
                        font-size: 12px;
                    }
                }

                .q-input-group {
                    width: 100%;
                }
            }

            .q-section-columns {
                display: flex;

                .q-text {
                    flex: 1;
                    margin-left: 10px;
                }

                .q-input-group {
                    flex: 2;
                }
            }

            .btn-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 3em;

                &.fw-left {
                    justify-content: flex-start;
                    width: 100%;
                    padding: 0;
                }
            }

        }

        // CUSTOM INPUTS

        .q-input-group {

            // RADIO
            .custom-radio-input {
                display: none;

                &:checked + .custom-radio-label {
                    color: $bwhite;
                    background-color: $bgreen;
                    @include transition(all 250ms ease-in-out);

                    .radio-circle {
                        background-color: $bgreen-dark;
                        @include nicerborder(1px, $bwhite, 50%);
                        @include transition(all 250ms ease-in-out);
                    }
                }

                &.yes {

                    &:checked ~ .goals-label {
                        display: block;
                    }
                }
            }

            .custom-radio-label {
                display: inline-flex;
                align-items: center;
                justify-content: space-around;
                width: 120px;
                height: 50px;
                padding: 0 20px;
                background-color: $bwhite;
                @include nicerborder(1px, $glightest, 8px);
                @include transition(all 250ms ease-in-out);

                &:first-of-type {
                    margin-right: 1em;
                }

                .radio-circle {
                    width: 13px;
                    height: 13px;
                    padding: 1px;
                    background-color: $reg-choice-tile-top-left;
                    @include nicerborder(1px, $glighter, 50%);
                    @include transition(all 250ms ease-in-out);
                }
            }

            .form-selection {
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                width: 100%;
                border: 1px solid $glighter3;
                border-radius: 8px;
                background-color: $bwhite;
                @include appearance(none);

                &:after {
                    position: absolute;
                    right: 10px;
                    top: 15px;
                    z-index: 0;
                    content: $font-Flaticon-down-arrow;
                    font-family: "Flaticon";
                    font-size: em(13);
                }

                select {
                    position: relative;
                    z-index: 1;
                    width: 100%;
                    flex: 1 0 0;
                    height: 48px;
                    padding-left: 15px;
                    font-size: em(15);
                    // line-height: 50px;
                    outline: 0;
                    border: 0;
                    background-color: transparent;
                    font-weight: 300;
                    @include appearance(none);
                }


                option.placeholder {
                    color: $glighter;
                    font-weight: 300;
                    @include appearance(none);
                }
            }

            .goals-label {
                display: none;
                margin-top: 1em;

                .dropdown-target-input {
                    width: 100%;
                    margin-top: .5em;
                    display: none;

                    .btn {
                        display: inline-flex;
                    }
                }
            }

            // TEXTAREA

            .custom-textarea {
                width: 100%;
                height: 205px;
                padding: 20px;
                font-family: $bfaf;
                font-size: em(15);
                line-height: 1.5;
                background-color: $bwhite;
                @include nicerborder(1px, $glightest, 8px);
                resize: none;

                &:focus {
                    outline: 0;
                }
            }

            .textarea-char-counter {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;

                .current-count {
                    margin-right: .4em;
                }

                .max-count {
                    margin-left: .4em;
                }
            }

            .custom-text-input {
                width: 200px;
                height: 50px;
                padding: 0 20px;
                font-family: $bfaf;
                font-size: em(15);
                line-height: 1.5;
                background-color: $bwhite;
                @include nicerborder(1px, $glightest, 8px);

                &-full-width {
                    width: 100%;
                }

                &:focus {
                    outline: 0;
                }
            }

            .q-helper {
                color: #999;
                font-size: 12px;

                a {
                    text-decoration: underline;
                    color: #999;
                }
            }

            .multiline-input-group {

                &.small {
                    width: 500px;
                    margin: 0 auto;

                    .cell {
                        width: 50%;
                    }
                }

                &.full-width {
                    width: 100%;

                    .cell {
                        width: calc((100% - 270px) / 2 + 30px);

                        &:first-of-type {
                            width: calc((100% - 270px) / 2 - 30px);
                        }

                        &:last-of-type {
                            width: 270px;

                            .custom-text-input {
                                width: 270px;
                            }
                        }
                    }
                }

                .line {
                    display: flex;
                    padding: 5px 10px 0 10px;

                    &.header {
                        padding-bottom: 5px;
                        color: $gray;
                        border-bottom: 1px solid $gray;
                        line-height: 1.5;
                    }
                }

                .cell {
                    display: inline-flex;
                    align-items: center;


                    .input-text {
                        margin-left: 5px;
                        font-size: em(14);
                    }
                }
            }

            .reference-input {
                padding: 1em 0;
                border-bottom: 1px solid $glight;

                &:first-of-type {
                    border-top: 1px solid $glight;
                }

                .line {
                    display: flex;
                    width: 100%;

                    &:first-of-type {
                        padding-bottom: .5em;
                    }

                    .input-cell {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 32%;
                        margin-right: 2%;

                        &:last-of-type {
                            margin-right: 0;
                        }

                        .label-text {
                            display: flex;
                            align-items: flex-end;
                            flex: 1 0 auto;
                            width: 100%;
                            padding-bottom: .7em;
                            color: $gray;
                            font-size: em(14);
                            line-height: 1.5;
                        }

                        .custom-text-input {
                            width: 100%;
                        }
                    }
                }

                &.small {
                    width: 520px;
                    margin: 0 auto;

                    .line {

                        .input-cell {
                            width: 48%;
                            margin-right: 2%;

                            &:last-of-type {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .add-new-line {
                display: inline-block;
                margin: 1.5em;
                font-size: em(13);
                text-transform: uppercase;
                cursor: pointer;
            }

            .attachment-list {
                list-style: none;

                .list-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1em;

                    .file-link {
                        margin-right: 1em;
                        color: $gdark;

                        &:visited {
                            color: $gdark;
                        }
                    }
                }
            }

        }
    }
}

.file-upload-popup-wrapper,
.file-edit-popup-wrapper {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba($gdark, 0.7);
    text-align: left;

    &.active {
        display: block;
    }

    .file-flex {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .file-popup {
        position: relative;
        width: 580px;
        z-index: 1000;
        background-color: $glightest;
        border-radius: 15px;
        @include opacity(1);

        .form-wrapper {
            margin: 3em 4em 2em 4em;

            form {
                width: 100%;
            }

            .custom-text-input {
                width: 100%;
                height: 50px;
                padding: 0 20px;
                font-family: $bfaf;
                font-size: em(15);
                line-height: 1.5;
                background-color: $bwhite;
                @include nicerborder(1px, $glightest, 8px);

                &:focus {
                    outline: 0;
                }
            }

            .form-selection {
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                width: 100%;
                border: 1px solid $glighter3;
                border-radius: 8px;
                background-color: $bwhite;
                @include appearance(none);

                &:after {
                    position: absolute;
                    right: 10px;
                    top: 20px;
                    z-index: 0;
                    content: $font-Flaticon-down-arrow;
                    font-family: "Flaticon";
                    font-size: em(13);
                }

                select {
                    position: relative;
                    z-index: 1;
                    width: 100%;
                    flex: 1 0 0;
                    height: 48px;
                    padding-left: 15px;
                    font-size: em(15);
                    // line-height: 50px;
                    outline: 0;
                    border: 0;
                    background-color: transparent;
                    font-weight: 300;
                    @include appearance(none);
                }


                option.placeholder {
                    color: $glighter;
                    font-weight: 300;
                    @include appearance(none);
                }
            }

            .custom-textarea {
                width: 100%;
                height: 130px;
                padding: 20px;
                font-family: $bfaf;
                font-size: em(15);
                line-height: 1.5;
                background-color: $bwhite;
                @include nicerborder(1px, $glightest, 8px);
                resize: none;

                &:focus {
                    outline: 0;
                }
            }

            .textarea-char-counter {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;

                .current-count {
                    margin-right: .4em;
                }

                .max-count {
                    margin-left: .4em;
                }
            }

            .attachments {
                padding-bottom: 2em;

                .title {
                    font-size: em(18);
                }

                .list {
                    padding-left: 1.5em;

                    .list-item {
                        position: relative;
                        display: flex;
                        align-items: center;
                        min-height: 50px;
                        font-size: em(15);
                        font-weight: 300;

                        .icon {
                            margin: 0;
                            margin-left: 1em;

                            i {
                                color: $gdark;
                            }
                        }

                        a {
                            color: $bblack;
                            text-decoration: underline;

                            &:hover {
                                color: $bblack;
                            }

                            &:visited {
                                color: $bblack;
                            }
                        }
                    }
                }
            }

            .file-upload {
                height: 130px;
            }

            .form-group {
                display: block;
                position: relative;
                margin-bottom: 1.5em;

                .error {
                    display: none;
                }

                &.has-error {

                    .form-input, .form-selection {
                        border: 1px solid $red;
                    }

                    .error {
                        display: flex;
                        flex: 1 0 0;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 1em;

                        .error-text {
                            font-size: em(14);
                            color: $red;
                        }
                    }
                }

                .label-text {
                    display: block;
                    padding-bottom: .5em;
                    font-size: em(18);
                    color: $gdark2;
                }

                .hint {
                    font-size: em(13);
                    font-weight: 300;
                    line-height: em(20);
                    color: $gray;
                }

                .form-input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    padding-left: 15px;
                    font-size: em(15);
                    // line-height: 50px;
                    border: 1px solid $glighter3;
                    border-radius: 8px;

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: $glighter;
                        font-weight: 300;
                    }

                    &.text-area {
                        height: 220px;
                        margin: 1em 0;
                        padding: .5em;
                        line-height: normal;
                        resize: none;
                    }
                }

                &.form-submit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    flex-wrap: wrap;
                    margin-bottom: 0;

                    .btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: em(18);
                        line-height: em(18);
                        font-weight: 300;
                        letter-spacing: 0.2em;
                    }
                }

            }

        }

        .fi-close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }
}
