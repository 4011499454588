.adverts-wrapper {
    width: 100%;

    .form-wrapper {
        width: 100%;

        .form-tabs {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: em(18);
            background-color: $bwhite;

            .form-tab {
                padding: 2em;
                text-align: center;
                color: $bblack;
                background-color: $bwhite;
                cursor: pointer;

                &.active {
                    color: $bblack;
                    background-color: $glightest2;
                    cursor: default;
                }

                &:hover {
                    background-color: $glightest2;
                }
            }
        }

        .form-content {
            width: 100%;
            padding: 2em;
            background-color: $glightest2;


            .input-group {
                display: none;

                &.active {
                    display: block;
                }
            }

            .form-header {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;

                .search-wrapper {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 48px;
                    margin-right: 1em;
                    transition: all 500ms ease-in-out;

                    &.open {
                        width: 200px;
                        transition: all 500ms ease-in-out;

                        .search-input {
                            padding: 0 2em;
                        }
                    }

                    .search-input {
                        width: 100%;
                        height: 48px;
                        font: normal 1em $bfaf;
                        color: $gray;
                        border: 2px solid $glightest;
                        border-radius: $rad;
                        outline: none;
                        &:focus { border-color: $glighter; }
                        &:hover { border-color: lighten($glighter, 12%); }
                        @include appearance(none);
                    }

                    .search-submit {
                        position: absolute;
                        top: 50%;
                        right: 4px;
                        color: $bwhite;
                        width: 40px;
                        height: 40px;
                        margin-top: -20px;
                        font-size: 16px;
                        background: $glight;
                        border: none;
                        border-radius: 50%;
                        outline: none;
                        cursor: pointer;
                        &:hover { background: $gray; }
                    }
                }

                .filter-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .btn {
                        padding: 1em;
                        margin-right: .3em;
                        font-size: em(11);
                        font-weight: 100;
                        line-height: em(34);
                        white-space: nowrap;
                        width: 140px;
                    }

                    .form-selection {
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;
                        position: relative;
                        width: 135px;
                        border: 1px solid $glighter3;
                        border-radius: 8px;
                        background-color: $bwhite;
                        margin-left: 1em;
                        @include appearance(none);

                        &:after {
                            position: absolute;
                            right: 10px;
                            top: 18px;
                            z-index: 0;
                            content: $font-Flaticon-down-arrow;
                            font-family: "Flaticon";
                            font-size: em(13);
                        }

                        select {
                            position: relative;
                            z-index: 1;
                            width: 100%;
                            flex: 1 0 0;
                            height: 46px;
                            padding-left: 15px;
                            font-size: em(15);
                            line-height: 46px;
                            outline: 0;
                            border: 0;
                            background-color: transparent;
                            font-weight: 300;
                            @include appearance(none);
                        }
                    }
                }

                .new-button-wrapper {
                    margin-top: 1em;
                    width: 100%;
                }

                .dropdown {
                    display: inline-block;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    margin-right: 1em;
                    cursor: pointer;

                    .icon {
                        margin-right: .5em;
                    }

                    .user-login-text {
                        position: relative;
                        margin-right: 1em;
                        color: $bdanger;
                        font-size: em(12);
                        text-transform: uppercase;
                    }

                    .btn-trigger {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        z-index: 2;
                        padding: 0 1em;
                        margin: 0;
                        border: none;

                        .menu-text {
                            align-self: center;
                            font-size: em(15);
                            font-weight: 300;
                            margin-right: 1.5em;
                            margin-left: .5em;
                        }

                        .icon {
                            left: -1em;
                        }
                    }

                    .menu-wrapper {
                        position: absolute;
                        display: none;
                        justify-content: center;
                        align-items: baseline;
                        flex-wrap: wrap;
                        padding-top: 4em;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        background-color: $dorange;
                        border-radius: 25px;
                        cursor: default;

                        &.menu-wrapper-black {
                            background-color: #000000;

                            .menu-list {

                                li {
                                    border-bottom: 1px solid $bblack;
                                }
                            }
                        }

                        &.active {
                            display: flex;
                            @include animation-duration(150ms);
                        }

                        .menu-content {
                            position: relative;
                            z-index: 0;
                            width: 100%;
                        }

                        .menu-list {
                            padding: 0 2em;
                            color: $bwhite;
                            list-style-type: none;
                            font-weight: 300;
                            text-transform: uppercase;
                            text-align: center;

                            li {
                                padding: 1em 0;
                                font-size: em(15);
                                border-bottom: 1px solid $bdanger;

                                a {
                                    color: $bwhite;

                                    &:visited {
                                        color: $bwhite;
                                    }
                                }
                            }
                        }

                        .btn-logout {
                            padding: 1em 1.2em;
                            font-size: em(12);
                            font-weight: 300;
                            margin: 4em 0 2em 0;
                            border: 1px solid $glighter;

                            .fi {
                                margin-right: -.5em;
                            }
                        }
                    }
                }
            }

            .form-list {
                width: 100%;
                padding-top: 2em;

                .list-header {
                    display: inline-flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    padding-left: 20px;
                    font-size: 0;
                    line-height: 60px;
                    color: $bwhite;
                    background-color: $bsuccess;

                    .list-header-title {
                        display: inline-block;
                        font-size: 18px;
                        font-weight: 100;

                        &.name {
                            width: 43%;
                        }

                        &.category {
                            width: 13%;
                        }

                        &.price {
                            width: 12%;
                        }

                        &.status {
                            width: 11%;
                        }

                        &.statistics {
                            width: 7%;
                        }

                        &.actions {
                            width: 14%;
                        }

                        &.text {
                            width: 46%;
                        }

                        &.date {
                            width: 11%;
                        }

                        &.rating-name {
                            width: 12%;
                        }

                        &.product-name {
                            width: 14%;
                        }

                        &.rating-status {
                            width: 17%;
                            text-align: center;
                        }
                    }
                }

                .list-item {
                    padding-left: 20px;
                    background-color: $bwhite;
                    border-left: 1px solid $glighter3;
                    border-right: 1px solid $glighter3;
                    border-bottom: 1px solid $glighter3;
                    font-size: 0;

                    &:nth-child(odd) {
                        background-color: $glightest2;
                    }

                    &.hidden-by-toggler {
                        display: none;
                    }

                    &.hidden-by-search-for {
                        display: none;
                    }

                    .list-item-section {
                        display: inline-flex;
                        align-items: center;
                        justify-content: flex-start;
                        vertical-align: middle;
                        font-size: 14px;
                        font-weight: 100;
                        height: 50px;

                        .custom-dropdown {
                            display: inline-flex;
                            justify-content: flex-start;
                            align-items: center;
                            position: relative;
                            width: 120px;
                            height: 35px;
                            padding: 1em;
                            border: 1px solid $glighter3;
                            border-radius: 8px;
                            background-color: $bwhite;
                            cursor: pointer;

                            .placeholder {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                .icon {
                                    margin-right: 1em;
                                }
                            }

                            &:after {
                                position: absolute;
                                right: 10px;
                                top: 11px;
                                z-index: 0;
                                content: $font-Flaticon-down-arrow;
                                font-family: "Flaticon";
                                font-size: em(13);
                            }

                            .dropdown {
                                position: absolute;
                                z-index: 2;
                                top: 100%;
                                left: -1px;
                                right: -1px;
                                border: 1px solid $glighter3;
                                border-top: none;
                                border-radius: 8px;
                                background-color: $bwhite;
                                opacity: 0;
                                pointer-events: none;

                                .dropdown-item {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    width: 100%;
                                    padding: 1em;
                                    cursor: pointer;
                                    color: $bblack;

                                    .icon {
                                        margin-right: 1em;
                                    }
                                }
                            }

                            &.active {
                                border-bottom-right-radius: 0;
                                border-bottom-left-radius: 0;

                                .dropdown {
                                    opacity: 1;
                                    pointer-events: auto;
                                    border-top-right-radius: 0;
                                    border-top-left-radius: 0;
                                }
                            }
                        }

                        &.name {
                            width: 29%;
                        }

                        &.category-icon {
                            width: 14%;

                            .icon {
                                margin-right: .5em;
                            }

                            .text {
                                font-size: em(13);
                            }
                        }

                        &.category {
                            width: 13%;
                            color: $gray;
                        }

                        &.price {
                            width: 12%;
                        }

                        &.status {
                            width: 11%;
                        }

                        &.statistics {
                            width: 7%;
                            i {
                                margin: 0 .5em;
                            }
                        }

                        &.actions {
                            width: 14%;
                            justify-content: flex-end;
                            float: right;

                            .icon {
                                margin-right: .5em;
                                cursor: pointer;
                            }
                        }

                        &.text {
                            width: 41%;

                            .truncate {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 90%;
                            }

                            .rating-wrapper {
                                position: relative;

                                .rating-message-trigger {
                                    padding-left: .5em;
                                    cursor: pointer;
                                }

                                .rating-bubble {
                                    display: none;
                                    position: absolute;
                                    top: -69px;
                                    left: 3em;
                                    z-index: 2;
                                    width: 300px;
                                    height: 300px;
                                    padding: 2em 1.5em;
                                    background: $gbg;
                                    border-radius: 15px;
                                    border: 1px solid $glighter;
                                    font-style: normal;

                                    &.active {
                                        display: block;
                                    }

                                    &:after, &:before {
                                        right: 100%;
                                        top: 25%;
                                        border: solid transparent;
                                        content: " ";
                                        height: 0;
                                        width: 0;
                                        position: absolute;
                                        pointer-events: none;
                                    }

                                    &:after {
                                        border-color: transparent;
                                        border-right-color: $gbg;
                                        border-width: 15px;
                                        margin-top: -15px;
                                    }

                                    &:before {
                                        border-color: transparent;
                                        border-right-color: $glighter;
                                        border-width: 16px;
                                        margin-top: -16px;
                                    }

                                    .rating-header {
                                        display: block;
                                        position: relative;
                                        width: 100%;
                                        background-color: $gbg;
                                        border-top-left-radius: 15px;
                                        border-top-right-radius: 15px;
                                        font-size: em(15);
                                        font-weight: 400;

                                        .star-container {
                                            display: inline-block;
                                            padding: .5em;
                                            border-radius: 8px;
                                            border: 1px solid $gray;
                                            font-weight: 300;

                                            &.star5 {
                                                color: $bgreen-dark;
                                            }

                                            &.star4 {
                                                color: $bgreen;
                                            }

                                            &.star3 {
                                                color: $bbrown;
                                            }

                                            &.star2 {
                                                color: $bdanger;
                                            }

                                            &.star1 {
                                                color: $red;
                                            }
                                        }

                                        .name {
                                            padding-left: 1em;
                                        }

                                        .close-icon {
                                            position: absolute;
                                            right: 0;
                                            padding-top: 0.5em;
                                        }
                                    }

                                    .rating-text {
                                        display: block;
                                        font-weight: 300;
                                    }

                                    .rating-footer {
                                        position: absolute;
                                        left: 0;
                                        bottom: 0;
                                        width: 100%;
                                        padding: 1em 1.5em;

                                        .rating-footer-buttons {
                                            display: inline-flex;
                                            justify-content: space-between;
                                            width: 100%;

                                            .btn {
                                                padding: 1em 1em;
                                            }
                                        }
                                    }

                                }
                            }
                        }

                        &.stars {
                            width: 5%;

                            .star-container {
                                padding: .8em;
                                border-radius: 8px;
                                background-color: $reg-tile-br;

                                &.star5 {
                                    color: $bgreen-dark;
                                }

                                &.star4 {
                                    color: $bgreen;
                                }

                                &.star3 {
                                    color: $bbrown;
                                }

                                &.star2 {
                                    color: $bdanger;
                                }

                                &.star1 {
                                    color: $red;
                                }
                            }
                        }

                        &.date {
                            width: 11%;
                        }

                        &.rating-name {
                            width: 12%;
                        }

                        &.product-name {
                            width: 14%;
                        }

                        &.rating-status {
                            width: 6%;

                            .status-container {
                                display: none;
                                padding: .6em 1.2em;
                                border-radius: 8px;
                                color: $bwhite;
                                background-color: $bdanger;
                            }
                        }

                        &.rating-status-action {
                            width: 11%;
                        }
                    }

                    &.new-item {

                        .list-item-section {

                            &.text {
                                font-weight: 400;
                            }

                            &.date {
                                font-weight: 400;
                            }

                            &.rating-status {

                                .status-container {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            .por-wrapper {

                .por-list {

                    .por-item {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        height: em(65);
                        padding: 0 2em;
                        margin-bottom: 1em;
                        background-color: $bwhite;
                        border: 1px solid $reg-tile-tr;

                        .por-address {
                            width: 50%;
                        }

                        .por-opening-hours {
                            width: 35%;
                            font-weight: 300;

                            .por-opening-hours-item {
                                padding-right: 1em;
                            }
                        }

                        .por-actions {
                            display: flex;
                            justify-content: flex-end;
                            width: 15%;

                            .icon {
                                margin-right: 0;
                                margin-left: 1em;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .por-new {
                    padding: 2em;
                    background-color: $glighter2;

                    .por-new-title {
                        font-size: em(20);
                    }

                    .inline-inputs {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 2em 0;

                        .zip {
                            width: 125px;
                            padding-right: 1em;
                        }

                        .city {
                            width: 250px;
                            padding-right: 1em;
                        }

                        .address {
                            width: 300px;
                        }
                    }

                    .label-text {
                        display: block;
                        padding-bottom: .5em;
                        font-size: em(18);
                        font-weight: 300;
                    }

                    .form-input {
                        display: block;
                        width: 100%;
                        height: 50px;
                        padding-left: 15px;
                        font-size: em(15);
                        // line-height: 50px;
                        border: 1px solid $glighter3;
                        border-radius: 8px;
                        @include appearance(none);

                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            color: $glighter;
                            font-weight: 300;
                        }
                        &::-moz-placeholder { /* Firefox 19+ */
                            color: $glighter;
                            font-weight: 300;
                        }
                        &:-ms-input-placeholder { /* IE 10+ */
                            color: $glighter;
                            font-weight: 300;
                        }
                        &:-moz-placeholder { /* Firefox 18- */
                            color: $glighter;
                            font-weight: 300;
                        }

                    }

                    .form-textarea {
                        display: block;
                        width: 100%;
                        max-width: 675px;
                        height: 200px;
                        padding: 15px;
                        font-size: em(15);
                        border: 1px solid $glighter3;
                        border-radius: 8px;
                        @include appearance(none);

                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            color: $glighter;
                            font-weight: 300;
                        }
                        &::-moz-placeholder { /* Firefox 19+ */
                            color: $glighter;
                            font-weight: 300;
                        }
                        &:-ms-input-placeholder { /* IE 10+ */
                            color: $glighter;
                            font-weight: 300;
                        }
                        &:-moz-placeholder { /* Firefox 18- */
                            color: $glighter;
                            font-weight: 300;
                        }

                    }

                    .por-time-list {
                        padding-bottom: 2em;
                        font-weight: 300;

                        .fi {
                            padding-left: 1em;
                        }
                    }

                    .btn-new-por-time {
                        color: $bblack;
                        border: 1px solid $bblack;
                        border-radius: 8px;
                        font-size: em(15);
                        text-transform: none;

                        .fi {
                            margin-left: -1em;
                            padding-right: .5em;
                        }
                    }

                    .new-por-time {
                        display: none;

                        &.active {
                            display: block;
                        }

                        .form-group {

                            .time {

                                .form-input {
                                    width: 80px;
                                }
                            }

                            .separator {
                                width: 20px;
                                height: 1px;
                                margin: 0 .5em;
                                border-bottom: 1px solid $gray;
                            }
                        }

                        .days-selection {
                            display: flex;
                            align-items: center;

                            .day {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 40px;
                                height: 35px;
                                margin-right: 5px;
                                margin-bottom: 1em;
                                font-weight: 300;
                                border: 1px solid $gray;
                                cursor: pointer;

                                &.active {
                                    background-color: $bgreen-active;
                                    color: $bwhite;
                                }
                            }
                        }

                        .btn-new-por-time-save-cancel {
                            border: none;
                            font-size: em(12);
                            font-weight: 300;
                        }
                    }

                    .btn-new-por {
                        display: block;
                        width: 280px;
                        margin-top: 4em;
                        padding: 1.5em;
                        font-weight: 300;
                    }
                }
            }

            .contacts-wrapper {

                .contacts-list {

                    .contact-item {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        height: em(65);
                        padding: 0 2em;
                        margin-bottom: 1em;
                        background-color: $bwhite;
                        border: 1px solid $reg-tile-tr;

                        .name {
                            width: 30%;
                        }

                        .email {
                            width: 30%;
                        }

                        .phone {
                            width: 25%;
                        }

                        .contact-actions {
                            display: flex;
                            justify-content: flex-end;
                            width: 15%;

                            .icon {
                                margin-right: 0;
                                margin-left: 1em;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .contact-new {
                    padding: 2em;
                    background-color: $glighter2;

                    .contact-new-title {
                        font-size: em(20);
                    }

                    .inline-inputs {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 2em 0;

                        .name {
                            width: 250px;
                            padding-right: 1em;
                        }

                        .email {
                            width: 250px;
                            padding-right: 1em;
                        }

                        .phone {
                            width: 200px;
                        }
                    }

                    .label-text {
                        display: block;
                        padding-bottom: .5em;
                        font-size: em(18);
                        font-weight: 300;
                    }

                    .form-input {
                        display: block;
                        width: 100%;
                        height: 50px;
                        padding-left: 15px;
                        font-size: em(15);
                        // line-height: 50px;
                        border: 1px solid $glighter3;
                        border-radius: 8px;
                        @include appearance(none);

                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            color: $glighter;
                            font-weight: 300;
                        }
                        &::-moz-placeholder { /* Firefox 19+ */
                            color: $glighter;
                            font-weight: 300;
                        }
                        &:-ms-input-placeholder { /* IE 10+ */
                            color: $glighter;
                            font-weight: 300;
                        }
                        &:-moz-placeholder { /* Firefox 18- */
                            color: $glighter;
                            font-weight: 300;
                        }

                    }

                    .btn-new-contact {
                        display: block;
                        width: 280px;
                        padding: 1.5em;
                        font-weight: 300;
                    }
                }
            }

            .intro-wrapper {
                padding: 2em 2em;

                .btn {
                    margin-top: 2em;
                }

                .intro-editor {
                    display: flex; /* or inline-flex */

                    .intro-editor-wysiwyg {
                        flex: 2;
                    }

                    .intro-editor-image {
                        flex: 1;
                        margin-left: 40px;
                    }
                }
            }

        }
    }

}

.new-por-popup-wrapper {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba($gdark, 0.7);
    text-align: left;

    &.active {
        display: block;
    }

    .new-por-flex {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .new-por-popup {
        position: relative;
        width: 30em;
        z-index: 1000;
        background-color: $glightest2;
        border-radius: 15px;
        @include opacity(1);

        .title {
            display: block;
            padding: 1em .8em;
            font-family: $secondary-font-family;
            font-size: em(35);
            color: $bdanger;
        }

        .form-wrapper {
            margin: 0 4em;

            form {
                width: 100%;
            }

            .form-group {
                display: block;
                position: relative;
                margin-bottom: 2em;

                .error {
                    display: none;
                }

                &.has-error {

                    .form-input, .form-selection {
                        border: 1px solid $red;
                    }

                    .error {
                        display: flex;
                        flex: 1 0 0;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 1em;

                        .error-text {
                            font-size: em(14);
                            color: $red;
                        }
                    }
                }

                .label-text {
                    display: block;
                    padding-bottom: .5em;
                    font-size: em(18);
                    color: $gdark2;
                }

                .form-input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    padding-left: 15px;
                    font-size: em(15);
                    // line-height: 50px;
                    border: 1px solid $glighter3;
                    border-radius: 8px;

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: $glighter;
                        font-weight: 300;
                    }
                }


                &.form-submit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    flex-wrap: wrap;

                    .legal {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-width: 100%;

                        .legal-text {
                            margin-left: 1em;
                            font-size: em(14);

                        }

                    }

                    .btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 3em;
                        margin-bottom: 1.5em;
                        font-size: em(18);
                        line-height: em(18);
                        font-weight: 300;
                        letter-spacing: 0.2em;
                    }
                }
            }

            .por-time-list {
                padding: 2em 0 1em 0;
                font-size: em(14);
                font-weight: 300;

                .fi {
                    padding-left: 1em;
                }
            }

            .btn-new-por-time {
                padding: 1em 1.5em;
                color: $bblack;
                border: 1px solid $bblack;
                border-radius: 8px;
                font-size: em(13);
                text-transform: none;

                .fi {
                    margin-left: -.5em;
                    padding-right: .5em;
                }
            }

            .new-por-time {
                display: none;

                &.active {
                    display: block;
                }

                .form-group {

                    .time {

                        .form-input {
                            width: 80px;
                        }
                    }

                    .separator {
                        width: 20px;
                        height: 1px;
                        margin: 0 .5em;
                        border-bottom: 1px solid $gray;
                    }

                    &.inline-inputs {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 1em 0 0 0;
                        margin-bottom: 1em;
                    }
                }

                .days-selection {
                    display: flex;
                    align-items: center;

                    .day {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                        height: 35px;
                        margin-right: 5px;
                        margin-bottom: 1em;
                        font-weight: 300;
                        border: 1px solid $gray;
                        cursor: pointer;

                        &.active {
                            background-color: $bgreen-active;
                            color: $bwhite;
                        }
                    }
                }

                .btn-new-por-time-save-cancel {
                    border: none;
                    font-size: em(12);
                    font-weight: 300;
                }
            }

        }

        .fi-close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }
}

.new-contact-popup-wrapper {
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba($gdark, 0.7);
    text-align: left;

    &.active {
        display: block;
    }

    .new-contact-flex {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .new-contact-popup {
        position: relative;
        width: 30em;
        z-index: 1000;
        background-color: $glightest2;
        border-radius: 15px;
        @include opacity(1);

        .title {
            display: block;
            padding: 1em .8em;
            font-family: $secondary-font-family;
            font-size: em(35);
            color: $bdanger;
        }

        .form-wrapper {
            margin: 0 4em;

            form {
                width: 100%;
            }

            .form-group {
                display: block;
                position: relative;
                margin-bottom: 2em;

                .error {
                    display: none;
                }

                &.has-error {

                    .form-input, .form-selection {
                        border: 1px solid $red;
                    }

                    .error {
                        display: flex;
                        flex: 1 0 0;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 1em;

                        .error-text {
                            font-size: em(14);
                            color: $red;
                        }
                    }
                }

                .label-text {
                    display: block;
                    padding-bottom: .5em;
                    font-size: em(18);
                    color: $gdark2;
                }

                .form-input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    padding-left: 15px;
                    font-size: em(15);
                    // line-height: 50px;
                    border: 1px solid $glighter3;
                    border-radius: 8px;

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: $glighter;
                        font-weight: 300;
                    }
                }

                &.form-submit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    flex-wrap: wrap;

                    .legal {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-width: 100%;

                        .legal-text {
                            margin-left: 1em;
                            font-size: em(14);

                        }

                    }

                    .btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 3em;
                        margin-bottom: 1.5em;
                        font-size: em(18);
                        line-height: em(18);
                        font-weight: 300;
                        letter-spacing: 0.2em;
                    }
                }
            }

            .contact-time-list {
                padding: 2em 0 1em 0;
                font-size: em(14);
                font-weight: 300;

                .fi {
                    padding-left: 1em;
                }
            }

            .btn-new-contact-time {
                padding: 1em 1.5em;
                color: $bblack;
                border: 1px solid $bblack;
                border-radius: 8px;
                font-size: em(13);
                text-transform: none;

                .fi {
                    margin-left: -.5em;
                    padding-right: .5em;
                }
            }

            .new-contact-time {
                display: none;

                &.active {
                    display: block;
                }

                .form-group {

                    .time {

                        .form-input {
                            width: 80px;
                        }
                    }

                    .separator {
                        width: 20px;
                        height: 1px;
                        margin: 0 .5em;
                        border-bottom: 1px solid $gray;
                    }

                    &.inline-inputs {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 1em 0 0 0;
                        margin-bottom: 1em;
                    }
                }

                .days-selection {
                    display: flex;
                    align-items: center;

                    .day {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                        height: 35px;
                        margin-right: 5px;
                        margin-bottom: 1em;
                        font-weight: 300;
                        border: 1px solid $gray;
                        cursor: pointer;

                        &.active {
                            background-color: $bgreen-active;
                            color: $bwhite;
                        }
                    }
                }

                .btn-new-contact-time-save-cancel {
                    border: none;
                    font-size: em(12);
                    font-weight: 300;
                }
            }

        }

        .fi-close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }
}

.reg-wrapper.advert-form-wrapper .select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
}

.select2-container .select2-selection--single,
.select2-container {
    height: 52px;
    left: -1px;
    top: -1px;
    margin-right: -2px;
}

.select2-dropdown {
    border: 1px solid #E0E0E0;
}

.has-error .select2-container--default .select2-selection--single,
.has-error .select2-container--default .select2-selection--multiple {
    border: 1px solid $bdanger;
}

.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #E0E0E0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered, .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    // line-height: 50px;
    text-align: center;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    line-height: 40px;
}

.template_image {
    padding: 0 !important;
}

.template_image .dz-preview {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
}

.template_image .dz-image {
    width: 100% !important;
    height: 100% !important;
}

#imagesDropzone {
	background: rgb(240, 240, 240);
	border: 0;
    display: block;

	&:hover {
		.dz-remove {
			height: 50%;
		    color: white;
		    text-align: center;
		    vertical-align: middle;
		    width: 100%;
		    position: absolute;
		    padding: 10px;
		    z-index: 20;
		    display: block;
		    top: 50%;
    		background: rgba(0,0,0, 0.6);
            cursor: pointer;
		}

		.image-highlight-btn {
			height: 50%;
		    color: white;
		    text-align: center;
		    vertical-align: middle;
		    width: 100%;
		    position: absolute;
		    padding: 10px;
		    z-index: 20;
		    display: block;
		    top: 0;
    		background: rgba(0,0,0,0.6);
            cursor: pointer;
		}
	}

	.dz-remove {
		display: none;
	    cursor: pointer;
	    text-decoration: none;
        margin: 0;
	}

	.image-highlight-btn {
	    display: none;
	    cursor: pointer;
	    text-decoration: none;
        font-weight: 100;
        border-bottom: 1px solid #FFF;
	}

	&.dropzone {
		&.dz-started {
			.dz-message {
				display: flex;
			}
		}

		.dz-message {
			background: #e7e7e7;
			border: 2px dashed #bababa;
			border-radius: 30px;
			margin: 0;
			padding: 2em 0;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
		}

		.dz-preview {
			min-height: 80px;
            border: 4px solid #FFF;
            z-index: 0;

			.dz-image {
				border-radius: 0;
				height: 80px;
			}

            &.selected {
                border: 4px solid $bdanger;
            }
		}
	}
}

.list-edit-table {
    width: 100%;
}

.list-edit-table th, .list-edit-table td {
    vertical-align: middle;
    padding: 10px;
    text-align: center;
}

.list-edit-table span.icon {
    pointer: cursor;
}
