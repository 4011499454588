.title-wrapper {
    display: block;
    margin-bottom: 1.5em;

    h1.page-title {
        font: normal 36px/1.5em $sfaf;
        color: $bblack;

        span { color: $bred; }
    }

    .addthis_native_toolbox {
        margin-top: 1em;
    }
}

.link-gallery {
    display: block;
    margin-top: 2em;

    .gallery-title {
        display: block;
        font: bold 1.3em/1.5em $sfaf;
        margin-bottom: 1em;
    }

    ul.links {
        margin-left: 2em;
        list-style: none;

        li {
            position: relative;
            padding: .7em 0 .7em 2em;
            &:before {
                content: "";
                width: 26px;
                height: 26px;
                display: block;
                position: absolute;
                top: .7em;
                left: 0;
                background: url(../img/list-arrow.svg) no-repeat;
                background-size: 100%;
            }

            a {
                display: inline-block;
                font-size: 1.2em;
                line-height: 1.5em;
                color: $bblack;
                margin-left: .5em;
                &:hover { text-decoration: underline; }
            }
        }
    }
}