.news-list {
    display: block;
    &.category-list {
        margin-top: 3em;
    }

    .news-item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 2em;
        border-bottom: 1px solid lighten($glighter, 5%);
        margin-bottom: 2em;
        &:last-child { border-bottom: none; }

        .img-container {
            flex-basis: 260px;
            margin-right: 1.5em;

            img { width: 100%; height: auto; display: block; }
        }

        .news-item-content {
            flex: 1;

            .date {
                display: block;
                color: lighten($bblack, 15%);
                font-size: .8em;
                margin-bottom: .5em;
            }

            h2.news-title {
                display: block;
                margin-bottom: 1em;

                a {
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 1.5em;
                    color: lighten($bblack, 15%);
                    &:hover { color: lighten($bblack, 10%); }
                }
            }

            .page-body {
                display: block;
                margin-bottom: 1em;
            }

            .btn-container {
                display: block;
                margin-top: .5em;
            }
        }
    }
}