.event-filters {
    margin: .5em 0 2em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-group {
        position: relative;
        margin-right: 2em;
        &.form-group-date { width: 50%; }
        &.form-group-category { min-width: 25%; }
        &.form-group-checkbox {
            max-width: 120px;
            margin-left: 1em;
            padding: 2em 0 0 2em;
            line-height: 1.5em;
            input { position: absolute; top: 50%; left: 0; margin-top: 7px; }
        }

        .form-group-label {
            display: block;
            font-size: 1.2em;
            line-height: 1.8em;
            font-weight: bold;
        }

        .form-input {
            width: 38%;
            height: 42px;
            border: 1px solid $glighter;
            border-radius: $rad;
        }

        .form-select {
            width: 90%;
            height: 42px;
            border: 1px solid $glighter;
            border-radius: 5px;
        }

        span {
            width: 25px;
            display: inline-block;
            font-family: $sfaf;
            position: relative;
            color: $glighter;
        }

        .date-label {
            position: relative;
            margin-right: 2em;

            span:before {
                content: "\f073";
                font-family: $ifaf;
                position: absolute;
                top: 0;
                right: 3em;
                color: $gray;
            }
        }

        .no-filters {
            display: inline-block;
            font-size: .9em;
            color: $gray;
            margin-top: 2.2em;
            &:hover { text-decoration: underline; }
        }
    }
}

.event-list {
    display: block;
    margin: 3em 0 1em 0;

    .event {
        display: block;
        margin-bottom: 2em;
        border-left: 1px solid $gray;

        .event-date {
            display: block;
            border-left: 4px solid $bblack;
            padding: .5em 0 .5em 1em;

            span {
                display: block;
                &.event-date-year {
                    font-size: .9em;
                    line-height: 1.3em;
                    color: $gray;
                }
                &.event-date-month {
                    font-size: 2em;
                    font-weight: 100;
                    line-height: 1.3em;
                    text-transform: uppercase;
                    b { font-weight: 900; }
                }
            }
        }

        .event-flex {
            display: flex;
            justify-content: space-between;
            padding: 1em 0 1em 1.5em;

            .event-body {
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .event-body-left {
                    flex: 1;
                    padding-left: 2em;
                }

                .btn-container {
                    margin: 0 2em;
                }

                h2 {
                    display: block;
                    margin-bottom: .5em;

                    a {
                        font-size: 1.5em;
                        font-weight: normal;
                        line-height: 1.5em;
                        color: $bdanger;
                        &:hover { text-decoration: underline; }
                    }
                }

                .event-category {
                    display: block;
                    font-style: italic;
                    color: $gray;
                }
            }

            .event-image {
                flex-basis: 30%;
                margin-left: 1em;

                img { width: 100%; display: block; }
            }
        }
    }
}