@media only screen and (max-width: 1300px) {

    .wrapper {
        width: 100%;
        padding: 0 1em;

        &.market-not-column-reverse {

            .sidebar {
                width: 335px;
            }
        }
    }

    .site-header {

        .wrapper {
            display: block;
            flex: none;

            .logo {
                margin: 1em 0 1em 1em;
            }

            .szechenyi-logo {
                margin-top: -1em;
            }
        }

        .header-flex {
            height: 120px;

            .nav-menu {
                ul {
                    li {
                        &:first-child { display: none; }
                    }
                }
            }

            &.header-custom {

                .header-bar {
                    justify-content: flex-start;

                    .header-search-form {
                        width: 100%;
                        justify-content: flex-start;
                        margin-right: 0;
                    }
                }

                .nav-menu {
                    margin: 0;
                    text-align: left;

                    ul {
                        line-height: 1.5em;
                        li {
                            &:first-child { display: none; }
                        }
                    }
                }
            }
        }

        .search-form {
            width: 190px;
            margin-right: 180px;

            .search-input { font-size: .9em; }
        }

        .szechenyi-logo {
            width: 200px;
            height: 150px;
        }

        .piacter-header {
            width: 100%;
            padding: 0 1em;
        }

    }

    .sidebar {
        width: 300px;

        .calendar-container {
            padding: 2em 1.5em;
            .calendar-title { font-size: 1em; }
        }

        .newsletter-container {
            .subscribe-title { font-size: .8em; }
            .subscribe-group { margin: 0 1.5em; }
        }
    }

    .more-news .more-news-item,
    .featured-pages .page-item {
        width: 100%;
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .reg-wrapper {

        .entity-choice {
            height: auto;

            .choice-wrapper {
                display: block;
                width: 100%;
            }

            .choice-info {
                display: block;
                height: auto;
                width: 100%;

                &--text {
                    padding-bottom: 2em;
                }
            }
        }
    }

    .profile-wrapper {
        width: 100%;

        .form-wrapper {

            .input-wrapper {

                .user-list {

                    .user-details {
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        position: relative;

                        .user-name {
                            flex: 1 0 100%;
                            padding: 1em 0;
                        }

                        .user-email {
                            flex: 1 0 100%;
                            padding: 1em 0;
                        }

                        .user-actions {
                            padding: 1em 0;

                            .form-selection.user-action-selection {

                                select {
                                    padding: 0 50px 0 45px;
                                }
                            }
                        }

                        i {
                            position: absolute;
                            top: 1em;
                            right: 1em;
                        }
                    }
                }

                .new-user {

                    .inline-inputs.inline-inputs-space {

                        .label-onethird {
                            width: 33%;
                        }
                    }
                }
            }
        }
    }

    .adverts-wrapper {

        .form-wrapper {

            .form-content {
                padding: 2em 1em;

                .form-header {
                    flex-wrap: wrap;

                    .new-button-wrapper {
                        width: 100%;
                        margin-top: 1em;
                    }
                }

                .form-list {

                    .list-header {

                        .list-header-title {

                            &.status {
                                width: 10%;
                            }

                            &.text {
                                width: 43%;
                            }

                            &.date {
                                width: 9%;
                            }

                            &.rating-name {
                                width: 14%;
                            }

                            &.product-name {
                                width: 15%;
                            }


                        }
                    }

                    .list-item {

                        .list-item-section {

                            &.name {
                                padding-right: 1em;
                            }

                            &.category-icon {
                                padding-right: 1em;
                            }

                            &.category {
                                padding-right: 1em;
                            }

                            &.status {
                                width: 10%;

                                .custom-dropdown {
                                    width: 90px;

                                    .placeholder {

                                        .icon {
                                            display: none;
                                        }
                                    }

                                    .dropdown {

                                        .dropdown-item {

                                            .icon {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }

                            &.text {
                                width: 36%;
                            }

                            &.stars {
                                width: 7%;
                                padding-left: 1em;
                            }

                            &.date {
                                width: 9%;
                            }

                            &.rating-name {
                                width: 14%;
                            }

                            &.product-name {
                                width: 15%;
                            }

                            &.rating-status {
                                width: 6%;
                            }

                            &.rating-status-action {
                                width: 13%;
                            }
                        }
                    }
                }
            }
        }
    }

    .mentor-wrapper {

        .mentor-content {
            width: 100%;
        }
    }

    .market-wrapper {

        .list {

            .list-item {

                .details {
                    padding: 3em 2em;

                    .product-info {
                        flex-direction: column;
                        align-items: flex-start;

                        .company {
                            width: 100%;
                        }
                    }
                }

                .image {
                    padding: 1em;
                }

                .contact {
                    padding: 3em 1em;
                }
            }
        }

        .market-product {

            .tabs-ratings {

                .rating-wrapper {
                    min-width: 250px;
                    padding: 0;
                }
            }
        }
    }

}

@media only screen and (max-width: 1100px) {

    .site-header {

        .wrapper {
            display: block;
            flex: none;

            .logo {
                margin: 1em 0 1em 1em;
            }

            .szechenyi-logo {
                margin-top: -1em;
            }
        }

        .header-flex {
            height: 120px;

            .nav-menu {
                ul {
                    li {
                        &:first-child { display: none; }
                    }
                }
            }

            &.header-custom {

                .header-bar {
                    justify-content: flex-start;

                    .header-search-form {
                        width: 100%;
                        justify-content: flex-start;
                        margin-right: 0;
                    }
                }

                .nav-menu {
                    text-align: left;

                    ul {
                        line-height: 1.5em;
                        li {
                            &:first-child { display: none; }
                        }
                    }
                }
            }
        }

        .search-form {
            width: 190px;
            margin-right: 180px;

            .search-input { font-size: .9em; }
        }

        .szechenyi-logo {
            width: 200px;
            height: 150px;
        }
    }

    .sidebar {

        &.sidebar-right {
            width: 220px;
        }
    }

    .sidebar-box-category {
        .box-title {
            font-size: 1.2em;
        }

        .box-content {
            padding: 1.5em;

            ul li a { font-size: .9em;
                &:before { top: 0; }
            }
        }
    }

    .left-nav {
        padding: 1em;
    }

    .title-wrapper {
        h1.page-title { font-size: 24px; }
    }

    .news-list {
        .news-item {
            .img-container { flex-basis: 200px; }
        }
    }

    .gallery-masonry {
        .grid-sizer { width: 28%; }
        .gutter-sizer { width: 25px; }
    }

    .event-filters {
        .form-group {
            &.form-group-date { width: 70%; }
        }
    }

    .profile-wrapper {
        width: 100%;

        .profile-header {
            display: block;
            flex: none;
            position: relative;
            height: 7.5em;
            padding-left: .5em;

            .logged-in {
                display: block;
                padding-top: 1em;
            }

            .logout-btn {
                display: block;
                position: absolute;
                right: 1em;
                bottom: .5em;
            }
        }

        .form-wrapper {

            .form-tabs {

                .form-tab {
                    padding: 1em;
                    min-height: 4em;
                }
            }

            .validation-error {
                padding: 2em .5em;
                margin: 2em 1.5em 0 1.5em;
            }

            .input-wrapper {

                .new-user {

                    .inline-inputs.inline-inputs-space {
                        flex-wrap: wrap;
                        justify-content: flex-start;

                        .label-onethird {
                            flex: 1 0 100%;
                            padding-bottom: 1em;

                            .label-text {
                            }
                        }
                    }
                }
            }

            &.validation-form {

                .input-wrapper {

                    .inline-inputs {
                        flex-wrap: wrap;

                        .label-text-inline {
                            width: 100%;
                        }

                        .form-selection {
                            min-width: 180px;
                            margin-top: 1em;
                            margin-right: 1em;
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }

    .adverts-wrapper {

        .form-wrapper {
            min-width: 1024px;

            .form-content {

                .form-header {

                    .filter-wrapper {
                        width: 100%;
                        margin-top: 1em;
                    }
                }

                .form-list {

                    .list-header {

                        .list-header-title {

                            &.name {
                                width: 40%;
                            }
                        }
                    }

                    .list-item {

                        .list-item-section {

                            &.name {
                                width: 26%;
                            }

                        }
                    }
                }
            }
        }
    }

    .market-wrapper {

        .list-nav {
            flex-wrap: wrap;

            .list-nav-text {
                width: 100%;
                padding-bottom: 2em;
            }

            .list-pagination {
                width: auto;
                padding-right: 2em;

                .pagination-pages {
                    margin: 0 1em;
                }
            }

            .items-per-page {
                width: auto;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {

    .wrapper {

        &.market-not-column-reverse {

            .sidebar {
                width: 300px;

                .filter-form {
                    padding: 1em;
                }
            }

            .home-flex {
                width: 100%;

                .main-content {
                    padding: 1em;
                }
            }
        }
    }

    .market-wrapper {

        .list {

            .list-item {
                flex-wrap: wrap;

                .details {
                    width: 100%;

                    .product-info {
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        .company {
                            width: auto;
                        }

                        .date {
                            padding-left: 2em;
                        }

                        .image-counter {
                            padding-left: 2em;
                        }
                    }
                }

                .image {
                    width: 50%;
                }

                .contact {
                    width: 50%;
                }
            }
        }

        .market-product {

            .main-info {
                flex-wrap: wrap;

                .gallery {
                    width: 100%;
                }

                .desc {
                    width: 60%;
                }

                .info {
                    width: 40%;
                }
            }

            .tabs-ratings {

                .more-info-tabs {
                    margin-right: .5em;
                }
            }

            .more-info {
                flex-wrap: wrap;

                .more-info-content {
                    width: 100%;

                    .description-wrapper {
                        padding: 0 1em;
                    }
                }

                .ratings {
                    width: 100%;
                }
            }

            .more-products {

                .products {
                    padding: 2em;

                    .product-tile {
                        padding: 3em 1em 1em 1em;
                        margin-left: 1em;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 900px) {

    .site-header {

        .search-form {
            width: auto;

            .sightless-block {
                display: none;
            }

            .search-input {
                display: none;
            }

            .search-submit {
                display: none;
            }
        }

        .header-flex {
            height: 70px;

            .nav-menu {

                ul {
                    height: auto;
                    line-height: 40px;
                }
            }
        }
    }

    .home-flex {
        flex-direction: column;
    }

    .herospace {
        .hero-slide {
            padding: 2em;

            .proceed { display: none; }
        }

        .flickity-page-dots {
            display: none;
        }
    }

    .sidebar {
        &.sidebar-right { width: 100%; }
    }

    .mentor-wrapper {

        .mentor-content {
            min-width: 640px;

            .mentor-card {
                overflow-x: scroll;

                .sidebar {
                    position: relative;
                    width: 50px;
                    padding-top: 6em;
                    overflow: hidden;

                    .mobile-sidebar-trigger {
                        display: flex;

                        .mobile-sidebar-trigger-off {
                            display: block;
                        }

                        .mobile-sidebar-trigger-on {
                            display: none;
                        }
                    }

                    .sidebar-text-content {
                        display: none;
                    }

                    .timeline-wrapper {
                        display: none;
                    }

                    .sidebar-header {

                        .sidebar-header-text {
                            display: none;
                        }
                    }

                    .sidebar-content {
                        display: none;
                    }

                    &.mobile-sidebar-triggered {
                        min-width: 330px;

                        .mobile-sidebar-trigger {

                            .mobile-sidebar-trigger-off {
                                display: none;
                            }

                            .mobile-sidebar-trigger-on {
                                display: block;
                            }
                        }

                        .sidebar-text-content {
                            display: block;
                        }

                        .timeline-wrapper {
                            display: flex;
                            padding-right: .5em;
                        }

                        .sidebar-header {

                            .sidebar-header-text {
                                display: block;
                            }
                        }

                        .sidebar-content {
                            display: block;
                        }
                    }
                }

                .functions {
                    min-width: 600px;
                    width: 100%;
                    padding: 1em 3em;

                    &.mobile-sidebar-triggered {
                        //width: 520px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {

    .site-header {
        .header-flex {
            .nav-menu {
                ul li a { font-size: .9em; padding: .75em; }
            }
        }
    }

    .sidebar {
        &.sidebar-left { width: 220px; }

        .calendar-container {
            .calendar-title { font-size: 1em; }
            .event-calendar { display: none; }
            .btn-container {
                margin-top: -1em;
                .btn { line-height: 1.5em; }
            }
        }

        .newsletter-container {
            .subscribe-title { font-size: 1em; line-height: 1.5em; }
            .subscribe-group { margin: 0 .5em; }
        }
    }

    .left-nav {
        ul li a { font-size: .9em; &:before { top: .9em; } }
    }

    .main-content {
        padding: 1em;
    }

    .news-list {
        .news-item {
            flex-direction: column;

            .img-container {
                flex: 0 auto;
                img { width: 100%; }
            }

            .news-item-content {
                width: 85%;
                background: $bwhite;
                padding: 1em 0;
                margin-top: -25px;
            }
        }
    }

    .reg-wrapper {

        .reg-form {
            display: block;

            .form {
                width: 100%;

                .form-body {
                    padding: 1.5em;

                    .form-group {

                        .icon-tooltip {
                            position: relative;
                            top: 0;
                            right: 0;
                            margin-top: 1em;

                            .tooltip-bubble {
                                width: 300px;
                            }
                        }
                    }
                }
            }

            .tip {
                width: 100%;
                max-width: 100%;
                padding-bottom: 2em;
            }
        }
    }

}

@media only screen and (max-width: 768px) {

    .site-header {
        height: auto;

        .logo {
            width: 200px;
            height: 52px;
        }

        .header-flex {
            height: auto;
            justify-content: space-between;

            .nav-menu {
                width: 100%;
                margin-left: 1em;

                ul {
                    margin-top: 1.5em;

                    li {
                        display: block;
                        padding: 0 .5em;
                        line-height: 1.5;
                        &:first-child { display: none; }

                        a {
                            display: block;
                            text-align: left;
                            padding: .5em 0;
                        }
                    }
                }
            }

            &.header-custom {

                .header-bar {

                    .header-search-form {

                        .sightless-block {
                            display: none;
                        }
                    }
                }

                .nav-menu {

                    ul {
                        height: auto;

                        li {
                        }
                    }
                }
            }
        }

        .search-form {
            margin-right: 0;
        }

        .szechenyi-logo {
            display: none;
        }

        .nav-menu-toggler {
            display: block;
        }

        .piacter-header {
            padding: 1em;
        }
    }

    .site-container {

        .wrapper {
            flex-direction: column-reverse;
            padding: 1em .2em 0 .2em;

            &.market-not-column-reverse {
                flex-direction: column;

                .sidebar {
                    width: 100%;

                    .filter-form {

                        .btn-filter-trigger-on {
                            display: block;
                        }

                        .form-wrapper {
                            display: none;

                            .form-group {

                                &.inline-inputs {
                                    justify-content: flex-start;
                                }
                            }
                        }

                        &.active {

                            .form-wrapper {
                                display: block;
                            }

                            .btn-filter-trigger-on {
                                display: none;
                            }

                            .btn-filter-trigger-off {
                                display: block;
                            }
                        }
                    }

                    .partner-search-container {
                        display: none;
                    }

                    .banner-container {
                        display: none;
                    }
                }

                .home-flex {
                    padding-top: 2em;
                }
            }

        }

        .home-flex {

            .main-content {
                padding: 0;

                .profile-wrapper {
                    margin-left: 0;
                    margin-top: 0;
                }

                .market-wrapper {
                    padding: 0 1em;

                }
            }
        }
    }

    .sidebar {
        &.sidebar-left {
            width: 100%;

            .left-nav {
                width: 220px;
                height: 100%;
                position: fixed;
                top: 0;
                left: -220px;
                z-index: 200;

                ul {
                    li {
                        padding: 0;

                        a {
                            font-size: 1.1em;
                            padding: .9em 1.1em 1.1em 1.5em;
                        }
                    }
                }
            }
        }

        .newsletter-container {
            .subscribe-group { margin: 0 1.5em; }
        }

        .banner-container {
            display: flex;
            justify-content: space-between;

            a {
                width: 50%;
                text-align: center;
                padding: .5em;
                border: none;

                img { max-width: 90%; }
            }
        }
    }

    .profile-wrapper {

        .validation-title {
            padding: .5em .5em;
        }

        .form-wrapper {

            &.validation-form {

                .input-wrapper {
                    margin: 0 1em 0 1em;
                }
            }
        }

        .back-to-profile {

            .home {
                margin-left: 1.25em;
            }
        }
    }

    .reg-wrapper {

        .entity-choice {

            .choice-wrapper {

                .choice-tiles {
                min-height: 1400px;

                    .choice-tile {
                        width: 100%;
                        height: 25%;

                        &--top-left {

                            .title {
                                top: 2em;
                            }

                            .content {
                                display: block;
                                height: 100%;
                                width: 100%;
                                top: 7em;
                                bottom: auto;
                                @include transform (translate(0, 0));
                                @include opacity (1);

                                &--list {
                                    position: absolute;
                                    top: 0;
                                }
                            }
                        }

                        &--top-right {
                            top: 25%;
                            left: 0;

                            .title {
                                top: 2em;
                            }

                            .content {
                                display: block;
                                height: 100%;
                                width: 100%;
                                top: 7em;
                                bottom: auto;
                                @include transform (translate(0, 0));
                                @include opacity (1);

                                &--list {
                                    position: absolute;
                                    top: 0;
                                }
                            }
                        }

                        &--bottom-left {
                            top: 50%;
                            left: 0;

                            .title {
                                top: 2em;
                            }

                            .content {
                                display: block;
                                height: 100%;
                                width: 100%;
                                top: 7em;
                                bottom: auto;
                                @include transform (translate(0, 0));
                                @include opacity (1);

                                &--list {
                                    position: absolute;
                                    top: 0;
                                }
                            }
                        }

                        &--bottom-right {
                            top: 75%;
                            left: 0;

                            .title {
                                top: 2em;
                            }

                            .content {
                                display: block;
                                height: 100%;
                                width: 100%;
                                top: 7em;
                                bottom: auto;
                                @include transform (translate(0, 0));
                                @include opacity (1);

                                &--list {
                                    position: absolute;
                                    top: 0;
                                }
                            }
                        }

                        .title {

                            &--logo {

                                .icon {
                                    width: em(42);
                                    height: em(42);

                                    &:before {
                                        width: em(42);
                                        height: em(42);
                                    }

                                    i {
                                        font-size: em(22);
                                    }
                                }
                            }

                            &--category {
                                font-size: em(16);
                                line-height: em(18);
                                min-height: em(36);
                            }
                        }

                        .title {

                            &--logo {
                                padding: .2em 0;
                            }
                        }

                        .content--list {
                            width: 100%;
                            padding: 1em 1.5em .2em 1.5em;
                        }

                        .content {

                            &.content--horizontal {

                                .content--list {
                                    padding: 1em 1.5em .2em 1.5em;
                                }
                            }
                        }
                    }

                    &.top-left-expanded {

                        .choice-tile {

                            &--top-left {
                                height: 46%;

                                .title {
                                    top: .3em;

                                    &--logo {
                                    }
                                }

                                .content {
                                    top: .3em;

                                    &--list {
                                        top: 6em;
                                    }
                                }
                            }

                            &--top-right {
                                height: 18%;
                                top: 46%;

                                .title {
                                    top: 1em;

                                    &--logo {
                                        padding-top: .2em;
                                        padding-bottom: .2em;
                                    }
                                }
                            }

                            &--bottom-left {
                                height: 18%;
                                top: 64%;

                                .title {
                                    top: 1em;

                                    &--logo {
                                        padding-top: .2em;
                                        padding-bottom: .2em;
                                    }
                                }
                            }

                            &--bottom-right {
                                height: 18%;
                                top: 82%;

                                .title {
                                    top: 1em;

                                    &--logo {
                                        padding-top: .2em;
                                        padding-bottom: .2em;
                                    }
                                }
                            }
                        }
                    }

                    &.top-right-expanded {

                        .choice-tile {

                            &--top-left {
                                height: 18%;
                                width: 100%;
                                top: 0;

                                .title {
                                    top: 1em;

                                    &--logo {
                                        padding-top: .2em;
                                        padding-bottom: .2em;
                                    }
                                }
                            }

                            &--top-right {
                                height: 46%;
                                width: 100%;
                                top: 18%;
                                left: 0;

                                .title {
                                    top: .3em;
                                    @include transform (translate(-50%, 0));

                                    &--logo {
                                    }
                                }

                                .content {
                                    width: 100%;
                                    top: 0;
                                    bottom: .3em;
                                    @include transform (translate(0, 0));

                                    &--list {
                                        position: absolute;
                                        top: 6em;
                                        bottom: 0;
                                        columns: 2;
                                    }
                                }
                            }

                            &--bottom-left {
                                height: 18%;
                                width: 100%;
                                top: 64%;

                                .title {
                                    top: 1em;

                                    &--logo {
                                        padding-top: .2em;
                                        padding-bottom: .2em;
                                    }
                                }
                            }

                            &--bottom-right {
                                height: 18%;
                                width: 100%;
                                top: 82%;

                                .title {
                                    top: 1em;

                                    &--logo {
                                        padding-top: .2em;
                                        padding-bottom: .2em;
                                    }
                                }
                            }
                        }
                    }

                    &.bottom-left-expanded {

                        .choice-tile {

                            &--top-left {
                                height: 18%;
                                width: 100%;
                                top: 0;

                                .title {
                                    top: 1em;

                                    &--logo {
                                        padding-top: .2em;
                                        padding-bottom: .2em;
                                    }
                                }
                            }

                            &--top-right {
                                height: 18%;
                                width: 100%;
                                top: 18%;
                                left: 0;

                                .title {
                                    top: 1em;

                                    &--logo {
                                        padding-top: .2em;
                                        padding-bottom: .2em;
                                    }
                                }
                            }

                            &--bottom-left {
                                height: 46%;
                                width: 100%;
                                top: 36%;

                                .title {
                                    top: .3em;
                                    @include transform (translate(-50%, 0));

                                    &--logo {
                                    }
                                }

                                .content {
                                    width: 100%;
                                    top: 0;
                                    bottom: .3em;
                                    @include transform (translate(0, 0));

                                    &--list {
                                        position: absolute;
                                        top: 6em;
                                        bottom: 0;
                                    }
                                }
                            }

                            &--bottom-right {
                                height: 18%;
                                width: 100%;
                                top: 82%;

                                .title {
                                    top: 1em;

                                    &--logo {
                                        padding-top: .2em;
                                        padding-bottom: .2em;
                                    }
                                }
                            }
                        }
                    }

                    &.bottom-right-expanded {

                        .choice-tile {

                            &--top-left {
                                height: 18%;
                                width: 100%;
                                top: 0;

                                .title {
                                    top: 1em;

                                    &--logo {
                                        padding-top: .2em;
                                        padding-bottom: .2em;
                                    }
                                }
                            }

                            &--top-right {
                                height: 18%;
                                width: 100%;
                                top: 18%;
                                left: 0;

                                .title {
                                    top: 1em;

                                    &--logo {
                                        padding-top: .2em;
                                        padding-bottom: .2em;
                                    }
                                }
                            }

                            &--bottom-left {
                                height: 18%;
                                width: 100%;
                                top: 36%;

                                .title {
                                    top: 1em;

                                    &--logo {
                                        padding-top: .2em;
                                        padding-bottom: .2em;
                                    }
                                }
                            }

                            &--bottom-right {
                                height: 46%;
                                width: 100%;
                                top: 54%;
                                left: 0;

                                .title {
                                    top: .3em;
                                    @include transform (translate(-50%, 0));

                                    &--logo {
                                    }
                                }

                                .content {
                                    width: 100%;
                                    top: 0;
                                    bottom: .3em;
                                    @include transform (translate(0, 0));

                                    &--list {
                                        position: absolute;
                                        top: 6em;
                                        bottom: 0;
                                        columns: 2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .market-wrapper {

        .market-product {

            .tabs-ratings {
                flex-wrap: wrap;
                flex-direction: column-reverse;

                .more-info-tabs {
                    width: 100%;
                }

                .rating-wrapper {
                    padding-bottom: 1em;
                }
            }

            .more-info {

                .more-info-content {

                    .contact-wrapper {
                        flex-wrap: wrap;

                        .contacts {
                            width: 100%;
                            padding: 3em 1em;

                            .contacts-title {
                                font-size: em(18);
                            }
                        }

                        .message {
                            width: 100%;
                            padding: 3em 1em;

                            .message-title {
                                padding: 0;
                                font-size: em(18);
                            }

                            .form-group {
                                padding: 1em;

                                .label-text {
                                    font-size: em(16);
                                }
                            }

                            .btn {
                                margin: 1em;
                            }
                        }
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 680px) {

    .herospace {
        height: auto;

        .wrapper {
            height: auto;
        }

        .hero-slide {
            height: auto;
            padding: 1em;

            .hero-slide-content {
                margin-left: 0;

                .date { margin-bottom: .5em; }
            }
        }
    }

    .event-filters {
        flex-direction: column;

        .form-group {
            width: 100%;
            margin: 0;
            &.form-group-date { width: 100%; }
        }
    }

    .event-list {
        .event {
            .event-date {
                span.event-date-month { font-size: 1.5em; }
            }

            .event-flex {
                .event-body {
                    flex-direction: column;
                    .event-body-left { width: 100%; padding-left: 0; }
                    .btn-container { width: 100%; }
                }
            }
        }
    }

    .site-footer {
        .footer-col {
            &.footer-col-social {
                width: 80px;
                text-align: center;

                a { margin: .5em 0; }
            }
        }
    }

    .profile-wrapper {

        .validated {

            .btn {
                font-size: em(14);
                line-height: em(20);
            }
        }

        .form-wrapper {

            .input-wrapper {

                .separator {
                    margin: 0;
                }
            }
        }
    }

    .reg-wrapper {

        .entity-choice {

            .choice-wrapper {

            }
        }

        .reg-form {

            .form {

                .form-body {

                    .inline-inputs {
                        flex-wrap: wrap;

                        .label-filler {
                            flex: 1 0 100%;
                            margin-top: 1em;
                        }
                    }

                    .separator {
                        margin: 0;
                    }
                }
            }
        }
    }

    .market-wrapper {

        .market-product {

            .main-info {

                .desc {
                    width: 100%;
                    padding: 2em;
                }

                .info {
                    width: 100%;
                    min-width: 250px;

                    .address-contact-wrapper {

                        .contact {
                            border-bottom-left-radius: 8px;
                        }
                    }
                }
            }

            .more-products {

                .title {
                    padding: 1em 2em;
                }

                .products {
                    flex-wrap: wrap;

                    .product-tile {
                        width: 100%;
                        margin-bottom: 2em;
                        margin-left: 0;
                    }
                }
            }

            .company-intro {
                flex-wrap: wrap;

                .info {
                    width: 100%;
                    padding: 2em;
                }

                .img-container {
                    width: 100%;
                    padding: 1em;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {

    .featured-news {
        .featured-content {
            padding: 1em;

            h2 { font-size: 1.5em; }
        }
    }

    .gallery-list {
        .gallery-item {
            width: 100%;
        }
    }

    .gallery-masonry {
        .gallery-masonry-item { width: 100%; margin: .5em 0; }
        .grid-sizer { width: 100%; }
        .gutter-sizer { width: 20px; }
    }

    .page-body {
        table {
            width: 95% !important;
            margin: 1em 0;

            td {
                display: block;
                text-align: left !important;
            }
        }
    }

    .news-list {
        .news-item {
            .news-item-content {
                width: 100%;
                padding: 0;
                margin: 1em 0 0 0;
            }
        }
    }

    .site-footer {
        .footer-col-flex {
            flex-direction: column;
        }

        .footer-col {
            width: 100%;
            padding: 1em;
            &.footer-col-social {
                .social-link { margin: 1em; }
            }
        }
    }

    .site-header {

        .header-flex {

            &.header-custom {

                .header-bar {

                    .header-search-form {
                        flex-wrap: wrap;

                        .user-login {
                            margin-right: 0;
                            margin-left: auto;
                        }

                        .search-input-wrapper {
                            width: 100%;
                            margin-top: 1em;
                        }

                    }
                }
            }
        }
    }

    .profile-wrapper {

        .form-wrapper {

            .form-tabs {
                font-size: em(14);

                .form-tab {
                    padding: 1em .5em;
                }
            }
        }
    }

}

@media only screen and (max-width: 480px) {

    .site-header {

        .wrapper {
            flex-direction: column;
            padding: 0 .75em;
        }

        .logo {
            margin-top: 10px;
        }

        .header-flex {
            width: 100%;
            height: 90px;
            flex-direction: column;

            .nav-menu {
                margin: 0;

                ul {
                    height: auto;
                    line-height: 70px;

                    li {
                        display: block;
                        margin-bottom: .5em;
                        &:first-child { display: none; }

                        a { padding: 0 .75em; }
                    }
                }
            }
        }

        .search-form {
            display: none;
        }
    }

    .featured-news {
        .featured-bg {
            display: none;
        }

        .featured-content {
            padding: 1.5em;
            position: static;
            background: rgba(85, 6, 6, 1);

            h2 { font-size: 1.4em; }
        }
    }

    .page-body {
        img { border: none; }
        h2 { font-size: 1.4em; }
        h3 { font-size: 1.2em; }
        h4 { font-size: 1.1em; }
        h5 { font-size: 1em; }
        h6 { font-size: 1em; }
    }

    .profile-wrapper {

        .form-wrapper {

            .form-tabs {

                .form-tab {
                    font-size: em(13);
                    padding: 1em .5em;

                    &.active {
                        font-size: em(14);
                    }
                }
            }

            .input-wrapper {
                margin: 0 .5em;

                .input-group {

                    .form-group {

                        .form-selection-year {
                            max-width: 5em;
                        }

                        .form-selection-month {
                            max-width: 4.5em;
                        }

                        .form-input {
                            padding-left: 5px;

                            &::-webkit-input-placeholder {
                                font-size: em(14);
                            }
                            &::-moz-placeholder {
                                font-size: em(14);
                            }
                            &:-ms-input-placeholder {
                                font-size: em(14);
                            }
                            &:-moz-placeholder {
                                font-size: em(14);
                            }
                        }

                        .form-selection {

                            select {
                                padding-left: 5px;
                            }
                        }
                    }
                }

                .user-list {

                    .user-details {
                        padding: 1em .5em;
                    }
                }
            }

            &.validation-form {

                .input-wrapper {

                    .inline-inputs {

                        .label-text-inline {
                            margin-right: 0;
                        }

                        .form-selection {
                            margin-right: 0;
                        }
                    }
                }

                &.legal-checkbox-wrapper {
                    padding: 1em 1em 0 1em;
                }

                .output-wrapper {
                    margin: 0 1.5em;
                }
            }
        }

        .validated {
            padding: 2em .5em;
        }

    }

    .reg-wrapper {

        .reg-form {

            .form {

                .form-header {

                    .form-title {
                        font-size: em(28);
                    }
                }

                .form-body {
                    padding: 1.5em .5em;

                    .form-group {

                        .form-input {
                            padding-left: 5px;

                            &::-webkit-input-placeholder {
                                font-size: em(14);
                            }
                            &::-moz-placeholder {
                                font-size: em(14);
                            }
                            &:-ms-input-placeholder {
                                font-size: em(14);
                            }
                            &:-moz-placeholder {
                                font-size: em(14);
                            }
                        }

                        .form-selection-year {
                            max-width: 5em;
                        }

                        .form-selection-month {
                            max-width: 4.5em;
                        }

                        .form-selection {

                            select {
                                padding-left: 5px;
                            }
                        }

                        .icon-tooltip {

                            .tooltip-bubble {
                                width: 250px;
                                top: -83px;

                                .tooltip-header {
                                    padding: 10px 30px;
                                }

                                .tooltip-text {
                                    padding: 10px 30px;
                                }
                            }
                        }
                    }
                }
            }

            .tip {

                .tip-title {
                    padding: 35px 40px 0 40px;
                }

                .tip-text {
                    padding: 35px 40px 0 40px;
                }
            }
        }
    }

    .site-container {

        .home-flex {

            .main-content {

                .market-wrapper {
                    padding: 0;

                    .list-nav {

                        .items-per-page {
                            width: 100%;
                            padding-top: .5em;
                        }
                    }

                    .list {

                        .list-item {

                            .details {

                                .product-info {

                                    .company {

                                        .company-text {
                                            font-size: em(16);
                                        }
                                    }
                                }
                            }

                            .contact {

                                .contact-details {

                                    .company-name {
                                        font-size: em(14);
                                    }

                                    .address {
                                        font-size: em(14);
                                    }
                                }
                            }
                        }
                    }

                    .market-product {

                        .main-info {

                            .gallery {
                                padding: 1em;
                            }
                        }
                    }
                }
            }
        }
    }

    .rating-popup-wrapper {

        .rating-popup {
            width: 90%;

            .form-wrapper {
                margin: 0 2em;
            }
        }
    }

}
