.reg-wrapper {
    width: 100%;
    font-size: $base-font-size-px;

    .back-to-home {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .home {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: em(20);
            color: $gray;
            text-transform: uppercase;
            font-weight: 100;

            &:visited {
                color: $gray;
            }
        }

    }

    .entity-choice {
        display: block;
        height: 624px;
        width: 100%;
        font-size: 0;

        .choice-wrapper {
            display: inline-block;
            width: 62%;
            background-color: $glightest2;
            font-size: $base-font-size-px;

            .choice-title {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                background-color: $glightest2;

                &--text {
                    font-size: em(35);
                    font-weight: 300;
                    padding: .7em 1em;
                    font-family: $sfaf;
                }
            }

            .choice-tiles {
                position: relative;
                width: 100%;
                height: 540px;
                overflow: hidden;

                .choice-tile {
                    height: 50%;
                    transition: all 200ms ease-in-out;

                    .title--link {
                        color: $bblack;
                        display: block;

                        &:visited {
                            color: $bblack;
                        }
                    }

                    &--top-left {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 62%;
                        background-color: $reg-tile-tl;

                        .title {
                            top: 3em;
                        }
                    }

                    &--bottom-left {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 62%;
                        background-color: $reg-tile-bl;

                        .title {
                            bottom: 3em;
                        }
                    }

                    &--top-right {
                        position: absolute;
                        top: 0;
                        left: 62%;
                        width: 38%;
                        background-color: $reg-tile-tr;

                        .title {
                            top: 3em;
                        }
                    }

                    &--bottom-right {
                        position: absolute;
                        top: 50%;
                        left: 62%;
                        width: 38%;
                        background-color: $reg-tile-br;

                        .title {
                            bottom: 3em;
                        }
                    }

                    .title {
                        position: absolute;
                        left: 50%;
                        // width: em(160);
                        // height: em(164);
                        // margin-top: em(-87);
                        // margin-left: em(-80);
                        @include transform (translate(-50%, 0));
                        transition: all 200ms ease-in-out;

                        &--logo {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 1em 0;

                            .icon {
                                margin-right: 0;
                            }
                        }

                        &--category {
                            font-size: em(20);
                            font-weight: 300;
                            line-height: em(24);
                            min-height: em(48);
                            text-transform: uppercase;
                            text-align: center;
                        }
                    }

                    .content {
                        position: absolute;
                        bottom: 2em;
                        @include opacity(0);
                        @include transform (translate(0, 100%));
                        transition: all 50ms ease-in-out;

                        &--horizontal {
                            width: 50%;
                            right: 0;
                            top: 50%;
                            bottom: 0;
                            @include transform (translate(100%, -25%));

                            .content--list {
                                columns: 1;
                                padding: 0;
                            }
                        }

                        &--list {
                            padding: 1em 3em 0 3em;
                            columns: 2;
                            font-size: em(13);
                            font-weight: 300;
                            line-height: em(26);
                            color: $gray;

                            li {
                                margin-left: 2em;
                            }
                        }
                    }
                }
            }
        }

        .choice-info {
            display: inline-block;
            height: 100%;
            width: 38%;
            vertical-align: top;
            color: $bblack;
            background-color: $gbg;
            font-size: $base-font-size-px;
            font-weight: 300;

            &--title {
                padding: 3em 0;
                font-size: em(24);
                font-weight: 300;
                text-align: center;
            }

            &--text {
                padding: 0 4em;
                font-size: em(14);
                line-height: em(28);
            }
        }
    }

    .reg-form {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;


        .form {
            width: 61%;

            &.new-advert-form {
                width: 100%;
            }

            .form-header {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-flow: row wrap;
                color: $bblack;
                background-color: $glightest2;

                .back-to-choice {
                    color: $glight2;
                    background-color: $glighter2;
                    width: em(80);
                    height: em(80);
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: center;

                    &:visited { color: $glight2; }

                    i { margin: 0 auto; }
                }

                .form-title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex: 1 0 0;
                    margin-left: .5em;
                    font-family: $sfaf;
                    font-size: em(35);
                    font-weight: 400;
                    height: 2.2em;
                }

                .form-category {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    height: em(80);
                    background-color: $glighter3;

                    .icon {
                        margin-left: 1.1em;
                    }

                    .form-category-title {
                        font-size: em(22);
                        text-transform: uppercase;
                        color: $gdark2;
                        font-weight: 300;
                    }

                    .adverts-new-tabs {
                        align-self: flex-end;
                        margin-right: 0;
                        margin-left: auto;

                        .adverts-new-tab {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            width: 180px;
                            height: 50px;
                            font-size: em(15);
                            font-weight: 300;
                            cursor: pointer;

                            &.active {
                                background-color: $glightest2;
                            }
                        }
                    }
                }

            }

            .form-body {
                padding: 3.5em;
                background-color: $glightest2;

                .input-group {
                    display: none;

                    &.active {
                        display: block;
                    }

                    .template {
                        display: none;
                        width: 100%;
                        padding-bottom: 2em;

                        .text-1, .text-2, .text-3,
                        .image-1, .image-2, .image-3 {
                            margin-bottom: 40px;
                            width: 100%;
                            height: 340px;
                        }

                        &.template-1 {
                            display: block;

                            .content-row {
                                display: flex;
                                flex-flow: row wrap;

                                .text-1 {
                                    flex: 6;
                                }

                                .image-1 {
                                    flex: 4;
                                    margin-left: 40px;
                                }

                                .image-3 {
                                    display: none;
                                }
                            }
                        }

                        &.template-2 {
                            display: block;

                            .content-row {
                                display: flex;
                                flex-flow: row wrap;

                                .text-1,
                                .image-2 {
                                    display: none;
                                }

                                .text-3 {
                                    flex: 6;
                                }

                                .image-3 {
                                    flex: 4;
                                    margin-left: 40px;
                                }
                            }
                        }

                        &.template-3 {
                            display: block;

                            .content-row {
                                display: flex;
                                flex-flow: row wrap;

                                .text-1 {
                                    flex: 6;
                                }

                                .image-1 {
                                    flex: 4;
                                    margin-left: 40px;
                                }

                                &.content-row-2 {
                                    flex-direction: row-reverse;

                                    .text-2 {
                                        flex: 6;
                                    }

                                    .image-2 {
                                        flex: 4;
                                        margin-right: 40px;
                                    }
                                }

                                .text-3 {
                                    flex: 6;
                                }

                                .image-3 {
                                    flex: 4;
                                    margin-left: 40px;
                                }
                            }
                        }
                    }
                }



                .form-group {
                    display: block;
                    position: relative;
                    margin-bottom: 2em;

                    .icon-tooltip {
                        position: absolute;
                        top: 2.2em;
                        right: -2.5em;
                        margin: 0;

                        &:hover {

                            .tooltip-bubble {
                                display: block;
                            }
                        }

                        .tooltip-bubble {
                            display: none;
                            position: absolute;
                            left: 3em;
                            width: 300px;
                            height: 200px;
                            padding: 0px;
                            background: lighten($bsecondary, 40%);
                            border-radius: 15px;
                            border: 1px solid $bsecondary;

                            &:after, &:before {
                            	right: 100%;
                            	top: 50%;
                            	border: solid transparent;
                            	content: " ";
                            	height: 0;
                            	width: 0;
                            	position: absolute;
                            	pointer-events: none;
                            }

                            &:after {
                                border-color: transparent;
                            	border-right-color: lighten($bsecondary, 40%);
                            	border-width: 15px;
                            	margin-top: -15px;
                            }

                            &:before {
                                border-color: transparent;
                            	border-right-color: $bsecondary;
                            	border-width: 16px;
                            	margin-top: -16px;
                            }

                            .tooltip-header {
                                display: block;
                                width: 100%;
                                padding: 20px 30px;
                                background-color: lighten($bsecondary, 35%);
                                border-top-left-radius: 15px;
                                border-top-right-radius: 15px;
                                font-size: em(17);
                                font-weight: 300;
                            }

                            .tooltip-text {
                                display: block;
                                padding: 15px 30px;
                                font-size: em(13);
                                line-height: em(30);
                                font-weight: 300;
                            }
                        }
                    }

                    .error {
                        display: none;
                    }

                    &.has-error {


                        .error {
                            display: flex;
                            flex: 1 0 0;
                            justify-content: flex-start;
                            align-items: center;
                            margin-top: 1em;

                            .error-text {
                                color: $red;
                            }
                        }
                    }

                    .radio-input {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        input {
                            margin: 1em;
                        }
                    }

                    .checkbox-por {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding-bottom: 1em;
                        font-weight: 300;

                        input {
                            margin: 1em;
                        }

                        .fi {
                            margin-left: 1em;
                        }
                    }

                    .btn-new-por-small {
                        text-transform: none;
                        font-size: em(14);
                        font-weight: 300;
                    }

                    .btn-new-contact-small {
                        text-transform: none;
                        font-size: em(14);
                        font-weight: 300;
                    }

                    &.new-advert-buttons {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 2em 0;

                        .icon {
                            cursor: pointer;
                        }

                        .btn {
                            display: flex;
                            align-items: center;
                            height: em(72);
                            padding: 0 2em;
                            border: none;
                            font-size: $base-font-size-px;
                            font-weight: 300;
                            border-radius: 36px;
                            margin-right: 1em;
                        }

                        &.edit-advert-buttons {

                            .icon {
                                margin-right: 1em;
                                cursor: pointer;

                                &.icon-noleftmargin {
                                    margin-left: 0;
                                }
                            }

                            .btn-w-icon {

                                .icon {
                                    margin-left: -2em;
                                }
                            }
                        }
                    }

                    &.new-advert-wo-details {
                        text-align: center;

                        .new-advert-wo-details-link {
                            color: $bblack;
                            font-size: em(13);
                            text-decoration: underline;

                            &:visited {
                                color: $bblack;
                            }
                        }
                    }

                    &.template-selector {
                        display: block;
                        padding: 2em;
                        background-color: $glighter3;

                        &.hidden {
                            display: none;
                        }

                        .label-text {
                            padding-bottom: 2em;
                        }

                        .templates {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .template-tile {
                                width: 250px;
                                height: 250px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 3px solid $gray;
                                cursor: pointer;

                                &:hover {
                                    border-color: $red;
                                }
                            }
                        }

                    }

                    &.template-selector-result {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 2em;
                        background-color: $glighter3;

                        .template-selected {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            color: $bgreen;
                            font-size: em(18);
                            font-weight: 800;
                        }

                        .template-img {
                            padding: 1em;
                            background-color: $bwhite;
                        }

                        .template-select-other {
                            padding-right: 1em;
                            font-weight: 300;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }

                }

                .template-wrapper {
                    display: none;

                    &.active {
                        display: block;
                    }
                }

                .inline-inputs {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .label-zip {
                        min-width: 10em;
                        margin-right: 1.5em;
                    }

                    .label-filler {
                        flex: 1 0 0;
                    }

                    .inline-input {
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-between;

                        .form-input {
                            width: 130px;
                        }

                        .label-info {
                            padding: 0 .3em;
                        }
                    }
                }

                .label-text {
                    display: block;
                    padding-bottom: .5em;
                    font-size: em(18);
                    color: $gdark2;

                    .label-text-light {
                        font-weight: 300;
                    }
                }

                .separator {
                    margin: 0 -1em;
                }

                hr {
                    border: none;
                    border-top: 1px solid $glighter3;
                }

                .section-title {
                    display: block;
                    margin-bottom: 2em;
                    font-size: em(12);
                    text-transform: uppercase;
                    color: $glight2;
                }

                .form-input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    padding-left: 15px;
                    font-size: em(15);
                    // line-height: 50px;
                    border: 1px solid $glighter3;
                    border-radius: 8px;
                    @include appearance(none);

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: $glighter;
                        font-weight: 300;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: $glighter;
                        font-weight: 300;
                    }

                }

                .form-selection {
                    display: inline-flex;
                    justify-content: flex-start;
                    align-items: center;
                    position: relative;
                    width: 100%;
                    border: 1px solid $glighter3;
                    border-radius: 8px;
                    background-color: $bwhite;
                    height: 50px;
                    @include appearance(none);

                    &:after {
                        position: absolute;
                        right: 10px;
                        top: 20px;
                        z-index: 0;
                        content: $font-Flaticon-down-arrow;
                        font-family: "Flaticon";
                        font-size: em(13);
                    }

                    select {
                        position: relative;
                        z-index: 1;
                        width: 100%;
                        flex: 1 0 0;
                        height: 50px;
                        padding-left: 15px;
                        font-size: em(15);
                        // line-height: 50px;
                        outline: 0;
                        border: 0;
                        background-color: transparent;
                        font-weight: 300;
                        @include appearance(none);
                    }


                    option.placeholder {
                        color: $glighter;
                        font-weight: 300;
                        @include appearance(none);
                    }

                    &.form-selection-publish-year {
                        max-width: 12em;
                    }

                    &.form-selection-year {
                        max-width: 7em;
                        margin-right: 1em;
                    }

                    &.form-selection-month {
                        max-width: 5em;
                        margin-right: 1em;
                    }

                    &.form-selection-net-price {
                        width: 210px;
                    }

                    &.form-selection-vat {
                        width: 100px;
                    }
                }

                .form-submit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-flow: row wrap;

                    .legal {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 2em;

                        .legal-text {
                            margin-left: 1em;
                            font-size: em(14);

                            .legal-link {
                                color: $gdark2;
                                text-decoration: underline;

                                &:visited {
                                    color: $gdark2;
                                }
                            }
                        }
                    }

                    .btn {
                        margin-top: 2em;
                        font-size: em(18);
                        line-height: em(18);
                        font-weight: 300;
                        letter-spacing: 0.2em;
                    }
                }

                .attachments {
                    padding-bottom: 2em;

                    .title {
                        font-size: em(18);
                    }

                    .list {
                        padding-left: 1.5em;

                        .list-item {
                            position: relative;
                            display: flex;
                            align-items: center;
                            min-height: 50px;
                            font-size: em(15);
                            font-weight: 300;

                            .icon {
                                margin: 0;
                                margin-left: 1em;

                                i {
                                    color: $gdark;
                                }
                            }

                            a {
                                color: $bblack;
                                text-decoration: underline;

                                &:hover {
                                    color: $bblack;
                                }

                                &:visited {
                                    color: $bblack;
                                }
                            }
                        }
                    }
                }
            }
        }

        .tip {
            max-width: 39%;

            .tip-title {
                font-size: em(24);
                font-weight: 300;
                line-height: em(26);
                text-align: center;
                padding: 50px 90px 0 90px;
            }

            .tip-text {
                font-size: em(14);
                font-weight: 300;
                line-height: em(26);
                text-align: justify;
                padding: 50px 55px 0 55px;
                color: $gdark;
            }
        }
    }

    .reg-thanks {
        background-color: $glightest2;

        .header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            min-width: 100%;
            height: em(80);
            background-color: $glighter3;

            .icon {
                margin-left: em(40);
            }

            .header-title {
                font-size: em(22);
                text-transform: uppercase;
                color: $gdark2;
                font-weight: 300;
            }
        }

        .thanks-text {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: row wrap;
            padding: 70px 25%;
            text-align: center;

            .icon {
                width: 100%;

                &:before {
                    background-color: transparent;
                }

                i {
                    font-size: em(72);
                    color: $bsuccess;
                }
            }

            .success-text {
                width: 100%;
                font-size: em(22);
                font-weight: 300;
                padding: 50px 0;
            }

            .success-details {
                width: 100%;
                font-size: em(14);
                line-height: em(26);
                color: $gdark2;
                font-weight: 300;
            }
        }
    }

}
