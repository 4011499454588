.breadcrumb {
    width: 100%;
    display: block;
    padding: .5em 0;
    margin-bottom: .5em;

    span {
        margin-right: .5em;
        display: inline;
        color: $gray;
        font-size: .95em;
        text-decoration: none;
        line-height: 1.3em;
        &:before {
            color: $bprimary;
            margin-right: .75em;
            content: '\f105';
            font-family: $ifaf;
        }
        a { color: $gray; text-decoration: underline; }
        &:first-child:before { display: none; }
    }
}
