.site-wrapper {
    position: relative;
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    &.fonts-smaller {
        font-size: 95%;
    }

    &.fonts-bigger {
        font-size: 125%;
    }
}

.site-container {
    width: 100%;
    display: block;
    flex: 1;

    .wrapper {
        display: flex;
        justify-content: space-between;
    }
}

.home-flex {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.main-content {
    flex: 1;
    padding: 1em 2em;
    &.home-content { padding: 0; }
}

.banner-container {
    width: 100%;
    display: block;
    text-align: center;
    margin: .5em 0 1em 0;

    img {
        display: inline-block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }
}

.error-body {
    display: block;
    padding: 4em 0;
    text-align: center;

    i.fa { font-size: 6em; color: $bprimary; margin-bottom: .25em; }
    p { display: block; font-size: 1.3em; line-height: 1.5em; }
}

.search-inner {
    display: block;
    margin: 0 1em 1em 0;

    .search-inner-form {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .search-input {
            flex: 1;
            font: normal 1.2em $bfaf;
            height: 42px;
            border: 1px solid $glighter;
            border-radius: 5px;
            padding: 0 1em;
            outline: none;
        }

        .search-submit {
            width: 42px;
            height: 42px;
            font-size: 1.5em;
            background: $bblack;
            border: none;
            border-radius: 5px;
            color: $bwhite;
            text-align: center;
            line-height: 38px;
            margin-left: .5em;
            outline: none;
        }
    }
}